import {  DeathOfBeneficiary } from "../store/state";

export default function validate(beneficiary : DeathOfBeneficiary) {
    let errors : any = {};

    if (!beneficiary.selectedBeneficiary) {
        errors.selectedBeneficiary = 'You must select an option.';
    }

    return errors;
};