import React, { CSSProperties, useState, useRef } from 'react';
import heschLogo from '../assets/hesch-logo.png';
import { Responsive, Sidebar, Menu, Icon, Image, Container, Sticky, Rail } from 'semantic-ui-react';
import { State } from '../store/state';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions/customerAuthActions';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: '97px',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: 'solid #00659D 2px',
    marginBottom: '10px',
    justifyContent: 'center'
};

interface INavBarMobileProps {
    visible: boolean; 
    onPusherClick: () => void;
    onToggle: () => void;
    children: React.ReactNode[];
    isLoggedIn: boolean;
    onLogout: () => void;
}

interface INavBarDesktopProps {
    isLoggedIn: boolean;
    onLogout: () => void;
}

export interface IHeaderProps {
    children: React.ReactNode[];
}

const NavBarMobile = ({visible, onPusherClick, onToggle, children, isLoggedIn, onLogout} : INavBarMobileProps) => {
    const contextRef = useRef(null);

    return (
        <div ref={contextRef}>
            {isLoggedIn &&
            <Sidebar as={Menu}
                    animation="overlay"
                    icon="labeled"
                    vertical
                    visible={visible}
                    style={{minWidth: '60vw'}}>
                <Menu.Item onClick={onLogout}>
                    <Icon name="log out"/> Log Out
                </Menu.Item>
            </Sidebar>
            }
            <Sidebar.Pusher 
                dimmed={visible}
                onClick={onPusherClick}
            >
                <Sticky context={contextRef} pushing={false} styleElement={{zIndex: 101}}>
                    <Menu attached="top" compact secondary style={{backgroundColor: '#FFFFFF', borderBottom: 'solid #00659D 2px'}}>
                        {isLoggedIn && 
                        <Menu.Item onClick={onToggle}>
                            <Icon name="sidebar" />
                        </Menu.Item>
                        }
                        <Menu.Item style={{width: '100%',flex: '0 1 auto', maxHeight: 47}}>
                            <Image src={heschLogo} style={{maxHeight: 44}}/>
                        </Menu.Item>
                    </Menu>
                </Sticky>
                <div style={{marginTop: 10}}>
                    {children}
                </div>
            </Sidebar.Pusher>
        </div>
    )
}

const NavBarDesktop = ({isLoggedIn, onLogout}: INavBarDesktopProps) => {
    return (
        <Container textAlign="center" style={{backgroundColor: '#fff', borderBottom: 'solid #00659D 2px', width: '100%'}} fluid>
            <Menu secondary>
                <Menu.Menu position="left">
                    <Menu.Item>
                        <a href="https://www.heschlaw.com">Back to HeschLaw.com</a>
                    </Menu.Item>
                </Menu.Menu>
                <Menu.Menu >
                    <Menu.Item style={{width: '100%',flex: '0 1 auto', maxWidth: 488, textAlign: 'center'}}>
                        <Image src={heschLogo} fluid/>
                    </Menu.Item>
                </Menu.Menu>
                <Menu.Menu position="right">
                    {isLoggedIn ? 
                    <Menu.Item onClick={onLogout}>
                        <Icon name="log out"/> Log Out 
                    </Menu.Item>
                    :
                    <Menu.Item style={{minWidth: '179px'}}>

                    </Menu.Item>
                    }
                </Menu.Menu>
            </Menu>
        </Container>
    );
};

const customerAuthSelector = (state: State) => state.customerAuth;
export const HeaderComponent = ({children}: IHeaderProps) => {
    const [visible, setVisible] = useState(false);
    const customerAuth = useSelector(customerAuthSelector);
    const dispatch = useDispatch();

    const handlePusher = () => {
        if (visible) setVisible(false);
    };

    const handleToggle = () => {
        setVisible(!visible);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <div>
            <Responsive {...Responsive.onlyMobile} minWidth={Responsive.onlyMobile.minWidth}>
                <NavBarMobile
                    visible={visible}
                    onPusherClick={handlePusher}
                    onToggle={handleToggle}
                    children={children}
                    isLoggedIn={!!customerAuth}
                    onLogout={handleLogout}
                />
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <NavBarDesktop 
                    isLoggedIn={!!customerAuth}
                    onLogout={handleLogout} />
                <div style={{marginTop: 60}}>
                    {children}
                </div>
            </Responsive>
        </div>
    )
}