import {FamilyInformation} from "../store/state";
import {initialState} from "../store/initialState";
import {familyInformationActionTypes} from "../actionTypes/familyInformationActionTypes";

export function familyInformationReducer(state = initialState.familyInformation, action: {type: string, payload: any}): FamilyInformation {
    switch (action.type) {
        case familyInformationActionTypes.UPDATE_FAMILY_INFORMATION: {
            return {
                ...state,
                children: action.payload.children,
                grandChildren: action.payload.grandChildren,
                sameRestrictions: action.payload.sameRestrictions
            };
        }
        case familyInformationActionTypes.UPDATE_FAMILY_INFORMATION_ERRORS: {
            return {
                ...state,
                errors: action.payload.errors
            };
        }
        default: {
            return state;
        }
    }
}