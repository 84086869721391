import { combineReducers, Reducer } from 'redux';
import {connectRouter, RouterState, LocationChangeAction} from 'connected-react-router';
import {welcomeReducer} from './welcomeReducer';
import {grantorPersonalInformationReducer} from './grantorPersonalInformationReducer';
import {familyInformationReducer} from "./familyInformationReducer";
import {requiredDistributionsReducer} from "./requiredDistributionsReducer";
import {workRequirementReducer} from "./workRequirementReducer";
import {workRequirementExceptionReducer} from "./workRequirementExceptionsReducer";
import {distributionsUponRetirementReducer} from "./distributionsUponRetirementReducer";
import {successorTrusteesReducer} from "./successorTrusteesReducer";
import {deathOfBeneficiaryReducer} from "./deathOfBeneficiaryReducer";
import { customerAuthReducer } from './customerAuthReducer';
import {additionalQuestionsReducer} from "./additionalQuestionsReducer";
import {healthCareDocumentsReducer} from "./healthCareDocumentsReducer";
import { History } from 'history';
import {assetIdentificationReducer} from "./assetidentificationReducer";
import { initialState } from '../store/initialState';

export default (history: History) => {
    const appReducer = combineReducers({
        router: (connectRouter(history) as Reducer<RouterState | undefined, LocationChangeAction>),
        deathOfBeneficiary: deathOfBeneficiaryReducer,
        distributionsUponRetirement: distributionsUponRetirementReducer,
        familyInformation: familyInformationReducer,
        grantorsPersonalInfo: grantorPersonalInformationReducer,
        requiredDistribution: requiredDistributionsReducer,
        successorTrustees: successorTrusteesReducer,
        welcome: welcomeReducer,
        workRequirement: workRequirementReducer,
        workRequirementExceptions: workRequirementExceptionReducer,
        customerAuth: customerAuthReducer,
        additionalQuestions: additionalQuestionsReducer,
        healthCareDocuments: healthCareDocumentsReducer,
        assetIdentification: assetIdentificationReducer
    });

    const rootReducer = (state = initialState, action: {type: string, payload?: any}) => {
        if (action.type === 'RESET') {
            state = {
                ...state,
                ...initialState,
            };
        }

        return appReducer(state, action);
    }

    return rootReducer;
}