import React, { Component, useEffect } from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import './App.css';
import { WelcomePage} from './pages/welcomePage';
import { GrantorsPersonalInformationPage } from './pages/grantorsPersonalInformationPage';
import { HeaderComponent } from './components/headerComponent';
import { MenuComponent } from './components/menuComponent';
import { FamilyInformationPage } from "./pages/familyInformationPage";
import { RequiredDistributionsPage } from "./pages/requiredDistributionsPage";
import { WorkRequirementPage } from "./pages/workRequirementPage";
import { WorkRequirementExceptionsPage } from "./pages/workRequirementExceptionsPage";
import { DistributionsUponRetirementPage } from "./pages/distributionsUponRetirementPage";
import { SuccessorTrusteesPage } from "./pages/successorTrusteesPage";
import { DeathOfBeneficiaryPage } from "./pages/deathOfBeneficiaryPage";
import { PaymentPage } from './pages/paymentPage';
import { AdditionalQuestionsPage } from './pages/additionalQuestionsPage';
import { HealthCareDocumentsPage } from "./pages/HealthCareDocumentsPage";
import { PaymentResultPage } from './pages/paymentResultPage';
import { AssetIdentificationPage } from "./pages/assetIdentificationPage";
import { history } from './store/history';
import { useDispatch, useSelector } from 'react-redux';
import { State } from './store/state';
import { fetchResponse } from './actions/customerAuthActions';

const customerAuthSelector = (state: State) => state.customerAuth;

export const App = () => {
    const customerAuth = useSelector(customerAuthSelector);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (customerAuth && customerAuth.customerId) {
            dispatch(fetchResponse());
        }
    }, [])
    return (
        <ConnectedRouter history={history}>
            <div className="App">
                <HeaderComponent>
                    <MenuComponent/>
                    <Route path="/" exact component={WelcomePage} />
                    {!!customerAuth ? 
                        <>
                            <Route path="/step1" exact component={GrantorsPersonalInformationPage} />
                            <Route path="/step2" exact component={FamilyInformationPage} />
                            <Route path="/step3" exact component={RequiredDistributionsPage} />
                            <Route path="/step4" exact component={WorkRequirementPage} />
                            <Route path="/step5" exact component={WorkRequirementExceptionsPage} />
                            <Route path="/step6" exact component={DistributionsUponRetirementPage} />
                            <Route path="/step7" exact component={SuccessorTrusteesPage} />
                            <Route path="/step8" exact component={DeathOfBeneficiaryPage} />
                            <Route path="/step9" exact component={AdditionalQuestionsPage} />
                            <Route path="/step10" exact component={HealthCareDocumentsPage} />
                            <Route path="/step11" exact component={AssetIdentificationPage} />
                            <Route path="/payment" exact component={PaymentPage} />
                            <Route path="/payment-success" exact component={(props: RouteComponentProps) => <PaymentResultPage {...props} />} />
                            <Route path="/payment-failure" exact component={(props: RouteComponentProps) => <PaymentResultPage {...props} error />} />
                        </>
                    :
                        <Redirect to="/"/>
                    }
                </HeaderComponent>
            </div>
        </ConnectedRouter>
    );
};