import {WorkRequirement} from "../store/state";
import {initialState} from "../store/initialState";
import {workRequirementActionTypes} from "../actionTypes/workRequirementActionTypes";

export function workRequirementReducer(state = initialState.workRequirement, action: {type: string, payload: any}): WorkRequirement {
    switch (action.type) {
        case workRequirementActionTypes.UPDATE_WORK_REQUIREMENT: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}