import {GrantorsPersonalInfo} from "../store/state";
import {initialState} from "../store/initialState";
import {grantorsPersonalInformationActionTypes} from "../actionTypes/grantorsPersonalInformationActionTypes";

export function grantorPersonalInformationReducer(state = initialState.grantorsPersonalInfo, action: {type: string, payload: any}): GrantorsPersonalInfo {
    switch (action.type) {
        case grantorsPersonalInformationActionTypes.UPDATE_GRANTOR_INFORMATION: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}