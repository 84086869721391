import {Customer} from "../store/state";
import {initialState} from "../store/initialState";
import {customerAuthActionTypes} from "../actionTypes/customerAuthActionTypes";

export function customerAuthReducer(state = initialState.customerAuth, action: {type: string, payload: any}): Customer | null {
    switch (action.type) {
        case customerAuthActionTypes.UPDATE_CUSTOMER_INFORMATION: {
            return {
                ...state,
                ...action.payload
            };
        }
        case customerAuthActionTypes.PAYMENT_COMPLETE: {
            if (state) {
                return {
                    ...state,
                    paymentComplete: true
                };
            }
        }
        case customerAuthActionTypes.UPDATE_CURRENT_STEP: {
            debugger;
            const currentStep : number = action.payload;
            if (state && state.currentStep < currentStep) {
                return {
                    ...state,
                    currentStep
                };
            } else if (state && !state.currentStep) {
                return {
                    ...state,
                    currentStep
                };
            } else if (state) {
                return {
                    ...state
                };
            }
        }
        default: {
            return state ? state : null;
        }
    }
}