import React, { useState, FormEvent, useEffect, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, FormProps } from 'semantic-ui-react';
import { State, SuccessorTrustees } from '../store/state';
import {updateSuccessorTrustees} from "../actions/successorTrusteesActions";
import {Link, RouteComponentProps} from "react-router-dom";
import { containerStyle, titleStyle, pStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle } from '../styles';
import useForm from '../useForm';
import validate from '../validationRules/successorTrusteeRules';
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const successorTrusteesSelector = (state: State) => state.successorTrustees;

export const SuccessorTrusteesComponent = (props: RouteComponentProps) => {
    document.title = "Successor Trustees";
    const reduxSuccessorTrustees = useSelector(successorTrusteesSelector);

    const nextPage  = () => {
        dispatch(updateSuccessorTrustees(successor));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: successor,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: SuccessorTrustees, 
        setValues: Function,
        errors: any, 
        handleChange: any, 
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(reduxSuccessorTrustees, nextPage, validate);

    useEffect(() => {
        setValues({...reduxSuccessorTrustees});
    }, [reduxSuccessorTrustees]);

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const dispatch = useDispatch();
    dispatch(redirectToAppropriateStep(7));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Successor Trustees</h1>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <p style={pStyle}>The Trustee will manage your trust. A corporate successor trustee is not required for the Retirement Trust. If a Grantor prefers using individual successor trustees, the Grantor is encouraged to identify a list of individuals to serve in that role. However, identifying a corporate successor trustee of last resort is always encouraged. The Trustee must invest and manage money, as well as maintain relationships withe the beneficiaries and make decisions about distributions to the beneficiaries. Please identify your Successor Trustees and alternates</p>
                        <Form.Input name={'trustee1'} 
                                    value={successor.trustee1} 
                                    onChange={handleChange} 
                                    className={'field'} 
                                    label={'Trustee 1'} 
                                    placeholder={'Trustee'} 
                                    width={16} 
                                    onFocus={() => updateFocus('Successor Trustee')} 
                                    onBlur={() => updateFocus('')}
                                    autoFocus
                                    required/>

                        <Form.Input name={'trustee2'} 
                                    value={successor.trustee2} 
                                    onChange={handleChange} 
                                    className={'field'} 
                                    label={'Trustee 2'} 
                                    placeholder={'Trustee'} 
                                    width={16} />

                        <Form.Input name={'trustee3'} 
                                    value={successor.trustee3} 
                                    onChange={handleChange} 
                                    className={'field'} 
                                    label={'Trustee 3'} 
                                    placeholder={'Trustee'} 
                                    width={16} />

                        <Form.Input name={'trustee4'} 
                                    value={successor.trustee4} 
                                    onChange={handleChange} 
                                    className={'field'} 
                                    label={'Trustee 4'} 
                                    placeholder={'Trustee'} 
                                    width={16} />

                        <Form.Input name={'trustee5'} 
                                    value={successor.trustee5} 
                                    onChange={handleChange} 
                                    className={'field'} 
                                    label={'Trustee 5'} 
                                    placeholder={'Trustee'} 
                                    width={16} />
                    </div>
                    <div className={'helpContainer'}>
                        <Header>{focus.field}</Header>
                        {/* Help section */}
                        {
                            !focus.field && 
                            <>
                                <p>The trustee is the manager of the trust. The trustee is responsible for the assets of the trust, including the management, investment, and maintenance of those assets. The trustee is the person that will distribute assets to the beneficiaries when they are scheduled. The trustee is allowed to receive guidance from professionals if they are not experienced in managing the assets within the trust. </p>
                                <p>A successor trustee is the person/institutions that step-in and become the trustee after the Grantor becomes disabled or dies. </p>
                                <p>A corporate trustee is a bank or financial institution that will manage the trust. A corporate trustee is not required, but is typically a choice for the last back-up. </p>
                            </>
                        }
                        {
                            focus.field === 'Successor Trustee' &&
                            <p>Enter a Trustee.</p>
                        }
                    </div>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}