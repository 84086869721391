import {State} from './state';

export const initialState: State = {
    router: void(0),
    welcome: {
        email: '',
        error: ''
    },
    grantorsPersonalInfo: {
        husbandLegalName: '',
        husbandHomeAddress: '',
        husbandCounty: '',
        husbandTelephoneNumber: '',
        husbandDateOfBirth: '',
        husbandLocation: '',
        husbandState: '',
        husbandDate: '',
        husbandWill: false,
        husbandTrust: false,
        husbandShowHusbandLocation: false,
        hasSpouse: false,
        wifeLegalName: '',
        wifeTelephoneNumber: '',
        wifeDateOfBirth: '',
        wifeLocation: '',
        wifeDate: '',
        wifeWill: false,
        wifeTrust: false,
        wifeShowWifeLocation: false
    },
    familyInformation: {
        sameRestrictions: true,
        children: [],
        grandChildren: []
    },
    requiredDistribution: {
        fixedDollarBoxChecked: false,
        fixedPercentageBoxChecked: false,
        workRequirementBoxChecked: false,
        otherDistributionBoxChecked: false
    },
    workRequirement: {
        equivalentYearlyWagesChecked: false,
        fixedPercentageYearlyWagesChecked: false,
        cappedAmountChecked: false,
        cappedPercentageChecked: false,
        otherRequirementChecked: false
    },
    workRequirementExceptions: {
        disabilityIncludeDisabilityException: false,
        disabilityAbleToEarnMoreThanFiftyPercentChecked: false,
        disabilityUnableToEarnMoreThanFiftyPercentChecked: false,
        disabilityCausedByDrugOrAlcoholChecked: false,
        disabilityOtherExceptionValue: '',
        disabilityOtherExceptionChecked: false,
        stayAtHomeParentIncludeStayAtHomeParentException: false,
        stayAtHomeParentWorkRequirementForSpouseChecked: false,
        stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: 0,
        stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: false,
        stayAtHomeParentPercentageOfDistributionsValue: 0,
        stayAtHomeParentPercentageOfDistributionsChecked: false,
        stayAtHomeParentOtherExceptionValue: '',
        stayAtHomeParentOtherExceptionChecked: false
    },
    distributionsUponRetirement: {
        includeDistributionsUponRetirement: true,
        remainingBalanceChecked: false,
        differentFinancialInstitution1: '',
        differentFinancialInstitution2: '',
        differentFinancialInstitutionsChecked: false,
        alternativeLifeExpectancyMethod: '',
        alternativeLifeExpectancyChecked: false,
        distributionPercentageValue: 0,
        monthlyDistributionValue: 0,
        noLifeAnnuityChecked: false
    },
    successorTrustees: {
        trustee1: '',
        trustee2: '',
        trustee3: '',
        trustee4: '',
        trustee5: ''
    },
    deathOfBeneficiary: {
        selectedBeneficiary: '',
        otherValue: ''
    },
    additionalQuestions: {
        primaryExecutor: '',
        alternateExecutor1: '',
        alternateExecutor2: '',
        primaryAttorneyInFact: '',
        alternateAttorneyInFact1: '',
        alternateAttorneyInFact2: '',
        attorneyInFactOperation: 'durable',
        primaryGuardian: '',
        alternateGuardian1: '',
        alternateGuardian2: '',
        monthlyPaymentForGuardian: false,
        oneTimePaymentForGuardianHome: false
    },
    customerAuth: null,
    healthCareDocuments: {
        primaryPowerOfAttorneyLegalName: '',
        primaryPowerOfAttorneyHomeAddress: '',
        primaryPowerOfAttorneyPhoneNumber: '',
        alternatePowerOfAttorney1LegalName: '',
        alternatePowerOfAttorney1HomeAddress: '',
        alternatePowerOfAttorney1PhoneNumber: '',
        alternatePowerOfAttorney2LegalName: '',
        alternatePowerOfAttorney2HomeAddress: '',
        alternatePowerOfAttorney2PhoneNumber: '',
        livingWillContact1LegalName: '',
        livingWillContact1HomeAddress: '',
        livingWillContact1PhoneNumber: '',
        livingWillContact2LegalName: '',
        livingWillContact2HomeAddress: '',
        livingWillContact2PhoneNumber: '',
        executeLivingWill: false,
        usePowerOfAttorneyContacts: false,
        primarySurrogateLegalName: '',
        primarySurrogateHomeAddress: '',
        primarySurrogatePhoneNumber: '',
        alternateSurrogateLegalName: '',
        alternateSurrogateHomeAddress: '',
        alternateSurrogatePhoneNumber: '',
        userState: ''
    },
    assetIdentification: {
        hasProperty: true,
        propertyCity: '',
        propertyState: '',
        survivorshipWithSpouse: false,
        otherHoldsRights: false,
        currentMarketValue: 0,
        investmentsOrVacationProperties: false,
        investmentOrVacationLocations: '',
        investmentOrVacationOwners: '',
        lifeInsuranceChecked: false,
        lifeInsuranceCompany: '',
        lifeInsurancePolicyNumber: '',
        lifeInsuranceOwner: '',
        lifeInsuranceBeneficiary: '',
        lifeInsuranceAmount: 0,
        investments: '',
        retirementPlans: ''
    }
};