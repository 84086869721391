import {CSSProperties} from 'react';

export const containerStyle : CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
};

export const centerStyle: CSSProperties = {
    alignSelf: 'center'
};

export const buttonStyle: CSSProperties = {
    backgroundColor: '#006CA8',
    color: 'white'
};

export const backButtonStyle: CSSProperties = {
    backgroundColor: '#e6e6e6',
    color: 'grey'
};

export const buttonContainerStyle: CSSProperties = {
    padding: '3%'
};

export const titleStyle: CSSProperties = {
    padding: '1%',
    fontSize: '28px',
    textAlign: 'center'
};

export const pStyle: CSSProperties = {
    fontSize: '16px !important'
};

export const radioStyle: CSSProperties = {
    padding: '5px'
};

export const otherRadioStyle: CSSProperties = {
    padding: '5px',
    alignSelf: 'center'
};

export const beneficiaryStyle: CSSProperties = {
    display: 'inline-flex',
    width: '100%'
};

export const checkedInputStyle: CSSProperties = {
    margin: '15px',
    width: '100%',
    border: '1px solid #D9D9D9',
    padding: '10px'
};

export const inputStyle: CSSProperties = {
    margin: '15px',
    width: '100%',
    border: '1px solid #D9D9D9',
    padding: '10px',
    background: '#F4F4F4'
};

export const distributionStyle: CSSProperties = {
    display: 'inline-flex',
    width: '100%'
};

export const checkboxStyle: CSSProperties = {
    padding: '5px',
    alignSelf: 'center'
};

export const headerStyle: CSSProperties = {
    paddingTop: '3%',
    fontSize: '24px'
};

export const subStyle : CSSProperties = {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 20,
    marginLeft: 20
}

export const dropdownDivStyle: CSSProperties = {
    minWidth: '15px',
    width: '33%'
};

export const dropdownStyle: CSSProperties = {
    minWidth: '15px',
    width: '100%'
};

export const dropdownLabelStyle: CSSProperties = {
    marginBottom: '5px',
};
