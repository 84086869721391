import {DeathOfBeneficiary} from "../store/state";
import {initialState} from "../store/initialState";
import {deathOfBeneficiaryActionTypes} from "../actionTypes/deathOfBeneficiaryActionTypes";

export function deathOfBeneficiaryReducer(state = initialState.deathOfBeneficiary, action: {type: string, payload: any}): DeathOfBeneficiary {
    switch (action.type) {
        case deathOfBeneficiaryActionTypes.UPDATE_DISTRIBUTION_AFTER_DEATH_OF_BENEFICIARY: {
            return {
                ...state,
                ...action.payload.beneficiary
            };
        }
        default: {
            return state;
        }
    }
}