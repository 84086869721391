import React, { useState, CSSProperties, ChangeEvent, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Header, Form, Radio, Checkbox, FormInputProps, FormProps} from 'semantic-ui-react';
import {AdditionalQuestions, State} from '../store/state';
import {updateAdditionalQuestions} from "../actions/additionalQuestionsActions";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    containerStyle, titleStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, headerStyle,
    radioStyle, pStyle, checkboxStyle
} from '../styles';
import { StickyContainer, Sticky } from 'react-sticky';
import validate from "../validationRules/additionalQuestionsRules";
import useForm from "../useForm";
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const additionalQuestionsSelector = (state: State) => state.additionalQuestions;

export const AdditionalQuestionsComponent = (props: RouteComponentProps) => {
    const additionalQuestions = useSelector(additionalQuestionsSelector);

    const nextPage  = () => {
        try {
            dispatch(updateAdditionalQuestions(answers));
        } catch(e) {
            alert('An error occured. Please contact Seven Hills Technology.');
        }
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };


    const {
        values: answers,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: AdditionalQuestions,
        setValues: Function,
        errors: any,
        handleChange: any,
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(additionalQuestions, nextPage, validate);

    useEffect(() => {
        setValues({...additionalQuestions});
    }, [additionalQuestions]);

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const dispatch = useDispatch();

    dispatch(redirectToAppropriateStep(9));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Additional Questions</h1>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <Header style={headerStyle}><b>Executor</b></Header>
                        <p style={pStyle}>Please identify your primary Executor and alternates:</p>
                        <Form.Input name={'primaryExecutor'}
                                    value={answers.primaryExecutor}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Primary Executor'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Executor')} 
                                    onBlur={() => updateFocus('')}
                                    autoFocus
                                    required
                                    error={errors.primaryExecutor} />
                        <Form.Input name={'alternateExecutor1'}
                                    value={answers.alternateExecutor1}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Executor'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Executor')} 
                                    onBlur={() => updateFocus('')} />
                        <Form.Input name={'alternateExecutor2'}
                                    value={answers.alternateExecutor2}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Executor'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Executor')} 
                                    onBlur={() => updateFocus('')} />
                        <br />
                        <Header style={headerStyle}><b>Attorney-in-fact</b></Header>
                        <p style={pStyle}>If I become disabled or incapacitated, I want my Attorney-in-fact to operate under a:</p>
                        <Radio
                            label='Durable Power of Attorney (takes effect immediately)'
                            name='attorneyInFactOperation'
                            value='durable'
                            style={radioStyle}
                            checked={answers.attorneyInFactOperation === 'durable'}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'attorneyInFactOperation', value: 'durable' } })}
                        /><br/>
                        <Radio
                            label='Springing Durable Power of Attorney (only takes effect if and when I become disabled)'
                            name='attorneyInFactOperation'
                            value='springingDurable'
                            style={radioStyle}
                            checked={answers.attorneyInFactOperation === 'springingDurable'}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'attorneyInFactOperation', value: 'springingDurable' } })}
                        /><br/>
                        <p style={pStyle}>Please identify your primary Attorney-in-fact and alternates:</p>
                        <Form.Input name={'primaryAttorneyInFact'}
                                    value={answers.primaryAttorneyInFact}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Primary Attorney-in-fact'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Attorney-in-fact')} 
                                    onBlur={() => updateFocus('')}
                                    required
                                    error={errors.primaryAttorneyInFact} />
                        <Form.Input name={'alternateAttorneyInFact1'}
                                    value={answers.alternateAttorneyInFact1}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Attorney-in-fact'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Attorney-in-fact')} 
                                    onBlur={() => updateFocus('')} />
                        <Form.Input name={'alternateAttorneyInFact2'}
                                    value={answers.alternateAttorneyInFact2}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Attorney-in-fact'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Attorney-in-fact')} 
                                    onBlur={() => updateFocus('')} />
                        <br />
                        <Header style={headerStyle}><b>Guardian</b></Header>
                        <p style={pStyle}>Please identify your primary Guardian and alternates:</p>
                        <Form.Input name={'primaryGuardian'}
                                    value={answers.primaryGuardian}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Primary Guardian'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Guardian')} 
                                    onBlur={() => updateFocus('')}
                                    required
                                    error={errors.primaryGuardian} />
                        <Form.Input name={'alternateGuardian1'}
                                    value={answers.alternateGuardian1}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Guardian'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Guardian')} 
                                    onBlur={() => updateFocus('')} />
                        <Form.Input name={'alternateGuardian2'}
                                    value={answers.alternateGuardian2}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Alternate Guardian'}
                                    placeholder={'Full legal name (including initials)'}
                                    width={16}
                                    onFocus={() => updateFocus('Guardian')} 
                                    onBlur={() => updateFocus('')} />
                        <br />
                        <p style={pStyle}>If a Guardian is needed for my minor children, I want the Guardian to receive:</p>
                        <Checkbox
                            label='Monthly payments for taking care of my children'
                            name='monthlyPaymentForGuardian'
                            value='yes'
                            style={checkboxStyle}
                            checked={answers.monthlyPaymentForGuardian === true}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'monthlyPaymentForGuardian', value: !answers.monthlyPaymentForGuardian } })}
                        />
                        <br />
                        <Checkbox
                            label='A one-time payment to make improvements to his or her home to better suit the needs of my children'
                            name='oneTimePaymentForGuardianHome'
                            value='yes'
                            style={checkboxStyle}
                            checked={answers.oneTimePaymentForGuardianHome === true}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'oneTimePaymentForGuardianHome', value: !answers.oneTimePaymentForGuardianHome } })}
                        />
                        <br />
                    </div>
                    <StickyContainer className={'helpContainer'}>
                        <Sticky>
                            {({style: stickyStyle}) => 
                                <div style={stickyStyle}>
                                    {
                                        focus.field &&
                                        <h3>{focus.field}</h3>
                                    }
                                    {
                                        !focus.field &&
                                        <><h3>Help</h3>
                                        <p>Help text will show up here are you navigate through the form</p></>
                                    }
                                    {
                                        focus.field === 'Executor' &&
                                        <p>The Executor is identified in your Will and is responsible for managing your estate with the Probate Court. He or she should be a resident of your home state; however, out-of-state relatives (by blood or marriage) may serve.</p>
                                    }
                                    {
                                        focus.field === 'Attorney-in-fact' &&
                                        <p>An attorney-in-fact basically manages your money if you aren’t able to do so yourself.</p>
                                    }
                                    {
                                        focus.field === 'Guardian' &&
                                        <p>A Guardian is responsible for the physical well-being, the estate, or both, of an incompetent or minor. Parents of young children frequently want to name relatives or friends in their Wills to serve as guardians of their children if both parents die.</p>
                                    }
                                </div>
                            }
                        </Sticky>
                    </StickyContainer>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} type="submit"
                            className={'ui right labeled icon button'}>
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}