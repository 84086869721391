import {Dispatch} from "redux";
import {familyInformationActionTypes} from "../actionTypes/familyInformationActionTypes";
import { State } from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateFamilyInfo = (children: any, grandChildren: any, sameRestrictions: boolean) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
        payload: {
            'children': children,
            'grandChildren': grandChildren,
            'sameRestrictions': sameRestrictions
        }
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 2
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step3');
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const updateErrors = (errors: any) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION_ERRORS,
        payload: {
            'errors': errors
        }
    });
};