import {Dispatch} from "redux";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { State } from "../store/state";
import {healthCareDocumentsActionTypes} from "../actionTypes/healthCareDocumentsActionTypes";
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateHealthCareDocuments = (healthCareDocuments: any) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: healthCareDocumentsActionTypes.UPDATE_HEALTH_CARE_DOCUMENTS,
        payload: healthCareDocuments
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 10
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push("step11");
    } catch (e) {
        console.log(e);
        throw e;
    }
};