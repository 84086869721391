import {SuccessorTrustees} from "../store/state";
import {initialState} from "../store/initialState";
import {successorTrusteesActionTypes} from "../actionTypes/successorTrusteesActionTypes";

export function successorTrusteesReducer(state = initialState.successorTrustees, action: {type: string, payload: any}): SuccessorTrustees {
    switch (action.type) {
        case successorTrusteesActionTypes.UPDATE_SUCCESSOR_TRUSTEES: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}