import { Dispatch } from "redux";
import { State } from "../store/state";
import { history } from '../store/history';

export const redirectToAppropriateStep = (step: number) => async (dispatch: Dispatch<any>, getState: () => State ) => {
    const state = getState();

    if (!state.customerAuth)
        return;
    
    const {customerAuth: {currentStep}} = state;
    if (currentStep < step-1) {
        history.replace(`step${currentStep}`);
    }
};

export const redirectToStep = (step: number) => async (dispatch: Dispatch<any>, getState: () => State ) => {
    history.push(`step${step}`);
};