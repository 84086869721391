import React, { useState, useEffect, FormEvent, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Checkbox, Radio } from 'semantic-ui-react';
import { State, Beneficiary } from '../store/state';
import { updateDistributionsUponRetirement } from "../actions/distributionsUponRetirementActions";
import { Link, RouteComponentProps } from "react-router-dom";
import { containerStyle, titleStyle, pStyle, radioStyle, checkedInputStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, distributionStyle, checkboxStyle, headerStyle, subStyle } from '../styles';
import validate from '../validationRules/distributionsUponRetirementRules';
import useForm from '../useForm';
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const distributionsUponRetirementSelector = (state: State) => state.distributionsUponRetirement;
const familyInformationSelector = (state: State) => state.familyInformation;
const childrenSelector = (state: State) => state.familyInformation.children;
const grandChildrenSelector = (state: State) => state.familyInformation.grandChildren;

export const DistributionsUponRetirementComponent = (props: RouteComponentProps) => {
    document.title = "Distributions Upon Retirement";
    const distributionsUponRetirement = useSelector(distributionsUponRetirementSelector);
    const reduxFamilyInfo = useSelector(familyInformationSelector);

    const reduxChildren = useSelector(childrenSelector);
    const reduxGrandChildren = useSelector(grandChildrenSelector);

    const [children, setChildDistributionUponRetirementValues] = useReducer((children, { target, type, key }) => {
        switch (type) {
            case "update":
                var child = children.find((_: any, index: number) => index === key);
                child.distributionsUponRetirement[target.name] = target.value;
                return [...children];
            case "updateAll":
                children.forEach((child: any)=>{child.distributionsUponRetirement[target.name] = target.value;});
                return [...children];
            case "checkGroup":
                children.forEach((child: any)=>{child.distributionsUponRetirement[target.name] = target.value;});
                return [...children];
            case "set":
                return [...reduxChildren];
            default:
                return reduxChildren;
        }
    }, reduxChildren);

    useEffect(() => {
        setChildDistributionUponRetirementValues({type: "set", list: reduxChildren});
    }, [reduxChildren]);    

    const updateChildDistributionUponRetirement = (e: any, index: number ) => {
        setChildDistributionUponRetirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const updateAllDistributionsUponRetirement = (e: any) => {
        setChildDistributionUponRetirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
        setGrandChildDistributionUponRetirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
    };

    const [grandChildren, setGrandChildDistributionUponRetirementValues] = useReducer((grandChildren, { target, type, key }) => {
        switch (type) {
            case "update":
                var grandChild = grandChildren.find((_: any, index: number) => index === key);
                grandChild.distributionsUponRetirement[target.name] = target.value;
                return [...grandChildren];
            case "updateAll":
                grandChildren.forEach((child: any)=>{child.distributionsUponRetirement[target.name] = target.value;});
                return [...grandChildren];
            case "checkGroup":
                grandChildren.forEach((child: any)=>{child.distributionsUponRetirement[target.name] = target.value;});
                return [...grandChildren];
            case "set":
                return [...reduxGrandChildren];
            default:
                return reduxGrandChildren;
        }
    }, reduxGrandChildren);

    useEffect(() => {
        setGrandChildDistributionUponRetirementValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateGrandChildDistributionUponRetirement = (e: any, index: number ) => {
        setGrandChildDistributionUponRetirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const [useChildren, setUseChildren] = useState(children.length > 0);

    const nextPage  = () => {
        dispatch(updateDistributionsUponRetirement({children: children, grandChildren: grandChildren, sameRestrictions: reduxFamilyInfo.sameRestrictions}, distribution));
    };

    const dispatch = useDispatch();
    
    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: distribution,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(distributionsUponRetirement, nextPage, validate);

    useEffect(() => {
        setValues({...distributionsUponRetirement});
    }, [distributionsUponRetirement]);

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        });
    };

    dispatch(redirectToAppropriateStep(6));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Distributions Upon Reaching Retirement</h1>
            <Form onSubmit={nextPage}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <Header style={headerStyle}>
                            <b>Answer the following questions regarding the rights your child will have upon reaching the age of 62 (or the 
                                age specified earlier)</b>
                        </Header>
                        {
                            (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                            <Checkbox
                                label='Payments shall be paid monthly beginning on the last day of the month in which the children turn 62
                                (or the age specified earlier).'
                                name='includeDistributionsUponRetirement'
                                value='yes'
                                style={checkboxStyle}
                                checked={useChildren ? children[0].distributionsUponRetirement.includeDistributionsUponRetirement === true : grandChildren[0].distributionsUponRetirement.includeDistributionsUponRetirement === true}
                                onChange={(e) => updateAllDistributionsUponRetirement({target: { name: 'includeDistributionsUponRetirement', value: useChildren ? !children[0].distributionsUponRetirement.includeDistributionsUponRetirement : !grandChildren[0].distributionsUponRetirement.includeDistributionsUponRetirement}})}
                            />
                        }
                        {
                            !reduxFamilyInfo.sameRestrictions &&
                            <> 
                                <p style={pStyle}>Payments shall be paid monthly beginning on the last day of the month in which the child turns 62 (or the age specified earlier).</p>
                                {
                                    children.map((child: Beneficiary, key: number) => (
                                        <>
                                            <Checkbox
                                                label={child.legalName}
                                                name='includeDistributionsUponRetirement'
                                                value='yes'
                                                style={checkboxStyle}
                                                checked={child.distributionsUponRetirement.includeDistributionsUponRetirement === true}
                                                onChange={(e) => updateChildDistributionUponRetirement({target: { name: 'includeDistributionsUponRetirement', value: !child.distributionsUponRetirement.includeDistributionsUponRetirement }}, key)}
                                            />
                                            <br></br>
                                        </>
                                    ))
                                }                        
                                {
                                    grandChildren.map((grandChild: Beneficiary, key: number) => (
                                        <>
                                            <Checkbox
                                                label={grandChild.legalName}
                                                name='includeDistributionsUponRetirement'
                                                value='yes'
                                                style={checkboxStyle}
                                                checked={grandChild.distributionsUponRetirement.includeDistributionsUponRetirement === true}
                                                onChange={(e) => updateGrandChildDistributionUponRetirement({target: { name: 'includeDistributionsUponRetirement', value: !grandChild.distributionsUponRetirement.includeDistributionsUponRetirement }}, key)}
                                            />
                                            <br></br>
                                        </>
                                    ))
                                }
                                <br></br>
                            </>
                        }                      
                        <>
                            {
                                (reduxFamilyInfo.sameRestrictions && 
                                    (children.length > 0 || grandChildren.length > 0) && 
                                    (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) ||
                                        grandChildren.some((gc) => gc.distributionsUponRetirement.includeDistributionsUponRetirement))) &&
                                <Form.Input name={'age'}
                                            value={useChildren ? children[0].distributionsUponRetirement.age : grandChildren[0].distributionsUponRetirement.age}
                                            onChange={(e) => updateAllDistributionsUponRetirement(e)}
                                            className={'field'}
                                            label={'Age'}
                                            placeholder={'Age'}
                                            width={16}
                                            onFocus={() => updateFocus('Age')} 
                                            onBlur={() => updateFocus('')}
                                            autoFocus
                                            type="number"
                                            min="1"
                                            required/>
                            }
                            {
                                !reduxFamilyInfo.sameRestrictions && children.map((child: Beneficiary, key: number) => (
                                    child.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                        <Form.Input name={'age'}
                                                    value={child.distributionsUponRetirement.age}
                                                    key={key}
                                                    onChange={(e) => updateChildDistributionUponRetirement(e, key)}
                                                    className={'field'}
                                                    label={child.legalName + ' Age'}
                                                    placeholder={'Age'}
                                                    width={16}
                                                    onFocus={() => updateFocus('Age')} 
                                                    onBlur={() => updateFocus('')}
                                                    autoFocus
                                                    type="number"
                                                    min="1"
                                                    required/>
                                )))
                            }
                            {
                                !reduxFamilyInfo.sameRestrictions && grandChildren.map((grandChild: Beneficiary, key: number) => (
                                    grandChild.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                        <Form.Input name={'age'}
                                            value={grandChild.distributionsUponRetirement.age}
                                            key={key}
                                            onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)}
                                            className={'field'}
                                            label={grandChild.legalName + ' Age'}
                                            placeholder={'Age'}
                                            width={16}
                                            onFocus={() => updateFocus('Age')} 
                                            onBlur={() => updateFocus('')}
                                            autoFocus
                                            type="number"
                                            min="1"
                                            required/>
                                )))
                            }
                        </>       
                        {
                            (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) || 
                                grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement)) && 
                            <>                
                                <div style={distributionStyle}>
                                    <Checkbox
                                        style={checkboxStyle}
                                        name='remainingBalanceChecked'
                                        value={'true'}
                                        checked={distribution.remainingBalanceChecked === true}
                                        onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'remainingBalanceChecked', value: !distribution.remainingBalanceChecked }})}
                                    />
                                    <div style={distribution.remainingBalanceChecked === true ? checkedInputStyle : inputStyle}>
                                        {
                                            (reduxFamilyInfo.sameRestrictions && 
                                                (children.length > 0 || grandChildren.length > 0) && 
                                                (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) ||
                                                grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement))) &&
                                            <>                                          
                                                <p style={pStyle}>The balance of assets remaining in that child’s sub-trust are totaled and that child 
                                                is entitled to a monthly annuity payment using what the average monthly payment amounts that would be 
                                                paid from Northwestern Mutual and New York Life annuities payables for the remainder of that child’s life.</p>
                                            </>
                                        }
                                        {
                                            !reduxFamilyInfo.sameRestrictions &&  
                                            <>
                                                <p style={pStyle}>The balance of assets remaining in that child’s sub-trust are totaled and that child 
                                                is entitled to a monthly annuity payment using what the average monthly payment amounts that would be 
                                                paid from Northwestern Mutual and New York Life annuities payables for the remainder of that child’s life.</p>
                                                <br></br>
                                                {
                                                    children.map((child: Beneficiary, key: number) => (
                                                        child.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                        <>                                                            
                                                            <Checkbox
                                                                label={child.legalName}
                                                                name='remainingBalanceChecked'
                                                                value='yes'
                                                                style={checkboxStyle}
                                                                checked={child.distributionsUponRetirement.remainingBalanceChecked === true}
                                                                onChange={(e) => updateChildDistributionUponRetirement({target: { name: 'remainingBalanceChecked', value: !child.distributionsUponRetirement.remainingBalanceChecked }}, key)}
                                                            />
                                                            <br></br>
                                                        </>
                                                    )))
                                                }
                                                {
                                                    grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                        grandChild.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                            <>                                                                                                           
                                                                <Checkbox
                                                                    label={grandChild.legalName}
                                                                    name='remainingBalanceChecked'
                                                                    value='yes'
                                                                    style={checkboxStyle}
                                                                    checked={grandChild.distributionsUponRetirement.remainingBalanceChecked === true}
                                                                    onChange={(e) => updateGrandChildDistributionUponRetirement({target: { name: 'remainingBalanceChecked', value: !grandChild.distributionsUponRetirement.remainingBalanceChecked }}, key)}
                                                                />
                                                                <br></br>
                                                            </>
                                                    )))
                                                }
                                            </>
                                        }
                                    </div>
                                </div>       
                            </>            
                        }    
                        {
                            (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) ||
                                grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement)) && 
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='differentFinancialInstitutionsChecked'
                                    value={'true'}
                                    checked={distribution.differentFinancialInstitutionsChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'differentFinancialInstitutionsChecked', value: !distribution.differentFinancialInstitutionsChecked } })}
                                />
                                {
                                    (reduxFamilyInfo.sameRestrictions && 
                                        (children.length > 0 || grandChildren.length > 0) && 
                                        (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) || 
                                            grandChildren.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement))) &&
                                    <div style={distribution.differentFinancialInstitutionsChecked === true ? checkedInputStyle : inputStyle}>                                                              
                                        <p style={pStyle}>Use different financial institutions</p>
                                        <Form.Input name={'differentFinancialInstitution1'} 
                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                    value={useChildren ? children[0].distributionsUponRetirement.differentFinancialInstitution1 : grandChildren[0].distributionsUponRetirement.differentFinancialInstitution1} 
                                                    onChange={(e) => updateAllDistributionsUponRetirement(e)} 
                                                    className={'field'} 
                                                    label={'Institution 1'} 
                                                    placeholder={'Institution'} 
                                                    width={16} 
                                                    onFocus={() => updateFocus('Institution')} 
                                                    onBlur={() => updateFocus('')}
                                                    required />
                                        <Form.Input name={'differentFinancialInstitution2'} 
                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                    value={useChildren ? children[0].distributionsUponRetirement.differentFinancialInstitution2 : grandChildren[0].distributionsUponRetirement.differentFinancialInstitution2} 
                                                    onChange={(e) => updateAllDistributionsUponRetirement(e)} 
                                                    className={'field'} 
                                                    label={'Institution 2'} 
                                                    placeholder={'Institution'} 
                                                    width={16} 
                                                    onFocus={() => updateFocus('Institution')} 
                                                    onBlur={() => updateFocus('')} />
                                    </div>
                                }
                                {
                                    !reduxFamilyInfo.sameRestrictions &&  
                                    <div style={distribution.differentFinancialInstitutionsChecked === true ? checkedInputStyle : inputStyle}>
                                        {
                                            children.map((child: Beneficiary, key: number) => (
                                                child.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                    <>                                                                         
                                                        <p style={pStyle}>Use different financial institutions for {child.legalName}</p>
                                                        <Form.Input name={'differentFinancialInstitution1'} 
                                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                                    value={child.distributionsUponRetirement.differentFinancialInstitution1} 
                                                                    onChange={(e) => updateChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Institution 1'} 
                                                                    placeholder={'Institution'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Institution')} 
                                                                    onBlur={() => updateFocus('')}
                                                                    required />
                                                        <Form.Input name={'differentFinancialInstitution2'} 
                                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                                    value={child.distributionsUponRetirement.differentFinancialInstitution2} 
                                                                    onChange={(e) => updateChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Institution 2'} 
                                                                    placeholder={'Institution'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Institution')} 
                                                                    onBlur={() => updateFocus('')} />
                                                    </>
                                            )))
                                        }
                                        {
                                            grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                grandChild.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                    <>                                                                         
                                                        <p style={pStyle}>Use different financial institutions for {grandChild.legalName}</p>
                                                        <Form.Input name={'differentFinancialInstitution1'} 
                                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                                    value={grandChild.distributionsUponRetirement.differentFinancialInstitution1} 
                                                                    onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Institution 1'} 
                                                                    placeholder={'Institution'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Institution')} 
                                                                    onBlur={() => updateFocus('')}
                                                                    required />
                                                        <Form.Input name={'differentFinancialInstitution2'} 
                                                                    disabled={distribution.differentFinancialInstitutionsChecked !== true} 
                                                                    value={grandChild.distributionsUponRetirement.differentFinancialInstitution2} 
                                                                    onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Institution 2'} 
                                                                    placeholder={'Institution'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Institution')} 
                                                                    onBlur={() => updateFocus('')} />
                                                    </>
                                            )))
                                        }
                                    </div>
                                }
                            </div>                            
                        }   
                        {
                            (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) ||
                            grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement)) && 
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='alternativeLifeExpectancyChecked'
                                    value={'true'}
                                    checked={distribution.alternativeLifeExpectancyChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'alternativeLifeExpectancyChecked', value: !distribution.alternativeLifeExpectancyChecked } })}
                                />
                                {
                                    (reduxFamilyInfo.sameRestrictions && 
                                        (children.length > 0 || grandChildren.length > 0) && 
                                        (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) || 
                                            grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement))) &&
                                    <div style={distribution.alternativeLifeExpectancyChecked === true ? checkedInputStyle : inputStyle}>                                                
                                        <p style={pStyle}>Altrnative life expectancy method</p>
                                        <Form.Input name={'alternativeLifeExpectancyMethod'} 
                                                    disabled={distribution.alternativeLifeExpectancyChecked !== true} 
                                                    value={useChildren ? children[0].distributionsUponRetirement.alternativeLifeExpectancyMethod : grandChildren[0].distributionsUponRetirement.alternativeLifeExpectancyMethod} 
                                                    onChange={(e) => updateAllDistributionsUponRetirement(e)} 
                                                    className={'field'} 
                                                    label={'Alternative'} 
                                                    placeholder={'Alternative'} 
                                                    width={16} 
                                                    onFocus={() => updateFocus('Alternative Method')} 
                                                    onBlur={() => updateFocus('')}
                                                    required />
                                    </div>
                                }
                                {
                                    !reduxFamilyInfo.sameRestrictions &&  
                                    <div style={distribution.alternativeLifeExpectancyChecked === true ? checkedInputStyle : inputStyle}>
                                        {
                                            children.map((child: Beneficiary, key: number) => (
                                                child.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                    <>                                                                                                               
                                                        <p style={pStyle}>Altrnative life expectancy method for {child.legalName}</p>
                                                        <Form.Input name={'alternativeLifeExpectancyMethod'} 
                                                                    disabled={distribution.alternativeLifeExpectancyChecked !== true} 
                                                                    value={child.distributionsUponRetirement.alternativeLifeExpectancyMethod} 
                                                                    onChange={(e) => updateChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Alternative'} 
                                                                    placeholder={'Alternative'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Alternative Method')} 
                                                                    onBlur={() => updateFocus('')}
                                                                    required />
                                                    </>
                                            )))
                                        }
                                        {
                                            grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                grandChild.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                    <>                                          
                                                        <p style={pStyle}>Altrnative life expectancy method for {grandChild.legalName}</p>
                                                        <Form.Input name={'alternativeLifeExpectancyMethod'} 
                                                                    disabled={distribution.alternativeLifeExpectancyChecked !== true} 
                                                                    value={grandChild.distributionsUponRetirement.alternativeLifeExpectancyMethod} 
                                                                    onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)} 
                                                                    className={'field'} 
                                                                    label={'Alternative'} 
                                                                    placeholder={'Alternative'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Alternative Method')} 
                                                                    onBlur={() => updateFocus('')}
                                                                    required />
                                                    </>
                                            )))
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {
                            (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) ||
                            grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement)) && 
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='noLifeAnnuityChecked'
                                    value={'true'}
                                    checked={distribution.noLifeAnnuityChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'noLifeAnnuityChecked', value: !distribution.noLifeAnnuityChecked } })}
                                />
                                {
                                    (reduxFamilyInfo.sameRestrictions && 
                                        (children.length > 0 || grandChildren.length > 0) && 
                                        (children.some((child) => child.distributionsUponRetirement.includeDistributionsUponRetirement) || 
                                            grandChildren.some(gc => gc.distributionsUponRetirement.includeDistributionsUponRetirement))) &&
                                    <>               
                                        <div style={distribution.noLifeAnnuityChecked === true ? checkedInputStyle : inputStyle}>                                 
                                            <p style={pStyle}>Do not use a life annuity</p>
                                            <Form.Input name={'distributionPercentageValue'} 
                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                        value={useChildren ? children[0].distributionsUponRetirement.distributionPercentageValue : grandChildren[0].distributionsUponRetirement.distributionPercentageValue} 
                                                        onChange={(e) => updateAllDistributionsUponRetirement(e)} 
                                                        className={'field'} 
                                                        label={'Distribution Percentage'} 
                                                        placeholder={'Distribution Percentage'} 
                                                        width={16} 
                                                        required
                                                        onFocus={() => updateFocus('Distribution Percentage')} 
                                                        onBlur={() => updateFocus('')}
                                                        type="number"
                                                        step="0.01"
                                                        min="0" />
                                            <Form.Input name={'monthlyDistributionValue'} 
                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                        value={useChildren ? children[0].distributionsUponRetirement.monthlyDistributionValue : grandChildren[0].distributionsUponRetirement.monthlyDistributionValue} 
                                                        onChange={(e) => updateAllDistributionsUponRetirement(e)} 
                                                        className={'field'} 
                                                        label={'Monthly Distribution'} 
                                                        placeholder={'Distribution Amount'} 
                                                        width={16} 
                                                        required
                                                        onFocus={() => updateFocus('Monthly Distribution')} 
                                                        onBlur={() => updateFocus('')}
                                                        type="number"
                                                        step="0.01"
                                                        min="0" />
                                        </div>
                                    </>
                                }
                                {
                                    !reduxFamilyInfo.sameRestrictions &&  
                                    <>
                                        <div style={distribution.noLifeAnnuityChecked === true ? checkedInputStyle : inputStyle}>
                                            {
                                                children.map((child: Beneficiary, key: number) => (
                                                    child.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                        <>                                                                                                                                                                     
                                                            <p style={pStyle}>Do not use a life annuity</p>
                                                            <Form.Input name={'distributionPercentageValue'} 
                                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                                        value={child.distributionsUponRetirement.distributionPercentageValue} 
                                                                        onChange={(e) => updateChildDistributionUponRetirement(e, key)} 
                                                                        className={'field'} 
                                                                        label={'Distribution Percentage'} 
                                                                        placeholder={'Distribution Percentage'} 
                                                                        width={16} 
                                                                        required
                                                                        onFocus={() => updateFocus('Distribution Percentage')} 
                                                                        onBlur={() => updateFocus('')}
                                                                        type="number"
                                                                        step="0.01"
                                                                        min="0" />
                                                            <Form.Input name={'monthlyDistributionValue'} 
                                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                                        value={child.distributionsUponRetirement.monthlyDistributionValue} 
                                                                        onChange={(e) => updateChildDistributionUponRetirement(e, key)} 
                                                                        className={'field'} 
                                                                        label={'Monthly Distribution'} 
                                                                        placeholder={'Distribution Amount'} 
                                                                        width={16} 
                                                                        required
                                                                        onFocus={() => updateFocus('Monthly Distribution')} 
                                                                        onBlur={() => updateFocus('')}
                                                                        type="number"
                                                                        step="0.01"
                                                                        min="0" />
                                                        </>
                                                )))
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                    grandChild.distributionsUponRetirement.includeDistributionsUponRetirement && (
                                                        <>                                                                                                                                                                     
                                                            <p style={pStyle}>Do not use a life annuity</p>
                                                            <Form.Input name={'distributionPercentageValue'} 
                                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                                        value={grandChild.distributionsUponRetirement.distributionPercentageValue} 
                                                                        onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)} 
                                                                        className={'field'} 
                                                                        label={'Distribution Percentage'} 
                                                                        placeholder={'Distribution Percentage'} 
                                                                        width={16} 
                                                                        required
                                                                        onFocus={() => updateFocus('Distribution Percentage')} 
                                                                        onBlur={() => updateFocus('')}
                                                                        type="number"
                                                                        step="0.01"
                                                                        min="0" />
                                                            <Form.Input name={'monthlyDistributionValue'} 
                                                                        disabled={distribution.noLifeAnnuityChecked !== true} 
                                                                        value={grandChild.distributionsUponRetirement.monthlyDistributionValue} 
                                                                        onChange={(e) => updateGrandChildDistributionUponRetirement(e, key)} 
                                                                        className={'field'} 
                                                                        label={'Monthly Distribution'} 
                                                                        placeholder={'Distribution Amount'} 
                                                                        width={16} 
                                                                        required
                                                                        onFocus={() => updateFocus('Monthly Distribution')} 
                                                                        onBlur={() => updateFocus('')}
                                                                        type="number"
                                                                        step="0.01"
                                                                        min="0" />
                                                        </>
                                                )))
                                            }
                                        </div>
                                    </>
                                }
                            </div>                            
                        }              
                    </div>
                    <div className={'helpContainer'}>
                        <Header>{focus.field}</Header>
                        {/* Help section */}
                        {
                            !focus.field && 
                            <>
                                <p><strong>Timing of distributions upon reaching retirement:</strong> We suggest that distributions made upon reaching retirement be paid monthly, beginning on the last day of the month in which the beneficiary turns the age specified in Section III(a).  However, you may specify any alternative schedule for distributions upon reaching retirement.</p>
                                <p>
                                    <strong>Method of distributions upon reaching retirement:</strong> One option is that the balance of assets remaining in the beneficiary’s sub-trust are totaled and that beneficiary is entitled to a monthly annuity payment based on the average monthly payment schedules that Northwestern Mutual and New York Life annuities use, payable for the remainder of that beneficiary’s life;  alternatively, you may select the payment schedules of two different financial institutions that sell annuities; you may also decide to select an alternative life expectancy method (Ex. A different life insurance company’s actuarial table or the IRS life expectancy tables in order to compute monthly distributions);

                                    <ul>
                                        <li>If you find that the distributions made based off of the annuity payment schedules are too high or too low, you may decide instead to select a fixed dollar amount of the trust income and principle, adjusted for inflation annually, or a fixed percentage of the trust principle for the monthly distribution.</li>
                                        <li>The objective of the annuity-calculated option is to provide a source of income to the beneficiary during their retirement years, which will be there for the remainder of their life. Choosing any other option carries the risk that the beneficiary will run out of money by the time they die. </li>
                                    </ul>
                                </p>
                            </>
                        }
                        {
                            focus.field === 'Age' && distribution.includeDistributionsUponRetirement &&
                            <p>Here you should put the age at which each child obtains the right to retirement distributions from their Trust.</p>
                        }
                        {
                            focus.field === 'Age' && !distribution.includeDistributionsUponRetirement &&
                            <p>Since no was selected, you should provide the age of which payments shall be paid monthly beginning on the last day of the month.</p>
                        }
                        {
                            focus.field === 'Institution' &&
                            <p>Here you should put the name of a different financial institution to be used when dealing with retirement distributions.</p>
                        }
                        {
                            focus.field === 'Alternative Method' &&
                            <p>Here you should put the alternative method of distribution.</p>
                        }
                        {
                            focus.field === 'Distribution Percentage' &&
                            <p>Here you should put the percentage of the distributions to be distributed upon retirement.</p>
                        }
                        {
                            focus.field === 'Monthly Distribution' &&
                            <p>Here you should put the monthly amount of the distributions to be distributed upon retirement.</p>
                        }
                    </div>
                </div>
                <div style={{ ...buttonContainerStyle, ...centerStyle }}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}