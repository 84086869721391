import React from 'react';
import { RouteComponentProps } from 'react-router';
import { PaymentResultComponent } from '../components/paymentResultComponent';

interface PaymentResultPageProps {
    error?: boolean;
}

export const PaymentResultPage: React.SFC<RouteComponentProps & PaymentResultPageProps> = (props) => (
    <div>
        <PaymentResultComponent {...props} />
    </div>
);