import axiosStatic from "axios";
import {initialState} from "./store/initialState";
import {State} from "./store/state";

declare const API_URL: string;

export const axios = axiosStatic.create({
    baseURL: API_URL,
    withCredentials: true
});

axios.interceptors.request.use(async config => {
    // const state: State = store.getState();
    // const organizationId = state.authSession.selectedOrganization ? state.authSession.selectedOrganization.id : null;
    // if (organizationId != null) {
    //     config.headers['x-organization-id'] = organizationId;
    // }
    // return config;
    return config;
});

axios.interceptors.response.use(async response => {
    return response;
});

export const login = async (email: string) => {
    return await axios.put('/customers', {Email: email});
};

export const loginWithToken = async (token: string) => {
    return await axios.put('/customers/login', {Token: token});
};

export const logout = async () => {
    return await axios.post('/auth/logout', {});
};

export const updateResponse = async (customerId: string, response: any) => {
    return await axios.put(`/customers/${customerId}/response`, response);
};

export const getResponse = async (customerId: string) => {
    return await axios.get(`/customers/${customerId}/response`);
};