import {Dispatch} from "redux";
import {successorTrusteesActionTypes} from "../actionTypes/successorTrusteesActionTypes";
import { SuccessorTrustees, State } from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateSuccessorTrustees = (successor: SuccessorTrustees) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: successorTrusteesActionTypes.UPDATE_SUCCESSOR_TRUSTEES,
        payload: successor
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 7
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step8');
    } catch (e) {
        console.log(e);
        throw e;
    }
};