import {Dispatch} from "redux";
import {additionalQuestionsActionTypes} from "../actionTypes/additionalQuestionsActionTypes";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import {AdditionalQuestions, State} from "../store/state";
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateAdditionalQuestions = (additionalQuestions: AdditionalQuestions) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: additionalQuestionsActionTypes.UPDATE_ADDITIONAL_QUESTIONS,
        payload: additionalQuestions
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 9
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step10');
    } catch (e) {
        console.log(e);
        throw e;
    }
};