import React, { useState, CSSProperties, FormEvent, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Checkbox, FormProps } from 'semantic-ui-react';
import { State, Beneficiary } from '../store/state';
import {Link, RouteComponentProps} from "react-router-dom";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio/Radio";
import {updateWorkRequirementExceptions} from "../actions/workRequirementExceptionActions";
import { containerStyle, titleStyle, pStyle, checkedInputStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, distributionStyle, checkboxStyle, headerStyle, radioStyle } from '../styles';
import validate from '../validationRules/workRequirementExceptionsRules';
import useForm from '../useForm';
import { history } from '../store/history';
import { redirectToAppropriateStep, redirectToStep } from '../actions/routerActions';

const exceptionStyle: CSSProperties = {
    display: 'inline-flex',
    width: '100%'
};

const familyInformationSelector = (state: State) => state.familyInformation;
const childrenSelector = (state: State) => state.familyInformation.children;
const grandChildrenSelector = (state: State) => state.familyInformation.grandChildren;

export const WorkRequirementExceptionsComponent = (props: RouteComponentProps) => {
    document.title = "Exceptions to Work Requirements";

    const reduxFamilyInfo = useSelector(familyInformationSelector);

    const reduxChildren = useSelector(childrenSelector);
    const reduxGrandChildren = useSelector(grandChildrenSelector);

    const [children, setChildWorkRequirementExceptionValues] = useReducer((children, { target, type, key }) => {
        switch (type) {
            case "update":
                var child = children.find((_: any, index: number) => index === key);
                child.workRequirementExceptions[target.name] = target.value;
                return [...children];
            case "updateAll":
                children.forEach((child: any)=>{child.workRequirementExceptions[target.name] = target.value;});
                return [...children];
            case "set":
                return [...reduxChildren];
            default:
                return reduxChildren;
        }
    }, reduxChildren);

    useEffect(() => {
        setChildWorkRequirementExceptionValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateChildWorkRequirementExceptions = (e: any, index: number ) => {
        setChildWorkRequirementExceptionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const [grandChildren, setGrandChildWorkRequirementExceptionValues] = useReducer((grandChildren, { target, type, key }) => {
        switch (type) {
            case "update":
                var grandChild = grandChildren.find((_: any, index: number) => index === key);
                grandChild.workRequirementExceptions[target.name] = target.value;
                return [...grandChildren];
            case "updateAll":
                grandChildren.forEach((child: any)=>{child.workRequirementExceptions[target.name] = target.value;});
                return [...grandChildren];
            case "set":
                return [...reduxGrandChildren];
            default:
                return reduxGrandChildren;
        }
    }, reduxGrandChildren);

    useEffect(() => {
        setGrandChildWorkRequirementExceptionValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateGrandChildrenWorkRequirementExceptions = (e: any, index: number ) => {
        setGrandChildWorkRequirementExceptionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const updateAllWorkRequirementExceptions = (e: any) => {
        setChildWorkRequirementExceptionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
        setGrandChildWorkRequirementExceptionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
    };

    const [focus, setFocus] = useState({
        field: '',
        exception: ''
    });

    const updateFocus = (field: string, exception: string) => {
        setFocus({
            field,
            exception
        })
    };

    const [useChildren, setUseChildren] = useState(children.length > 0);

    const nextPage  = () => {
        dispatch(updateWorkRequirementExceptions({children: children, grandChildren: grandChildren, sameRestrictions: reduxFamilyInfo.sameRestrictions}));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const dispatch = useDispatch();
    dispatch(redirectToAppropriateStep(5));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Exceptions To Work Requirement</h1>
            <Form onSubmit={nextPage}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        {
                            (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                            <>
                                <Header style={headerStyle}><b>Disability</b></Header>
                                <Checkbox
                                    label='I would like to include an exception to the work requirement in case your child becomes disabled, incapacitated, or incompetent.'
                                    name='disabilityIncludeDisabilityException'
                                    value='yes'
                                    style={checkboxStyle}
                                    checked={useChildren ? children[0].workRequirementExceptions.disabilityIncludeDisabilityException === true : grandChildren[0].workRequirementExceptions.disabilityIncludeDisabilityException === true}
                                    onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'disabilityIncludeDisabilityException', value: useChildren ? !children[0].workRequirementExceptions.disabilityIncludeDisabilityException : !grandChildren[0].workRequirementExceptions.disabilityIncludeDisabilityException }})}
                                />
                                {(useChildren && children[0].workRequirementExceptions.disabilityIncludeDisabilityException) ||
                                (!useChildren && grandChildren[0].workRequirementExceptions.disabilityIncludeDisabilityException) && 
                                    <>
                                        <p style={pStyle}>Check applicable:</p>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='disabilityAbleToEarnMoreThanFiftyPercentChecked'
                                                checked={useChildren ? children[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true : grandChildren[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'disabilityAbleToEarnMoreThanFiftyPercentChecked', value: useChildren ? !children[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked : !grandChildren[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>If a child is determined to be disabled, incapacitated, or incompetent and able to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the child is entitled to withdraw an amount equal to the difference of his or her average three year annual wage and his or her part time income received from part time employment.</p>
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='disabilityUnableToEarnMoreThanFiftyPercentChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true : grandChildren[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'disabilityUnableToEarnMoreThanFiftyPercentChecked', value: useChildren ? !children[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked : !grandChildren[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>If a child is determined to be disabled and unable to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the child is entitled to the withdrawal rights her or she would otherwise be entitle to upon reaching age 62 (or the age specified above).</p>
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='disabilityCausedByDrugOrAlcoholChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true : grandChildren[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'disabilityCausedByDrugOrAlcoholChecked', value: useChildren ? !children[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked : !grandChildren[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>Disabilities caused by drug or alcohol addiction are to be included.</p>
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='disabilityOtherExceptionChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.disabilityOtherExceptionChecked === true : grandChildren[0].workRequirementExceptions.disabilityOtherExceptionChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'disabilityOtherExceptionChecked', value: useChildren ? !children[0].workRequirementExceptions.disabilityOtherExceptionChecked : !grandChildren[0].workRequirementExceptions.disabilityOtherExceptionChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.disabilityOtherExceptionChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.disabilityOtherExceptionChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>Other</p>
                                                <Form.Input name={'disabilityOtherExceptionValue'}
                                                            disabled={useChildren ? children[0].workRequirementExceptions.disabilityOtherExceptionChecked !== true : grandChildren[0].workRequirementExceptions.disabilityOtherExceptionChecked !== true}
                                                            value={useChildren ? children[0].workRequirementExceptions.disabilityOtherExceptionValue : grandChildren[0].workRequirementExceptions.disabilityOtherExceptionValue} 
                                                            onChange={(e) => updateAllWorkRequirementExceptions(e)}
                                                            className={'field'}
                                                            label={'Other'}
                                                            placeholder={'Other'}
                                                            width={16}
                                                            onFocus={() => updateFocus('Other', 'Disability')}
                                                            onBlur={() => updateFocus('', '')}
                                                            required />
                                            </div>
                                        </div>
                                    </>
                                }
                                <Header style={headerStyle}><b>Stay-At-Home Parent</b></Header>
                                    <Checkbox
                                        label='I would like to include an exception to the work requirement in case your child becomes a stay-at-home parent with a spouse or significant other.'
                                        name='stayAtHomeParentIncludeStayAtHomeParentException'
                                        value='yes'
                                        style={checkboxStyle}
                                        checked={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException === true : grandChildren[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException === true}
                                        onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'stayAtHomeParentIncludeStayAtHomeParentException', value: useChildren ? !children[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException : !grandChildren[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException }})}
                                    />
                                {(useChildren && children[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException) ||
                                (!useChildren && grandChildren[0].workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException) && 
                                    <>
                                        <p style={pStyle}>Check applicable:</p>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='stayAtHomeParentWorkRequirementForSpouseChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true : grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementForSpouseChecked', value: useChildren ? !children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked : !grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>The work requirement shifts to the child’s spouse or significant other.</p>
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true : grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked', value: useChildren ? !children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked : !grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>The work requirement is delayed until after the child’s children reach age 18 or</p>
                                                <Form.Input name={'stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue'}
                                                            disabled={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked !== true : grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked !== true}
                                                            value={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue : grandChildren[0].workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue}
                                                            onChange={(e) => updateAllWorkRequirementExceptions(e)}
                                                            className={'field'}
                                                            label={'Age'}
                                                            placeholder={'Age'}
                                                            width={16}
                                                            type="number"
                                                            min="1"
                                                            onFocus={() => updateFocus('Grand Child\'s Age', 'Stay At Home Parent')} 
                                                            onBlur={() => updateFocus('', '')}
                                                            required />
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='stayAtHomeParentPercentageOfDistributionsChecked'
                                                value={'true'}
                                                checked={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true : grandChildren[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'stayAtHomeParentPercentageOfDistributionsChecked', value: useChildren ? !children[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked : !grandChildren[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>Percentage of trust assets that are distributions</p>
                                                <Form.Input name={'stayAtHomeParentPercentageOfDistributionsValue'}
                                                            disabled={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked !== true : grandChildren[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked !== true}
                                                            value={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue : grandChildren[0].workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue}
                                                            onChange={(e) => updateAllWorkRequirementExceptions(e)}
                                                            className={'field'}
                                                            label={'Distribution Percentage'}
                                                            placeholder={'Example: 4%'}
                                                            width={16}
                                                            type="number"
                                                            min="0"
                                                            step="0.5"
                                                            onFocus={() => updateFocus('Distribution Percentage', 'Stay At Home Parent')}
                                                            onBlur={() => updateFocus('', '')}
                                                            required />
                                            </div>
                                        </div>
                                        <div style={exceptionStyle}>
                                            <Checkbox
                                                style={checkboxStyle}
                                                name='stayAtHomeParentOtherExceptionChecked'
                                                value={'true'}
                                                checked={useChildren? children[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true : grandChildren[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true}
                                                onChange={(e) => updateAllWorkRequirementExceptions({target: { name: 'stayAtHomeParentOtherExceptionChecked', value: useChildren ? !children[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked : !grandChildren[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked }})}
                                            />
                                            <div style={(useChildren && children[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true) || (!useChildren && grandChildren[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true) ? checkedInputStyle : inputStyle}>
                                                <p style={pStyle}>Other</p>
                                                <Form.Input name={'stayAtHomeParentOtherExceptionValue'}
                                                            disabled={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked !== true : grandChildren[0].workRequirementExceptions.stayAtHomeParentOtherExceptionChecked !== true}
                                                            value={useChildren ? children[0].workRequirementExceptions.stayAtHomeParentOtherExceptionValue : grandChildren[0].workRequirementExceptions.stayAtHomeParentOtherExceptionValue}
                                                            onChange={(e) => updateAllWorkRequirementExceptions(e)}
                                                            className={'field'}
                                                            label={'Other'}
                                                            placeholder={'Other'}
                                                            width={16}
                                                            required
                                                            onFocus={() => updateFocus('Other', 'Stay At Home Parent')}
                                                            onBlur={() => updateFocus('', '')} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {
                            !reduxFamilyInfo.sameRestrictions && children.map((child: Beneficiary, key: number) => (
                                <>
                                    <Header style={headerStyle}><b>Disability</b></Header>
                                    <Checkbox
                                        label={'I would like to include an exception to the work requirement in case ' + child.legalName + '  becomes disabled, incapacitated, or incompetent.'}
                                        name='disabilityIncludeDisabilityException'
                                        value='yes'
                                        style={checkboxStyle}
                                        checked={child.workRequirementExceptions.disabilityIncludeDisabilityException === true}
                                        onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'disabilityIncludeDisabilityException', value: !child.workRequirementExceptions.disabilityIncludeDisabilityException }}, key)}
                                    />
                                    {child.workRequirementExceptions.disabilityIncludeDisabilityException && 
                                        <>
                                            <p style={pStyle}>Check applicable:</p>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityAbleToEarnMoreThanFiftyPercentChecked'
                                                    checked={child.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'disabilityAbleToEarnMoreThanFiftyPercentChecked', value: !child.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>If a child is determined to be disabled, incapacitated, or incompetent and able to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the child is entitled to withdraw an amount equal to the difference of his or her average three year annual wage and his or her part time income received from part time employment.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityUnableToEarnMoreThanFiftyPercentChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'disabilityUnableToEarnMoreThanFiftyPercentChecked', value: !child.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>If a child is determined to be disabled and unable to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the child is entitled to the withdrawal rights her or she would otherwise be entitle to upon reaching age 62 (or the age specified above).</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityCausedByDrugOrAlcoholChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'disabilityCausedByDrugOrAlcoholChecked', value: !child.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Disabilities caused by drug or alcohol addiction are to be included.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityOtherExceptionChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.disabilityOtherExceptionChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'disabilityOtherExceptionChecked', value: !child.workRequirementExceptions.disabilityOtherExceptionChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.disabilityOtherExceptionChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Other</p>
                                                    <Form.Input name={'disabilityOtherExceptionValue'}
                                                                disabled={child.workRequirementExceptions.disabilityOtherExceptionChecked !== true}
                                                                value={child.workRequirementExceptions.disabilityOtherExceptionValue} 
                                                                onChange={(e) => updateChildWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Other'}
                                                                placeholder={'Other'}
                                                                width={16}
                                                                onFocus={() => updateFocus('Other', 'Disability')}
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <Header style={headerStyle}><b>Stay-At-Home Parent</b></Header>
                                        <Checkbox
                                            label={'I would like to include an exception to the work requirement in case ' + child.legalName + ' becomes a stay-at-home parent with a spouse or significant other.'}
                                            name='stayAtHomeParentIncludeStayAtHomeParentException'
                                            value='yes'
                                            style={checkboxStyle}
                                            checked={child.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException === true}
                                            onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'stayAtHomeParentIncludeStayAtHomeParentException', value: !child.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException }}, key)}
                                        />
                                    {child.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException && 
                                        <>
                                            <p style={pStyle}>Check applicable:</p>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentWorkRequirementForSpouseChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementForSpouseChecked', value: !child.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>The work requirement shifts to the child’s spouse or significant other.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked', value: !child.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>The work requirement is delayed until after the child’s children reach age 18 or</p>
                                                    <Form.Input name={'stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue'}
                                                                disabled={child.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked !== true}
                                                                value={child.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue}
                                                                onChange={(e) => updateChildWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Age'}
                                                                placeholder={'Age'}
                                                                width={16}
                                                                type="number"
                                                                min="1"
                                                                onFocus={() => updateFocus('Grand Child\'s Age', 'Stay At Home Parent')} 
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentPercentageOfDistributionsChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'stayAtHomeParentPercentageOfDistributionsChecked', value: !child.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Percentage of trust assets that are distributions</p>
                                                    <Form.Input name={'stayAtHomeParentPercentageOfDistributionsValue'}
                                                                disabled={child.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked !== true}
                                                                value={child.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue}
                                                                onChange={(e) => updateChildWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Distribution Percentage'}
                                                                placeholder={'Example: 4%'}
                                                                width={16}
                                                                type="number"
                                                                min="0"
                                                                step="0.5"
                                                                onFocus={() => updateFocus('Distribution Percentage', 'Stay At Home Parent')}
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentOtherExceptionChecked'
                                                    value={'true'}
                                                    checked={child.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true}
                                                    onChange={(e) => updateChildWorkRequirementExceptions({target: { name: 'stayAtHomeParentOtherExceptionChecked', value: !child.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked }}, key)}
                                                />
                                                <div style={child.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Other</p>
                                                    <Form.Input name={'stayAtHomeParentOtherExceptionValue'}
                                                                disabled={child.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked !== true}
                                                                value={child.workRequirementExceptions.stayAtHomeParentOtherExceptionValue}
                                                                onChange={(e) => updateChildWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Other'}
                                                                placeholder={'Other'}
                                                                width={16}
                                                                required
                                                                onFocus={() => updateFocus('Other', 'Stay At Home Parent')}
                                                                onBlur={() => updateFocus('', '')} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            ))
                        }
                        {
                            !reduxFamilyInfo.sameRestrictions && grandChildren.map((grandChild: Beneficiary, key: number) => (
                                <>
                                <Header style={headerStyle}><b>Disability</b></Header>
                                    <Checkbox
                                        label={'I would like to include an exception to the work requirement in case ' + grandChild.legalName + ' becomes disabled, incapacitated, or incompetent.'}
                                        name='disabilityIncludeDisabilityException'
                                        value='yes'
                                        style={checkboxStyle}
                                        checked={grandChild.workRequirementExceptions.disabilityIncludeDisabilityException === true}
                                        onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'disabilityIncludeDisabilityException', value: !grandChild.workRequirementExceptions.disabilityIncludeDisabilityException }}, key)}
                                    />
                                    {grandChild.workRequirementExceptions.disabilityIncludeDisabilityException && 
                                        <>
                                            <p style={pStyle}>Check applicable:</p>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityAbleToEarnMoreThanFiftyPercentChecked'
                                                    checked={grandChild.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'disabilityAbleToEarnMoreThanFiftyPercentChecked', value: !grandChild.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>If a grand child is determined to be disabled, incapacitated, or incompetent and able to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the grand child is entitled to withdraw an amount equal to the difference of his or her average three year annual wage and his or her part time income received from part time employment.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityUnableToEarnMoreThanFiftyPercentChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'disabilityUnableToEarnMoreThanFiftyPercentChecked', value: !grandChild.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>If a grand child is determined to be disabled and unable to earn 50% or more of the average of his or her last three calendar years’ annual wages, then the grand child is entitled to the withdrawal rights her or she would otherwise be entitle to upon reaching age 62 (or the age specified above).</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityCausedByDrugOrAlcoholChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'disabilityCausedByDrugOrAlcoholChecked', value: !grandChild.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Disabilities caused by drug or alcohol addiction are to be included.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='disabilityOtherExceptionChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.disabilityOtherExceptionChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'disabilityOtherExceptionChecked', value: !grandChild.workRequirementExceptions.disabilityOtherExceptionChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.disabilityOtherExceptionChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Other</p>
                                                    <Form.Input name={'disabilityOtherExceptionValue'}
                                                                disabled={grandChild.workRequirementExceptions.disabilityOtherExceptionChecked !== true}
                                                                value={grandChild.workRequirementExceptions.disabilityOtherExceptionValue} 
                                                                onChange={(e) => updateGrandChildrenWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Other'}
                                                                placeholder={'Other'}
                                                                width={16}
                                                                onFocus={() => updateFocus('Other', 'Disability')}
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <Header style={headerStyle}><b>Stay-At-Home Parent</b></Header>
                                        <Checkbox
                                            label={'I would like to include an exception to the work requirement in case your ' + grandChild.legalName + ' becomes a stay-at-home parent with a spouse or significant other.'}
                                            name='stayAtHomeParentIncludeStayAtHomeParentException'
                                            value='yes'
                                            style={checkboxStyle}
                                            checked={grandChild.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException === true}
                                            onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'stayAtHomeParentIncludeStayAtHomeParentException', value: !grandChild.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException }}, key)}
                                        />
                                    {grandChild.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException && 
                                        <>
                                            <p style={pStyle}>Check applicable:</p>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentWorkRequirementForSpouseChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementForSpouseChecked', value: !grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>The work requirement shifts to the grand child’s spouse or significant other.</p>
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked', value: !grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>The work requirement is delayed until after the grand child children reach age 18 or</p>
                                                    <Form.Input name={'stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue'}
                                                                disabled={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked !== true}
                                                                value={grandChild.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue}
                                                                onChange={(e) => updateGrandChildrenWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Age'}
                                                                placeholder={'Age'}
                                                                width={16}
                                                                type="number"
                                                                min="1"
                                                                onFocus={() => updateFocus('Grand Child\'s Age', 'Stay At Home Parent')} 
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentPercentageOfDistributionsChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'stayAtHomeParentPercentageOfDistributionsChecked', value: !grandChild.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Percentage of trust assets that are distributions</p>
                                                    <Form.Input name={'stayAtHomeParentPercentageOfDistributionsValue'}
                                                                disabled={grandChild.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked !== true}
                                                                value={grandChild.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue}
                                                                onChange={(e) => updateGrandChildrenWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Distribution Percentage'}
                                                                placeholder={'Example: 4%'}
                                                                width={16}
                                                                type="number"
                                                                min="0"
                                                                step="0.5"
                                                                onFocus={() => updateFocus('Distribution Percentage', 'Stay At Home Parent')}
                                                                onBlur={() => updateFocus('', '')}
                                                                required />
                                                </div>
                                            </div>
                                            <div style={exceptionStyle}>
                                                <Checkbox
                                                    style={checkboxStyle}
                                                    name='stayAtHomeParentOtherExceptionChecked'
                                                    value={'true'}
                                                    checked={grandChild.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true}
                                                    onChange={(e) => updateGrandChildrenWorkRequirementExceptions({target: { name: 'stayAtHomeParentOtherExceptionChecked', value: !grandChild.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked }}, key)}
                                                />
                                                <div style={grandChild.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked === true ? checkedInputStyle : inputStyle}>
                                                    <p style={pStyle}>Other</p>
                                                    <Form.Input name={'stayAtHomeParentOtherExceptionValue'}
                                                                disabled={grandChild.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked !== true}
                                                                value={grandChild.workRequirementExceptions.stayAtHomeParentOtherExceptionValue}
                                                                onChange={(e) => updateGrandChildrenWorkRequirementExceptions(e, key)}
                                                                className={'field'}
                                                                label={'Other'}
                                                                placeholder={'Other'}
                                                                width={16}
                                                                required
                                                                onFocus={() => updateFocus('Other', 'Stay At Home Parent')}
                                                                onBlur={() => updateFocus('', '')} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            ))
                        }                         
                    </div>
                    <div className={'helpContainer'}>
                        <Header>{focus.field}</Header>
                        {/* Disability help section */}
                        {
                            !focus.field &&
                            <>
                                <p><strong>Disability:</strong> If you decide to include a work requirement for the beneficiary, you may also include an exception to this requirement in case your child becomes disabled, incapacitated, or incompetent. </p>
                                <ul>
                                    <li>
                                        The specifics of this exception can be tailored to any situation.  Typically the amount of beneficiary’s distributions will be determined by the beneficiary’s ability to earn 50% or more of their average income earned over the past three years. 
                                        <ul>
                                            <li>
                                                If beneficiary is able to earn 50% or more of the average of their last three years’ annual wages, then the beneficiary is entitled to an amount equal to the difference of their three years’ average annual wages and his or her part time income received for the year.
                                            </li>
                                            <li>
                                                If beneficiary is <u>unable</u> to earn at least 50% of the average of their last three years’ annual wages, then the beneficiary is entitled to the withdrawal rights he or she would otherwise be entitled to upon reaching the age chosen for retirement.
                                                <ul>
                                                    <li>In other words, if the beneficiary is unable to earn at least 50% of the average of their last three years wages, they will receive their retirement benefits early.  If they are able to recover and return to work, then their benefits from the trust will revert back to how they were distributed before the disability</li>
                                                </ul>
                                            </li>
                                            <li>
                                                You may specify whether or not to include disabilities caused by drug or alcohol addiction. 
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p><strong>Stay-At-Home Parent:</strong> If you decide to include a work requirement for the beneficiary, you may also include an exception to this requirement to provide for your beneficiary if he/she chooses to be a stay-at-home parent.</p>
                                <ul>
                                    <li>You may choose to have the work requirement shift to the beneficiary’s spouse or significant other; or allow the work requirement to be delayed until after the beneficiary’s children reach age 18 or any other specified age; you may specify a percentage of the trust assets to be distributed to the beneficiary while he or she is a stay at home parent; or you may specify any other distribution for the stay at home parent exception. </li>
                                    <li>Alternatively, if you do not want your beneficiary to receive distributions from your trust for being a stay-at-home parents, you would not include this exception. </li>
                                </ul>
                            </>
                        }
                        {
                            focus.field === 'Grand Child\'s Age' &&
                            <p>Enter the age your grand child must be before they need to work again.</p>
                        }
                        {
                            focus.field === 'Distribution Percentaget' &&
                            <p>Enter the percentage of trust assets that are distributions.</p>
                        }
                        {
                            focus.field === 'Other' && focus.exception === 'Disability' &&
                            <p>Include any other exceptions to work requirement that relate to disability.</p>
                        }
                        {
                            focus.field === 'Other' && focus.exception === 'Stay At Home Parent' &&
                            <p>Include any other exceptions to work requirement that relate to being a stay at home parent.</p>
                        }
                    </div>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}