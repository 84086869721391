import {AdditionalQuestions} from "../store/state";
import {initialState} from "../store/initialState";
import {additionalQuestionsActionTypes} from "../actionTypes/additionalQuestionsActionTypes";

export function additionalQuestionsReducer(state = initialState.additionalQuestions, action: {type: string, payload: any}): AdditionalQuestions {
    switch (action.type) {
        case additionalQuestionsActionTypes.UPDATE_ADDITIONAL_QUESTIONS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}