import React, { useState, CSSProperties, ChangeEvent, FormEvent, useEffect, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Radio, InputOnChangeData, FormProps, FormInputProps, Checkbox, Dropdown, Label } from 'semantic-ui-react';
import { State, GrantorsPersonalInfo } from '../store/state';
import {updateGrantorInfo} from "../actions/grantorsPersonalInformationActions";
import {Link, RouteComponentProps} from "react-router-dom";
import InputMask from 'react-input-mask';
import { containerStyle, titleStyle, pStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, headerStyle, checkboxStyle, subStyle, dropdownDivStyle, dropdownLabelStyle, dropdownStyle, radioStyle } from '../styles';
import validate from '../validationRules/grantorsPersonalInformationRules';
import useForm from '../useForm';
import { StickyContainer, Sticky } from 'react-sticky';
import {  logout } from '../actions/customerAuthActions';
import { redirectToAppropriateStep } from '../actions/routerActions';

const addressStyle: CSSProperties = {
    paddingBottom: '10px'
};

const grantorsPersonalInfoSelector = (state: State) => state.grantorsPersonalInfo;

export const GrantorsPersonalInformationComponent = (props: RouteComponentProps) => {
    document.title = "Grantors Personal Information";
    const grantorsPersonalInfo = useSelector(grantorsPersonalInfoSelector);

    const nextPage  = () => {
        try {
            dispatch(updateGrantorInfo(grantorInfo));
        } catch(e) {
            alert('An error occured. Please contact Seven Hills Technology.');
        }
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();

        const confirmation = window.confirm('Going back will cause you to lose your progress. Are you sure you want to go back?');

        if (confirmation) {
            dispatch(logout());
        }
    };

    const {
        values: grantorInfo,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: GrantorsPersonalInfo,
        setValues: Function,
        errors: any, 
        handleChange: any, 
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(grantorsPersonalInfo, nextPage, validate)

    useEffect(() => {
        setValues({...grantorsPersonalInfo});
    }, [grantorsPersonalInfo])

    const [focus, setFocus] = useState({
        person: '',
        field: ''
    });

    const updateFocus = (person: string, field: string) => {
        setFocus({
            person,
            field
        })
    };

    const dispatch = useDispatch();

    const states: any[] = [
        {
            text: 'Kentucky',
            value: 'Kentucky'
        },
        {
            text: 'Ohio',
            value: 'Ohio'
        }
    ];

    const stateOptions = states.map((state: any) => ({
        key: state.value,
        text: state.text,
        value: state.value,
    }));

    dispatch(redirectToAppropriateStep(1));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Grantor’s Personal Information</h1>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <div key={'Grantor'}>
                            <Header style={headerStyle}><b>Grantor's Information</b></Header>
                            <Form.Input name={'husbandLegalName'} 
                                        value={grantorInfo.husbandLegalName} 
                                        onChange={handleChange} 
                                        className={'field'} 
                                        label={'Legal Name'} 
                                        placeholder={'Legal Name'} 
                                        width={16} 
                                        onFocus={() => updateFocus('Grantor', 'Legal Name')} 
                                        onBlur={() => updateFocus('', '')}
                                        autoFocus
                                        required
                                        error={errors.husbandLegalName} />
                            <Form.Input name={'husbandHomeAddress'} 
                                        value={grantorInfo.husbandHomeAddress} 
                                        onChange={handleChange} 
                                        className={'field'} 
                                        label={'Home Address'} 
                                        placeholder={'Home Address'} 
                                        width={16} 
                                        onFocus={() => updateFocus('Grantor', 'Home Address')}
                                        onBlur={() => updateFocus('', '')}
                                        required
                                        error={errors.husbandHomeAddress} />
                            <Form.Dropdown name={'husbandState'}
                                          value={grantorInfo.husbandState}
                                          onChange={(e: any, data: any) => handleChange({ target: { name: 'husbandState', value: data.value } })}
                                          placeholder={'State'}
                                          search
                                          selection
                                          label="Current State"
                                          options={stateOptions}
                                          style={dropdownStyle}
                                          onFocus={() => updateFocus('Grantor', 'State')}
                                          onBlur={() => updateFocus('', '')}
                                          error={errors.propertyState}
                                          required
                                          width={6} />
                            <InputMask mask="(999) 999-9999" 
                                        value={grantorInfo.husbandTelephoneNumber} 
                                        onChange={handleChange} 
                                        maskChar={null}
                                        onFocus={() => updateFocus('Grantor', 'Telephone Number')}
                                        onBlur={() => updateFocus('', '')}>
                                            {(inputProps: FormInputProps) => <Form.Input name={'husbandTelephoneNumber'} 
                                                                                         value={grantorInfo.husbandTelephoneNumber} 
                                                                                         onChange={handleChange} 
                                                                                         className={'field'} 
                                                                                         label={'Telephone Number'} 
                                                                                         placeholder={'(513) 555-1234'} 
                                                                                         width={7} 
                                                                                         error={errors.husbandTelephoneNumber}
                                                                                         required
                                                                                         {...inputProps} />}
                            </InputMask>
                            <InputMask mask="99/99/9999"
                                       value={grantorInfo.husbandDateOfBirth}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Grantor', 'Date of Birth')}
                                       onBlur={() => updateFocus('', '')}>
                                           {(inputProps: FormInputProps) => <Form.Input name={'husbandDateOfBirth'} 
                                                                                        value={grantorInfo.husbandDateOfBirth} 
                                                                                        onChange={handleChange} 
                                                                                        className={'field'} 
                                                                                        label={'Date of Birth'} 
                                                                                        placeholder={'10/10/1965'} 
                                                                                        width={6} 
                                                                                        error={errors.husbandDateOfBirth}
                                                                                        required
                                                                                        {...inputProps} />}
                            </InputMask>
                            <div style={pStyle}>
                                <Checkbox onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'husbandShowHusbandLocation', value: !grantorInfo.husbandShowHusbandLocation } })} 
                                        name="husbandShowHusbandLocation" 
                                        checked={grantorInfo.husbandShowHusbandLocation === true} 
                                        label={'I have lived in another state or foreign location'} 
                                        style={checkboxStyle} />
                            </div>
                            {
                                grantorInfo.husbandShowHusbandLocation &&
                                <div style={subStyle}>
                                    <Form.Input name={'husbandLocation'} 
                                                value={grantorInfo.husbandLocation} 
                                                onChange={handleChange} 
                                                className={'field'} 
                                                label={'Location'} 
                                                placeholder={'Location'} 
                                                width={16} 
                                                required
                                                onFocus={() => updateFocus('Grantor', 'Location')}
                                                onBlur={() => updateFocus('', '')}
                                                error={errors.husbandLocation} />
                                    <Form.Input name={'husbandDate'} 
                                                value={grantorInfo.husbandDate} 
                                                onChange={handleChange} 
                                                className={'field'} 
                                                label={'Date'} 
                                                placeholder={'Date'} 
                                                width={16} 
                                                required
                                                onFocus={() => updateFocus('Grantor', 'Date')}
                                                onBlur={() => updateFocus('', '')}
                                                error={errors.husbandDate} />
                                </div>
                            }
                            <div style={pStyle}>
                                <Checkbox
                                    label='I have a will'
                                    name='husbandWill'
                                    value='yes'
                                    checked={grantorInfo.husbandWill === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'husbandWill', value: !grantorInfo.husbandWill } })}
                                    error={errors.husbandWill} 
                                    style={checkboxStyle} 
                                />
                            </div>
                            <div style={pStyle}>
                                <Checkbox
                                    label='I have a trust'
                                    name='husbandTrust'
                                    value='yes'
                                    checked={grantorInfo.husbandTrust === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'husbandTrust', value: !grantorInfo.husbandTrust } })}
                                    error={errors.husbandTrust} 
                                    style={checkboxStyle} 
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div style={pStyle}>
                            <Checkbox
                                label='I have a spouse'
                                name='hasSpouse'
                                value='true'
                                checked={grantorInfo.hasSpouse === true}
                                onChange={(e: any) => handleChange({persist: e.persist, target: { name: 'hasSpouse', value: !grantorInfo.hasSpouse } })}
                                error={errors.hasSpouse}
                                style={checkboxStyle}
                                />
                        </div>
                        { grantorInfo.hasSpouse &&
                            <>
                                <br/>
                                <br/>
                                <div>
                                    <Header style={headerStyle}><b>Spouse</b></Header>
                                    <Form.Input name={'wifeLegalName'} 
                                                value={grantorInfo.wifeLegalName} 
                                                onChange={handleChange} 
                                                className={'field'} 
                                                label={'Legal Name'} 
                                                placeholder={'Legal Name'} 
                                                width={16} 
                                                onFocus={() => updateFocus('Spouse', 'Legal Name')}
                                                onBlur={() => updateFocus('', '')}
                                                error={errors.wifeLegalName} />
                                    <InputMask mask="(999) 999-9999" 
                                                value={grantorInfo.wifeTelephoneNumber} 
                                                onChange={handleChange} 
                                                maskChar={null}
                                                onFocus={() => updateFocus('Spouse', 'Telephone Number')}
                                                onBlur={() => updateFocus('', '')}>
                                        {(inputProps: FormInputProps) => <Form.Input name={'wifeTelephoneNumber'} 
                                                                        value={grantorInfo.wifeTelephoneNumber} 
                                                                        onChange={handleChange} 
                                                                        className={'field'} 
                                                                        label={'Telephone Number'} 
                                                                        placeholder={'(513) 555-1234'} 
                                                                        width={7} 
                                                                        error={errors.wifeTelephoneNumber}
                                                                        {...inputProps} />}
                                    </InputMask>
                                    <InputMask mask="99/99/9999"
                                            value={grantorInfo.wifeDateOfBirth}
                                            onChange={handleChange}
                                            maskChar={null}
                                            onFocus={() => updateFocus('Spouse', 'Date of Birth')}
                                            onBlur={() => updateFocus('', '')}>
                                                {(inputProps: FormInputProps) => <Form.Input name={'wifeDateOfBirth'} 
                                                                                                value={grantorInfo.wifeDateOfBirth} 
                                                                                                onChange={handleChange} 
                                                                                                className={'field'} 
                                                                                                label={'Date of Birth'} 
                                                                                                placeholder={'10/10/1965'} 
                                                                                                width={6} 
                                                                                                error={errors.wifeDateOfBirth}
                                                                                                {...inputProps} />}
                                    </InputMask>
                                    <div style={pStyle}>
                                        <Checkbox onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'wifeShowWifeLocation', value: !grantorInfo.wifeShowWifeLocation } })} 
                                                  name="wifeShowWifeLocation" 
                                                  checked={grantorInfo.wifeShowWifeLocation === true} 
                                                  label={"I have lived in another state or foreign location"} 
                                                  style={checkboxStyle}/>
                                    </div>
                                    {
                                        grantorInfo.wifeShowWifeLocation &&
                                        <div style={subStyle}>
                                            <Form.Input name={'wifeLocation'} 
                                                        value={grantorInfo.wifeLocation} 
                                                        onChange={handleChange} 
                                                        className={'field'} 
                                                        label={'Location'} 
                                                        placeholder={'Location'} 
                                                        width={16} 
                                                        required
                                                        onFocus={() => updateFocus('Spouse', 'Location')}
                                                        onBlur={() => updateFocus('', '')}
                                                        error={errors.wifeCountry} />
                                            <Form.Input name={'wifeDate'} 
                                                        value={grantorInfo.wifeDate} 
                                                        onChange={handleChange} 
                                                        className={'field'} 
                                                        label={'Date'} 
                                                        placeholder={'Date'} 
                                                        width={16}
                                                        required
                                                        onFocus={() => updateFocus('Spouse', 'Date')}
                                                        onBlur={() => updateFocus('', '')}
                                                        error={errors.wifeDate} />
                                        </div>
                                    }
                                    <div style={pStyle}>
                                        <Checkbox
                                            label='I have a will'
                                            name='wifeWill'
                                            value='true'
                                            checked={grantorInfo.wifeWill === true}
                                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'wifeWill', value: !grantorInfo.wifeWill } })}
                                            error={errors.wifeWill}
                                            style={checkboxStyle}
                                        />
                                    </div>
                                    <div style={pStyle}>
                                        <Checkbox
                                            label='I have a trust'
                                            name='wifeTrust'
                                            value='true'
                                            checked={grantorInfo.wifeTrust === true}
                                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'wifeTrust', value: !grantorInfo.wifeTrust } })}
                                            error={errors.wifeTrust}
                                            style={checkboxStyle}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <StickyContainer className={'helpContainer'}>
                        <Sticky>
                            {({style: stickyStyle}) => 
                                <div style={stickyStyle}>
                                    {
                                        focus.field && focus.person &&
                                        <h3>{focus.field} - {focus.person}</h3>
                                    }
                                    {
                                        (!focus.field || !focus.person) &&
                                        <><h3>Grantor</h3>
                                        <p>The person who creates the trust.  He or she decides the provisions to be included, the property to be included, and who is to be the beneficiaries – who gets what and when.</p></>
                                    }
                                    {/* Grantor help section */}
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Legal Name' &&
                                        <p>Enter the grantor's legal name.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Home Address' &&
                                        <p>Enter the grantor's home address.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'County' &&
                                        <p>Enter the county the grantor lives in.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Telephone Number' &&
                                        <p>Enter the grantor's telephone number, with area code.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Date of Birth' &&
                                        <p>Enter the grantor's date of birth.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Another Country' &&
                                        <p>If the grantor has ever had a residence in another state or foreign country, click yes and enter the location and date.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Location' &&
                                        <p>Enter the location the grantor lived in previously.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Date' &&
                                        <p>Enter the the date when the grantor moved back to the United States.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Will' &&
                                        <p>Check the box if the grantor has a will in place.</p>
                                    }
                                    {
                                        focus.person === 'Grantor' && focus.field === 'Trust' &&
                                        <p>Check the box if the grantor has a trust in place.</p>
                                    }
                                    {/* Wife help section */}
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Legal Name' &&
                                        <p>Enter your spouses's full name.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Home Address' &&
                                        <p>Enter your spouse's home address.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'County' &&
                                        <p>Enter the county your spouse lives in.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Telephone Number' &&
                                        <p>Enter your spouse's preferred telephone number (with area code).</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Date of Birth' &&
                                        <p>Enter your spouse's date of birth.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Another Country' &&
                                        <p>If your spouse has ever had a residence in another state or foreign country, click yes and enter the location and date..</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Location' &&
                                        <p>Enter the location your spouse lived in previously.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Date' &&
                                        <p>Enter the the date when your spouse moved back to the United States.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Will' &&
                                        <p>Check the box if your spouse has a will in place.</p>
                                    }
                                    {
                                        focus.person === 'Spouse' && focus.field === 'Trust' &&
                                        <p>Check the box if your spouse has a trust in place</p>
                                    }
                                </div>
                            }
                        </Sticky>
                        
                    </StickyContainer>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}