import {WorkRequirementExceptions} from "../store/state";
import {initialState} from "../store/initialState";
import {workRequirementExceptionActionTypes} from "../actionTypes/workRequirementExceptionActionTypes";

export function workRequirementExceptionReducer(state = initialState.workRequirementExceptions, action: {type: string, payload: any}): WorkRequirementExceptions {
    switch (action.type) {
        case workRequirementExceptionActionTypes.UPDATE_WORK_REQUIREMENT_EXCEPTION: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}