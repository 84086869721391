import { DistributionsUponRetirement } from "../store/state";

export default function validate(distribution : DistributionsUponRetirement) {
    let errors : any = {};

    if (!distribution.includeDistributionsUponRetirement && !distribution.age) {
        errors.age = 'Retirement age is required.';
    }

    if (distribution.alternativeLifeExpectancyChecked && !distribution.alternativeLifeExpectancyMethod) {
        errors.alternativeLifeExpectancyMethod = 'Alternative method is required.';
    }

    if (distribution.differentFinancialInstitutionsChecked && !distribution.differentFinancialInstitution1 && !distribution.differentFinancialInstitution2) {
        errors.differentFinancialInstitution1 = 'Different financial institution must be entered.';
    }

    if (distribution.noLifeAnnuityChecked && !distribution.distributionPercentageValue) {
        errors.distributionPercentageValue = 'Distribution percentage is required.';
    }

    if (distribution.noLifeAnnuityChecked && !distribution.monthlyDistributionValue) {
        errors.monthlyDistributionValue = 'Monthly distribution is required.';
    }

    return errors;
};