import {Dispatch} from "redux";
import {workRequirementActionTypes} from "../actionTypes/workRequirementActionTypes";
import {familyInformationActionTypes} from "../actionTypes/familyInformationActionTypes";
import { State, WorkRequirement, FamilyInformation } from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateWorkRequirements = (familyInfo: FamilyInformation, workRequirement: WorkRequirement) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
        payload:  {
            'children': familyInfo.children,
            'grandChildren': familyInfo.grandChildren,
            'sameRestrictions': familyInfo.sameRestrictions
        }
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 4
    });
    dispatch({
        type: workRequirementActionTypes.UPDATE_WORK_REQUIREMENT,
        payload: workRequirement
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        if (familyInfo.children.some(e => e.workRequirements.includeWorkRequirement) || familyInfo.grandChildren.filter(e => e.workRequirements.includeWorkRequirement)) 
        {        
            history.push('step5');
        } else {
            history.push('step6');
        }
    } catch (e) {
        console.log(e);
        throw e;
    }
};