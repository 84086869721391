import {AssetIdentification} from "../store/state";
import {initialState} from "../store/initialState";
import {assetIdentificationActionTypes} from "../actionTypes/assetIdentificationActionTypes";

export function assetIdentificationReducer(state = initialState.assetIdentification, action: {type: string, payload: any}): AssetIdentification {
    switch (action.type) {
        case assetIdentificationActionTypes.UPDATE_ASSETS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}