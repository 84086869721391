import React, {useState, FormEvent, useEffect, useReducer,} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Checkbox, FormProps } from 'semantic-ui-react';
import {State, RequiredDistribution, GrantorsPersonalInfo, Beneficiary} from '../store/state';
import {updateRequiredDistributions} from "../actions/requiredDistributionsActions";
import {Link, RouteComponentProps} from "react-router-dom";
import { containerStyle, titleStyle, pStyle, checkedInputStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, distributionStyle, checkboxStyle } from '../styles';
import useForm from '../useForm';
import validate from '../validationRules/requiredDistributionsRules';
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const familyInformationSelector = (state: State) => state.familyInformation;
const requiredDistributionSelector = (state: State) => state.requiredDistribution;
const childrenSelector = (state: State) => state.familyInformation.children;
const grandChildrenSelector = (state: State) => state.familyInformation.grandChildren;

export const RequiredDistributionsComponent = (props: RouteComponentProps) => {
    document.title = "Required Distributions Until Retirement";
    const reduxFamilyInfo = useSelector(familyInformationSelector);
    const reduxRequiredDistribution = useSelector(requiredDistributionSelector);

    const reduxChildren = useSelector(childrenSelector);
    const reduxGrandChildren = useSelector(grandChildrenSelector);

    const [children, setChildRequiredDistributionValues] = useReducer((children, { target, type, key }) => {
        switch (type) {
            case "update":
                var child = children.find((_: any, index: number) => index === key);
                child.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value;
                return [...children];
            case "updateAll":
                children.forEach((child: any)=>{child.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value});
                return [...children];
            case "checkGroup":
                children.forEach((child: any)=>{child.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value});
                return [...children];
            case "set":
                return [...reduxChildren];
            default:
                return reduxChildren;
        }
    }, reduxChildren);

    useEffect(() => {
        setChildRequiredDistributionValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);    

    const updateRequiredDistribution = (e: any, index: number ) => {
        setChildRequiredDistributionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const updateAllRequiredDistributions = (e: any) => {
        setChildRequiredDistributionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
        setGrandChildRequiredDistributionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
    };

    const [grandChildren, setGrandChildRequiredDistributionValues] = useReducer((grandChildren, { target, type, key }) => {
        switch (type) {
            case "update":
                var grandChild = grandChildren.find((_: any, index: number) => index === key);
                grandChild.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value;
                return [...grandChildren];
            case "updateAll":
                grandChildren.forEach((child: any)=>{child.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value});
                return [...grandChildren];
            case "checkGroup":
                grandChildren.forEach((child: any)=>{child.requiredDistributions[target.name] = target.type === 'number' ? parseInt(target.value) : target.value});
                return [...grandChildren];
            case "set":
                return [...reduxGrandChildren];
            default:
                return reduxGrandChildren;
        }
    }, reduxGrandChildren);

    useEffect(() => {
        setGrandChildRequiredDistributionValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateGrandChildrenRequiredDistribution = (e: any, index: number ) => {
        setGrandChildRequiredDistributionValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const [useChildren, setUseChildren] = useState(children.length > 0);

    const nextPage  = () => {
        dispatch(updateRequiredDistributions({children: children, grandChildren: grandChildren, sameRestrictions: reduxFamilyInfo.sameRestrictions}, distributionInfo));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const {
        values: distributionInfo,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: RequiredDistribution,
        setValues: Function,
        errors: any,
        handleChange: any,
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(reduxRequiredDistribution, nextPage, validate)

    useEffect(() => {
        setValues({...reduxRequiredDistribution});
    }, [reduxRequiredDistribution]);

    const dispatch = useDispatch();
    dispatch(redirectToAppropriateStep(3));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Required Distributions Until Retirement</h1>
            <Form onSubmit={nextPage}>
                <div className={'contentStyle'}>
                    {
                        <div className={'informationContainer'}>
                            <p>Each child and grand child has the right to certain distributions from their Trust until that child reaches
                                the age of</p>
                            {
                                (reduxFamilyInfo.sameRestrictions && 
                                    (children.length > 0 || grandChildren.length > 0)) &&
                                <Form.Input name={'age'}
                                            value={useChildren ? children[0].requiredDistributions.age : grandChildren[0].requiredDistributions.age}
                                            onChange={(e) => updateAllRequiredDistributions(e)}
                                            className={'field'}
                                            label={'Age'}
                                            placeholder={'Age'}
                                            width={16}
                                            onFocus={() => updateFocus('Age')}
                                            onBlur={() => updateFocus('')}
                                            autoFocus
                                            type="number"
                                            min="1"
                                            required/>
                            }
                            {
                                !reduxFamilyInfo.sameRestrictions && children.map((child: Beneficiary, key: number) => (
                                    <Form.Input name={'age'}
                                                value={child.requiredDistributions.age}
                                                key={key}
                                                onChange={(e) => updateRequiredDistribution(e, key)}
                                                className={'field'}
                                                label={child.legalName + ' Age'}
                                                placeholder={'Age'}
                                                width={16}
                                                onFocus={() => updateFocus('Age')}
                                                onBlur={() => updateFocus('')}
                                                autoFocus
                                                type="number"
                                                min="1"
                                                required/>
                                ))
                            }
                            {
                                !reduxFamilyInfo.sameRestrictions && grandChildren.map((grandChild: Beneficiary, key: number) => (
                                    <Form.Input name={'age'}
                                    value={grandChild.requiredDistributions.age}
                                    key={key}
                                    onChange={(e) => updateGrandChildrenRequiredDistribution(e, key)}
                                    className={'field'}
                                    label={grandChild.legalName + ' Age'}
                                    placeholder={'Age'}
                                    width={16}
                                    onFocus={() => updateFocus('Age')}
                                    onBlur={() => updateFocus('')}
                                    autoFocus
                                    type="number"
                                    min="1"
                                    required/>
                                ))
                            }
                            <p style={pStyle}>How much are the required distributions for each child until age 62 (or the
                                age specified above)? (Check all applicable):</p>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='fixedDollarBoxChecked'
                                    value={'true'}
                                    checked={distributionInfo.fixedDollarBoxChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'fixedDollarBoxChecked', value: !distributionInfo.fixedDollarBoxChecked }})}
                                />
                                <div style={distributionInfo.fixedDollarBoxChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <>
                                            <Form.Input name={'fixedDollarAmount'}
                                                        disabled={distributionInfo.fixedDollarBoxChecked !== true}
                                                        value={useChildren ? children[0].requiredDistributions.fixedDollarAmount : grandChildren[0].requiredDistributions.fixedDollarAmount}
                                                        onChange={(e) => updateAllRequiredDistributions(e)}
                                                        className={'field'}
                                                        label={'Fixed Dollar Amount'}
                                                        placeholder={'Fixed Dollar Amount'}
                                                        width={16}
                                                        onFocus={() => updateFocus('Fixed Dollar Amount')}
                                                        onBlur={() => updateFocus('')}
                                                        required
                                                        type="number"
                                                        min="0"/>
                                        </>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Fixed dollar amount of the trust income and principal each
                                                year, adjusted for inflation annually</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => (
                                                <div>
                                                    <Form.Input name={'fixedDollarAmount'}
                                                                disabled={!distributionInfo.fixedDollarBoxChecked}
                                                                value={child.requiredDistributions.fixedDollarAmount}
                                                                onChange={(e) => updateRequiredDistribution(e, key)}
                                                                className={'field'}
                                                                key={key}
                                                                label={child.legalName + ' Fixed Dollar Amount'}
                                                                placeholder={'Fixed Dollar Amount'}
                                                                width={16}
                                                                onFocus={() => updateFocus('Fixed Dollar Amount')}
                                                                onBlur={() => updateFocus('')}
                                                                required
                                                                type="number"
                                                                step="0.01"
                                                                min="0"/>
                                                </div>
                                                ))
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                    <>
                                                        <Form.Input name={'fixedDollarAmount'}
                                                                    disabled={!distributionInfo.fixedDollarBoxChecked}
                                                                    value={grandChild.requiredDistributions.fixedDollarAmount}
                                                                    onChange={(e) => updateGrandChildrenRequiredDistribution(e, key)}
                                                                    className={'field'}
                                                                    key={key}
                                                                    label={grandChild.legalName + ' Fixed Dollar Amount'}
                                                                    placeholder={'Fixed Dollar Amount'}
                                                                    width={16}
                                                                    onFocus={() => updateFocus('Fixed Dollar Amount')}
                                                                    onBlur={() => updateFocus('')}
                                                                    required
                                                                    type="number"
                                                                    min="0"/>
                                                    </>
                                                ))
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='fixedPercentageBoxChecked'
                                    value={'true'}
                                    checked={distributionInfo.fixedPercentageBoxChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'fixedPercentageBoxChecked', value: !distributionInfo.fixedPercentageBoxChecked }})}
                                />
                                <div style={distributionInfo.fixedPercentageBoxChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <>
                                            <Form.Input name={'fixedPercentageAmount'}
                                                        disabled={!distributionInfo.fixedPercentageBoxChecked}
                                                        value={useChildren ? children[0].requiredDistributions.fixedPercentageAmount : grandChildren[0].requiredDistributions.fixedPercentageAmount}
                                                        onChange={(e) => updateAllRequiredDistributions(e)}
                                                        className={'field'}
                                                        label={'Fixed Percentage Amount'}
                                                        placeholder={'Fixed Percentage Amount'}
                                                        width={16}
                                                        onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                        onBlur={() => updateFocus('')}
                                                        required
                                                        type="number"
                                                        min="0"/>
                                        </>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Fixed percentage amount of the trust principal each
                                                year</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => (
                                                    <div>
                                                        <Form.Input name={'fixedPercentageAmount'}
                                                                    disabled={!distributionInfo.fixedPercentageBoxChecked}
                                                                    value={child.requiredDistributions.fixedPercentageAmount}
                                                                    onChange={(e) => updateRequiredDistribution(e, key)}
                                                                    className={'field'}
                                                                    label={child.legalName + ' Fixed Percentage Amount'}
                                                                    placeholder={'Fixed Percentage Amount'}
                                                                    key={key}
                                                                    width={16}
                                                                    onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                                    onBlur={() => updateFocus('')}
                                                                    required
                                                                    type="number"
                                                                    min="0"/>
                                                    </div>
                                                ))
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                    <>
                                                        <Form.Input name={'fixedPercentageAmount'}
                                                                    disabled={!distributionInfo.fixedPercentageBoxChecked}
                                                                    value={grandChild.requiredDistributions.fixedPercentageAmount}
                                                                    onChange={(e) => updateGrandChildrenRequiredDistribution(e, key)}
                                                                    className={'field'}
                                                                    label={grandChild.legalName + ' Fixed Percentage Amount'}
                                                                    placeholder={'Fixed Percentage Amount'}
                                                                    key={key}
                                                                    width={16}
                                                                    onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                                    onBlur={() => updateFocus('')}
                                                                    required
                                                                    type="number"
                                                                    min="0"/>
                                                    </>
                                                ))
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='workRequirementBoxChecked'
                                    value={'true'}
                                    checked={distributionInfo.workRequirementBoxChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'workRequirementBoxChecked', value: !distributionInfo.workRequirementBoxChecked }})}
                                />
                                <div style={distributionInfo.workRequirementBoxChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        reduxFamilyInfo.sameRestrictions &&
                                        <div style={distributionStyle}>
                                            <p style={pStyle}>Include a work requirement</p>
                                        </div>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <div>
                                            {
                                                children.map((child: Beneficiary, key: number) => (
                                                    <>
                                                        <Checkbox
                                                            style={checkboxStyle}
                                                            disabled={!distributionInfo.workRequirementBoxChecked}
                                                            name='workRequirementChecked'
                                                            value={'true'}
                                                            label={`Work requirement for ${child.legalName}`}
                                                            key={key}
                                                            checked={child.requiredDistributions.workRequirementChecked === true}
                                                            onChange={(e) => updateRequiredDistribution({target: { name: 'workRequirementChecked', value: !child.requiredDistributions.workRequirementChecked }}, key)}
                                                        />
                                                        <br></br>
                                                    </>
                                                ))
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                    <>
                                                        <Checkbox
                                                            style={checkboxStyle}
                                                            disabled={!distributionInfo.workRequirementBoxChecked}
                                                            name='workRequirementChecked'
                                                            value={'true'}
                                                            label={grandChild.legalName}
                                                            key={key}
                                                            checked={grandChild.requiredDistributions.workRequirementChecked === true}
                                                            onChange={(e) => updateGrandChildrenRequiredDistribution({target: { name: 'workRequirementChecked', value: !grandChild.requiredDistributions.workRequirementChecked }}, key)}
                                                        />
                                                        <br></br>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='otherDistributionBoxChecked'
                                    value={'true'}
                                    checked={distributionInfo.otherDistributionBoxChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'otherDistributionBoxChecked', value: !distributionInfo.otherDistributionBoxChecked }})}
                                />
                                <div style={distributionInfo.otherDistributionBoxChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <>
                                            <Form.Input name={'otherDistributionDescription'}
                                                        disabled={!distributionInfo.otherDistributionBoxChecked}
                                                        value={useChildren ? children[0].requiredDistributions.otherDistributionDescription : grandChildren[0].requiredDistributions.otherDistributionDescription}
                                                        onChange={(e) => updateAllRequiredDistributions(e)}
                                                        className={'field'}
                                                        label={'Other'}
                                                        placeholder={'Other'}
                                                        width={16}
                                                        onFocus={() => updateFocus('Other')}
                                                        onBlur={() => updateFocus('')}/>
                                        </>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Other</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => (
                                                    <div>
                                                        <Form.Input name={'otherDistributionDescription'}
                                                                    disabled={!distributionInfo.otherDistributionBoxChecked}
                                                                    value={child.requiredDistributions.otherDistributionDescription}
                                                                    onChange={(e) => updateRequiredDistribution(e, key)}
                                                                    className={'field'}
                                                                    key={key}
                                                                    label={child.legalName}
                                                                    placeholder={'Other'}
                                                                    width={16}
                                                                    onFocus={() => updateFocus('Other')}
                                                                    onBlur={() => updateFocus('')}/>
                                                    </div>
                                                ))
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => (
                                                    <>
                                                        <Form.Input name={'otherDistributionDescription'}
                                                                    disabled={!distributionInfo.otherDistributionBoxChecked}
                                                                    value={grandChild.requiredDistributions.otherDistributionDescription}
                                                                    onChange={(e) => updateGrandChildrenRequiredDistribution(e, key)}
                                                                    className={'field'}
                                                                    key={key}
                                                                    label={grandChild.legalName}
                                                                    placeholder={'Other'}
                                                                    width={16}
                                                                    onFocus={() => updateFocus('Other')}
                                                                    onBlur={() => updateFocus('')}/>
                                                    </>
                                                ))
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className={'helpContainer'}>
                        <Header>Child {focus.field && `- ${focus.field}`}</Header>
                        {/* Help section */}
                        {
                            focus.field === 'Age' &&
                            <><p>Enter the age at which each child obtains the right to certain distributions from their Trust.</p>
                            <p>We suggest age 62 because that would be the typical age for a person to be able to retire.  This can be changed to any age.  At the designated age, the beneficiary will receive their monthly distribution.  This distribution acts as a pension/annuity to supplement beneficiary’s income for the rest of their life.</p>
                            <p>You may allow for the yearly distribution: (1) to be a fixed amount (i.e. $20k); (2) a percentage of their trust principle (i.e. 4%, which would allow the trust nest egg to grow, while supplementing beneficiary’s income.); (3) or you may also include a work requirement for the beneficiary (Section: Work Requirement) matching the beneficiary’s yearly wages.  You may customize these distributions to be administered in any way not already mentioned.</p></>
                        }
                        {
                            focus.field === 'Fixed Dollar Amount' &&
                            <p>Enter the fixed dollar amount of the trust income and principal each year, adjusted for inflation annually.</p>
                        }
                        {
                            focus.field === 'Fixed Percentage Amount' &&
                            <p>Fixed percentage of the trust principal each year.</p>
                        }
                        {
                            focus.field === 'Other' &&
                            <p>Any other requirement should be specified here.</p>
                        }
                    </div>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}