import {Dispatch} from "redux";
import {requiredDistributionsActionTypes} from "../actionTypes/requiredDistributionsActionTypes";
import {State, Beneficiary, RequiredDistribution, FamilyInformation} from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";
import {familyInformationActionTypes} from "../actionTypes/familyInformationActionTypes";

export const updateRequiredDistributions = (familyInfo: FamilyInformation, requiredDistribution: RequiredDistribution) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
        payload:  {
            'children': familyInfo.children,
            'grandChildren': familyInfo.grandChildren,
            'sameRestrictions': familyInfo.sameRestrictions
        }
    });
    dispatch({
        type: requiredDistributionsActionTypes.UPDATE_REQUIRED_DISTRIBUTIONS,
        payload: requiredDistribution
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 3
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        if (requiredDistribution.workRequirementBoxChecked && (familyInfo.children.some(e => e.requiredDistributions.workRequirementChecked) || familyInfo.grandChildren.filter(e => e.requiredDistributions.workRequirementChecked))) {
            history.push('step4');
        } else {
            dispatch({
                type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
                payload: 5
            });
            history.push('step6')
        }
    } catch (e) {
        console.log(e);
        throw e;
    }
};