import {
    State,
    GrantorsPersonalInfo,
    FamilyInformation,
    RequiredDistribution,
    WorkRequirement,
    DistributionsUponRetirement,
    DeathOfBeneficiary,
    Welcome,
    SuccessorTrustees,
    AdditionalQuestions, HealthCareDocuments, AssetIdentification, Customer
} from './store/state';
import moment from 'moment';

export interface ServerCustomer {
    customerId: string;
    email: string;
    dateCreated: Date;
    lastActivity: Date;
}

export interface ServerResponse {
    trustees: {legalName: string, orderIndex: number}[];
    customer: any;
    customerId: string;
    currentStep: number;
    husbandLegalName: string;
    wifeLegalName?: string;
    husbandHomeAddress: string;
    wifeHomeAddress?: string;
    husbandState: string;
    husbandHomeAddressCounty: string;
    wifeHomeAddressCounty?: string;
    husbandTelephoneNumber: string;
    wifeTelephoneNumber?: string;
    husbandEmailAddress: string;
    wifeEmailAddress?: string;
    husbandDateOfBirth?: Date;
    wifeDateOfBirth?: Date;
    husbandForeignLocation?: string;
    wifeForeignLocation?: string;
    husbandForeignLocationDate?: string;
    wifeForeignLocationDate?: string;
    husbandWill: boolean;
    wifeWill?: boolean;
    husbandTrust: boolean;
    wifeTrust?: boolean;
    sameRestrictions: boolean;
    children?: any[];
    grandchildren?: any[];    
    remainingAssetsType: string;
    remainingAssetsOtherDescription?: string;
    primaryExecutor: string;
    alternateExecutor1?: string;
    alternateExecutor2?: string;
    primaryAttorneyInFact: string;
    alternateAttorneyInFact1?: string;
    alternateAttorneyInFact2?: string;
    attorneyInFactOperation?: string;
    primaryGuardian: string;
    alternateGuardian1?: string;
    alternateGuardian2?: string;
    monthlyPaymentForGuardian: boolean;
    oneTimePaymentForGuardianHome: boolean;
    primaryPowerOfAttorneyLegalName: string;
    primaryPowerOfAttorneyHomeAddress: string;
    primaryPowerOfAttorneyPhoneNumber: string;
    alternatePowerOfAttorney1LegalName?: string;
    alternatePowerOfAttorney1HomeAddress?: string;
    alternatePowerOfAttorney1PhoneNumber?: string;
    alternatePowerOfAttorney2LegalName?: string;
    alternatePowerOfAttorney2HomeAddress?: string;
    alternatePowerOfAttorney2PhoneNumber?: string;
    executeLivingWill: boolean;
    usePowerOfAttorneyContacts: boolean;
    livingWillContact1LegalName?: string;
    livingWillContact1HomeAddress?: string;
    livingWillContact1PhoneNumber?: string;
    livingWillContact2LegalName?: string;
    livingWillContact2HomeAddress?: string;
    livingWillContact2PhoneNumber?: string;
    primarySurrogateLegalName: string;
    primarySurrogateHomeAddress: string;
    primarySurrogatePhoneNumber: string;
    alternateSurrogateLegalName: string;
    alternateSurrogateHomeAddress: string;
    alternateSurrogatePhoneNumber: string;
    hasProperty: boolean;
    propertyCity: string;
    propertyState: string;
    survivorshipWithSpouse: boolean;
    otherHoldsRights: boolean;
    currentMarketValue: number;
    investmentsOrVacationProperties: boolean;
    investmentOrVacationLocations: string;
    investmentOrVacationOwners: string;
    lifeInsuranceChecked: boolean;
    lifeInsuranceCompany: string;
    lifeInsurancePolicyNumber: string;
    lifeInsuranceOwner: string;
    lifeInsuranceBeneficiary: string;
    lifeInsuranceAmount: number;
    investments: string;
    retirementPlans: string;
    comments: string;
    paymentComplete: boolean;
}

export const convertToServerResponse = (state : State) => {
    let trustees = [];
    if (state.successorTrustees.trustee1) {
        trustees.push({
            legalName: state.successorTrustees.trustee1,
            orderIndex: 1
        });
    }

    if (state.successorTrustees.trustee2) {
        trustees.push({
            legalName: state.successorTrustees.trustee2,
            orderIndex: 2
        });
    }

    if (state.successorTrustees.trustee3) {
        trustees.push({
            legalName: state.successorTrustees.trustee3,
            orderIndex: 3
        });
    }

    if (state.successorTrustees.trustee4) {
        trustees.push({
            legalName: state.successorTrustees.trustee4,
            orderIndex: 4
        });
    }

    if (state.successorTrustees.trustee5) {
        trustees.push({
            legalName: state.successorTrustees.trustee5,
            orderIndex: 5
        });
    }
    debugger;
    const translatedResponse : ServerResponse = {
        trustees,
        customer: state.customerAuth ? state.customerAuth : undefined,
        customerId: state.customerAuth ? state.customerAuth.customerId : "undefined",
        currentStep: state.customerAuth ? state.customerAuth.currentStep : 0,
        husbandLegalName: state.grantorsPersonalInfo.husbandLegalName,
        husbandHomeAddress: state.grantorsPersonalInfo.husbandHomeAddress,
        husbandState: state.grantorsPersonalInfo.husbandState,
        husbandHomeAddressCounty: state.grantorsPersonalInfo.husbandCounty,
        husbandTelephoneNumber: state.grantorsPersonalInfo.husbandTelephoneNumber,
        husbandEmailAddress: state.welcome.email,
        husbandDateOfBirth: new Date(state.grantorsPersonalInfo.husbandDateOfBirth) ? new Date(state.grantorsPersonalInfo.husbandDateOfBirth) : undefined,
        husbandForeignLocation: state.grantorsPersonalInfo.husbandLocation,
        husbandForeignLocationDate: state.grantorsPersonalInfo.husbandDate,
        husbandTrust: state.grantorsPersonalInfo.husbandTrust,
        husbandWill: state.grantorsPersonalInfo.husbandWill,
        wifeLegalName: state.grantorsPersonalInfo.wifeLegalName,
        wifeHomeAddress: state.grantorsPersonalInfo.husbandHomeAddress,
        wifeHomeAddressCounty: state.grantorsPersonalInfo.husbandCounty,
        wifeTelephoneNumber: state.grantorsPersonalInfo.wifeTelephoneNumber,
        wifeEmailAddress: state.welcome.email,
        wifeDateOfBirth: state.grantorsPersonalInfo.wifeDateOfBirth ? new Date(state.grantorsPersonalInfo.wifeDateOfBirth) : undefined,
        wifeForeignLocation: state.grantorsPersonalInfo.wifeLocation,
        wifeForeignLocationDate: state.grantorsPersonalInfo.wifeDate,
        wifeTrust: state.grantorsPersonalInfo.wifeTrust,
        wifeWill: state.grantorsPersonalInfo.wifeWill,
        sameRestrictions: state.familyInformation.sameRestrictions,
        children: state.familyInformation.children.map(x => {
            return {
                id: x.id,
                ...x,
                dateOfBirth: new Date(x.dateOfBirth),
                address: x.homeAddress, 
                email: x.emailAddress,
                requiredDistributions: {
                    childRightToDistributionsAge: x.requiredDistributions.age,
                    fixedDollarAmount: x.requiredDistributions.fixedDollarAmount,
                    fixedPercentagePercent: x.requiredDistributions.fixedPercentageAmount,
                    includeWorkRequirement: x.requiredDistributions.workRequirementChecked,
                    otherDistributionRequirementDescription: x.requiredDistributions.otherDistributionDescription
                },
                workRequirements: {
                    includeWorkRequirement: x.workRequirements.includeWorkRequirement,
                    equivalentToPreviousWages: x.workRequirements.equivalentYearlyWagesChecked,
                    previouseWagesPercent: x.workRequirements.fixedPercentageYearlyWagesAmount,
                    cappedDollarAmount: x.workRequirements.cappedAmount,
                    cappedPercentAmount: x.workRequirements.cappedPercentage,
                    otherWorkRequirementDescription: x.workRequirements.otherRequirementValue
                },
                workRequirementExceptions: {
                    disabilityException: x.workRequirementExceptions.disabilityIncludeDisabilityException,
                    disabilityCanEarnException: x.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked,
                    disabilityCannotEarnException: x.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked,
                    disabilityDrugsOrAlcholException: x.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked,
                    otherDisabilityExceptionDescription: x.workRequirementExceptions.disabilityOtherExceptionValue,
                    otherDisabilityException: x.workRequirementExceptions.disabilityOtherExceptionChecked,
                    stayAtHomeParentException: x.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException,
                    workRequirementShifts: x.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked,
                    workRequirementDelayedChildAge: x.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue,
                    workRequirementDelayed: x.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked,
                    stayAtHomeDistributionPercentageAmount: x.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue,
                    stayAtHomeDistributionPercentage: x.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked,
                    otherStayAtHomeExceptionDescription: x.workRequirementExceptions.stayAtHomeParentOtherExceptionValue,
                    otherStayAtHomeException: x.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked
                },
                distributionsUponRetirement: {    
                    makePaymentsAtSpecifiedAge: x.distributionsUponRetirement.includeDistributionsUponRetirement,
                    makePaymentsAlternativeDescription: x.distributionsUponRetirement.age ? x.distributionsUponRetirement.age.toString() : "",
                    monthlyPaymentsForLife: x.distributionsUponRetirement.remainingBalanceChecked,
                    useDifferentInstitutions: x.distributionsUponRetirement.differentFinancialInstitutionsChecked,
                    differentInstitution1: x.distributionsUponRetirement.differentFinancialInstitution1,
                    differentInstitution2: x.distributionsUponRetirement.differentFinancialInstitution2,
                    alternativeLifeExpectancyMethod: x.distributionsUponRetirement.alternativeLifeExpectancyChecked,
                    alternativeLifeExpectancyMethodDescription: x.distributionsUponRetirement.alternativeLifeExpectancyMethod,
                    fixedDollarDistribution: x.distributionsUponRetirement.noLifeAnnuityChecked,
                    fixedDollarDistributionAmount: x.distributionsUponRetirement.monthlyDistributionValue,
                    fixedPercentageDistribution: x.distributionsUponRetirement.noLifeAnnuityChecked,
                    fixedPercentageDistributionPercent: x.distributionsUponRetirement.distributionPercentageValue,
                }
            }
        }),
        grandchildren: state.familyInformation.grandChildren.map(x => {
            return {
                id: x.id,
                ...x,
                dateOfBirth: new Date(x.dateOfBirth), 
                address: x.homeAddress, 
                email: x.emailAddress,
                requiredDistributions: {
                    childRightToDistributionsAge: x.requiredDistributions.age,
                    fixedDollarAmount: x.requiredDistributions.fixedDollarAmount,
                    fixedPercentagePercent: x.requiredDistributions.fixedPercentageAmount,
                    includeWorkRequirement: x.requiredDistributions.workRequirementChecked,
                    otherDistributionRequirementDescription: x.requiredDistributions.otherDistributionDescription
                },
                workRequirements: {
                    includeWorkRequirement: x.workRequirements.includeWorkRequirement,
                    equivalentToPreviousWages: x.workRequirements.equivalentYearlyWagesChecked,
                    previouseWagesPercent: x.workRequirements.fixedPercentageYearlyWagesAmount,
                    cappedDollarAmount: x.workRequirements.cappedAmount,
                    cappedPercentAmount: x.workRequirements.cappedPercentage,
                    otherWorkRequirementDescription: x.workRequirements.otherRequirementValue
                },
                workRequirementExceptions: {
                    disabilityException: x.workRequirementExceptions.disabilityIncludeDisabilityException,
                    disabilityCanEarnException: x.workRequirementExceptions.disabilityAbleToEarnMoreThanFiftyPercentChecked,
                    disabilityCannotEarnException: x.workRequirementExceptions.disabilityUnableToEarnMoreThanFiftyPercentChecked,
                    disabilityDrugsOrAlcholException: x.workRequirementExceptions.disabilityCausedByDrugOrAlcoholChecked,
                    otherDisabilityExceptionDescription: x.workRequirementExceptions.disabilityOtherExceptionValue,
                    otherDisabilityException: x.workRequirementExceptions.disabilityOtherExceptionChecked,
                    stayAtHomeParentException: x.workRequirementExceptions.stayAtHomeParentIncludeStayAtHomeParentException,
                    workRequirementShifts: x.workRequirementExceptions.stayAtHomeParentWorkRequirementForSpouseChecked,
                    workRequirementDelayedChildAge: x.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue,
                    workRequirementDelayed: x.workRequirementExceptions.stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked,
                    stayAtHomeDistributionPercentageAmount: x.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsValue,
                    stayAtHomeDistributionPercentage: x.workRequirementExceptions.stayAtHomeParentPercentageOfDistributionsChecked,
                    otherStayAtHomeExceptionDescription: x.workRequirementExceptions.stayAtHomeParentOtherExceptionValue,
                    otherStayAtHomeException: x.workRequirementExceptions.stayAtHomeParentOtherExceptionChecked
                },
                distributionsUponRetirement: {    
                    makePaymentsAtSpecifiedAge: x.distributionsUponRetirement.includeDistributionsUponRetirement,
                    makePaymentsAlternativeDescription: x.distributionsUponRetirement.age ? x.distributionsUponRetirement.age.toString() : "",
                    monthlyPaymentsForLife: x.distributionsUponRetirement.remainingBalanceChecked,
                    useDifferentInstitutions: x.distributionsUponRetirement.differentFinancialInstitutionsChecked,
                    differentInstitution1: x.distributionsUponRetirement.differentFinancialInstitution1,
                    differentInstitution2: x.distributionsUponRetirement.differentFinancialInstitution2,
                    alternativeLifeExpectancyMethod: x.distributionsUponRetirement.alternativeLifeExpectancyChecked,
                    alternativeLifeExpectancyMethodDescription: x.distributionsUponRetirement.alternativeLifeExpectancyMethod,
                    fixedDollarDistribution: x.distributionsUponRetirement.noLifeAnnuityChecked,
                    fixedDollarDistributionAmount: x.distributionsUponRetirement.monthlyDistributionValue,
                    fixedPercentageDistribution: x.distributionsUponRetirement.noLifeAnnuityChecked,
                    fixedPercentageDistributionPercent: x.distributionsUponRetirement.distributionPercentageValue,
                }
            }
        }),
        remainingAssetsType: state.deathOfBeneficiary.selectedBeneficiary,
        remainingAssetsOtherDescription: state.deathOfBeneficiary.otherValue,
        primaryExecutor: state.additionalQuestions.primaryExecutor,
        alternateExecutor1: state.additionalQuestions.alternateExecutor1,
        alternateExecutor2: state.additionalQuestions.alternateExecutor2,
        primaryAttorneyInFact: state.additionalQuestions.primaryAttorneyInFact,
        alternateAttorneyInFact1: state.additionalQuestions.alternateAttorneyInFact1,
        alternateAttorneyInFact2: state.additionalQuestions.alternateAttorneyInFact2,
        attorneyInFactOperation: state.additionalQuestions.attorneyInFactOperation,
        primaryGuardian: state.additionalQuestions.primaryGuardian,
        alternateGuardian1: state.additionalQuestions.alternateGuardian1,
        alternateGuardian2: state.additionalQuestions.alternateGuardian2,
        monthlyPaymentForGuardian: state.additionalQuestions.monthlyPaymentForGuardian,
        oneTimePaymentForGuardianHome: state.additionalQuestions.oneTimePaymentForGuardianHome,
        primaryPowerOfAttorneyLegalName: state.healthCareDocuments.primaryPowerOfAttorneyLegalName,
        primaryPowerOfAttorneyHomeAddress: state.healthCareDocuments.primaryPowerOfAttorneyHomeAddress,
        primaryPowerOfAttorneyPhoneNumber: state.healthCareDocuments.primaryPowerOfAttorneyPhoneNumber,
        alternatePowerOfAttorney1LegalName: state.healthCareDocuments.alternatePowerOfAttorney1LegalName,
        alternatePowerOfAttorney1HomeAddress: state.healthCareDocuments.alternatePowerOfAttorney1HomeAddress,
        alternatePowerOfAttorney1PhoneNumber: state.healthCareDocuments.alternatePowerOfAttorney1PhoneNumber,
        alternatePowerOfAttorney2LegalName: state.healthCareDocuments.alternatePowerOfAttorney2LegalName,
        alternatePowerOfAttorney2HomeAddress: state.healthCareDocuments.alternatePowerOfAttorney2HomeAddress,
        alternatePowerOfAttorney2PhoneNumber: state.healthCareDocuments.alternatePowerOfAttorney2PhoneNumber,
        executeLivingWill: state.healthCareDocuments.executeLivingWill,
        usePowerOfAttorneyContacts: state.healthCareDocuments.usePowerOfAttorneyContacts,
        livingWillContact1LegalName: state.healthCareDocuments.livingWillContact1LegalName,
        livingWillContact1HomeAddress: state.healthCareDocuments.livingWillContact1HomeAddress,
        livingWillContact1PhoneNumber: state.healthCareDocuments.livingWillContact1PhoneNumber,
        livingWillContact2LegalName: state.healthCareDocuments.livingWillContact2LegalName,
        livingWillContact2HomeAddress: state.healthCareDocuments.livingWillContact2HomeAddress,
        livingWillContact2PhoneNumber: state.healthCareDocuments.livingWillContact2PhoneNumber,
        primarySurrogateLegalName: state.healthCareDocuments.primarySurrogateLegalName,
        primarySurrogateHomeAddress: state.healthCareDocuments.primarySurrogateHomeAddress,
        primarySurrogatePhoneNumber: state.healthCareDocuments.primarySurrogatePhoneNumber,
        alternateSurrogateLegalName: state.healthCareDocuments.alternateSurrogateLegalName,
        alternateSurrogateHomeAddress: state.healthCareDocuments.alternateSurrogateHomeAddress,
        alternateSurrogatePhoneNumber: state.healthCareDocuments.alternateSurrogatePhoneNumber,
        hasProperty: state.assetIdentification.hasProperty,
        propertyCity: state.assetIdentification.propertyCity,
        propertyState: state.assetIdentification.propertyState,
        survivorshipWithSpouse: state.assetIdentification.survivorshipWithSpouse,
        otherHoldsRights: state.assetIdentification.otherHoldsRights,
        currentMarketValue: state.assetIdentification.currentMarketValue,
        investmentsOrVacationProperties: state.assetIdentification.investmentsOrVacationProperties,
        investmentOrVacationLocations: state.assetIdentification.investmentOrVacationLocations,
        investmentOrVacationOwners: state.assetIdentification.investmentOrVacationOwners,
        lifeInsuranceChecked: state.assetIdentification.lifeInsuranceChecked,
        lifeInsuranceCompany: state.assetIdentification.lifeInsuranceCompany,
        lifeInsurancePolicyNumber: state.assetIdentification.lifeInsurancePolicyNumber,
        lifeInsuranceOwner: state.assetIdentification.lifeInsuranceOwner,
        lifeInsuranceBeneficiary: state.assetIdentification.lifeInsuranceBeneficiary,
        lifeInsuranceAmount: state.assetIdentification.lifeInsuranceAmount,
        investments: state.assetIdentification.investments,
        retirementPlans: state.assetIdentification.retirementPlans,
        comments: "",
        paymentComplete: state.customerAuth ? state.customerAuth.paymentComplete : false
    };

    return translatedResponse;
};

export const convertToState = (response: ServerResponse) => {
    const welcome : Welcome = {
        email: response.husbandEmailAddress,
        error: ''
    };

    const customerAuth : Customer = {
        customerId: response.customerId,
        email: response.customer.email,
        currentStep: response.currentStep,
        lastActivity: response.customer.lastActivity,
        dateCreated: response.customer.dateCreated,
        paymentComplete: false
    }

    const grantorsPersonalInfo : GrantorsPersonalInfo = {
        husbandLegalName: response.husbandLegalName,
        husbandHomeAddress: response.husbandHomeAddress,
        husbandState: response.husbandState,
        husbandCounty: response.husbandHomeAddressCounty,
        husbandTelephoneNumber: response.husbandTelephoneNumber,
        husbandDateOfBirth: moment(response.husbandDateOfBirth, "YYYY-MM-DDThh:mm:ss").format("MM/DD/YYYY")  ? moment(response.husbandDateOfBirth, "YYYY-MM-DDThh:mm:ss").format("MM/DD/YYYY") : '',
        husbandShowHusbandLocation: !!response.husbandForeignLocation,
        husbandLocation: response.husbandForeignLocation || undefined,
        husbandDate: response.husbandForeignLocationDate || undefined,
        husbandTrust: response.husbandTrust,
        husbandWill: response.husbandWill,
        hasSpouse: !!response.wifeLegalName,
        wifeLegalName: response.wifeLegalName || undefined,
        wifeTelephoneNumber: response.wifeTelephoneNumber || undefined,
        wifeDateOfBirth: response.wifeDateOfBirth ? moment(response.wifeDateOfBirth, "YYYY-MM-DDThh:mm:ss").format("MM/DD/YYYY") : undefined,
        wifeShowWifeLocation: !!response.wifeForeignLocation,
        wifeLocation: response.wifeForeignLocation || undefined,
        wifeDate: response.wifeForeignLocationDate || undefined,
        wifeTrust: response.wifeTrust || undefined,
        wifeWill: response.wifeWill || undefined
    };

    var familyInformation : FamilyInformation = {
        sameRestrictions: response.sameRestrictions,
        children: response.children ? response.children.map((x, idx) => {
            return {
                id: x.id,
                key: idx,
                ...x,
                requiredDistributions: x.requiredDistributions != null ? 
                {
                    age: x.requiredDistributions.childRightToDistributionsAge,
                    fixedDollarAmount: x.requiredDistributions.fixedDollarAmount,
                    fixedPercentageAmount: x.requiredDistributions.fixedPercentagePercent,
                    workRequirementChecked: x.requiredDistributions.includeWorkRequirement,
                    otherDistributionDescription: x.requiredDistributions.otherDistributionRequirementDescription
                } : 
                {
                    age: 62,
                    fixedDollarAmount: 0,
                    fixedPercentageAmount: 0,
                    workRequirementChecked: false,
                    otherDistributionDescription: ''
                },
                workRequirements: x.workRequirements != null ? 
                {
                    includeWorkRequirement: x.workRequirements.includeWorkRequirement,
                    equivalentYearlyWagesChecked: x.workRequirements.equivalentToPreviousWages,
                    fixedPercentageYearlyWagesAmount: x.workRequirements.previouseWagesPercent,
                    cappedAmount: x.workRequirements.cappedDollarAmount,
                    cappedPercentage: x.workRequirements.cappedPercentAmount,
                    otherRequirementValue: x.workRequirements.otherWorkRequirementDescription
                } : 
                {
                    includeWorkRequirement: false,
                    equivalentYearlyWagesChecked: false,
                    fixedPercentageYearlyWagesAmount: 0,
                    cappedAmount: 0,
                    cappedPercentage: 0,
                    otherRequirementValue: ''
                },
                workRequirementExceptions: x.workRequirementExceptions != null ?
                {
                    disabilityIncludeDisabilityException: x.workRequirementExceptions.disabilityException,
                    disabilityAbleToEarnMoreThanFiftyPercentChecked: x.workRequirementExceptions.disabilityCanEarnException,
                    disabilityUnableToEarnMoreThanFiftyPercentChecked: x.workRequirementExceptions.disabilityCannotEarnException,
                    disabilityCausedByDrugOrAlcoholChecked: x.workRequirementExceptions.disabilityDrugsOrAlcholException,
                    disabilityOtherExceptionValue: x.workRequirementExceptions.otherDisabilityExceptionDescription,
                    disabilityOtherExceptionChecked: x.workRequirementExceptions.otherDisabilityException,
                    stayAtHomeParentIncludeStayAtHomeParentException: x.workRequirementExceptions.stayAtHomeParentException,
                    stayAtHomeParentWorkRequirementForSpouseChecked: x.workRequirementExceptions.workRequirementShifts,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: x.workRequirementExceptions.workRequirementDelayedChildAge,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: x.workRequirementExceptions.workRequirementDelayed,
                    stayAtHomeParentPercentageOfDistributionsValue: x.workRequirementExceptions.stayAtHomeDistributionPercentageAmount,
                    stayAtHomeParentPercentageOfDistributionsChecked: x.workRequirementExceptions.stayAtHomeDistributionPercentage,
                    stayAtHomeParentOtherExceptionValue: x.workRequirementExceptions.otherStayAtHomeExceptionDescription,
                    stayAtHomeParentOtherExceptionChecked: x.workRequirementExceptions.otherStayAtHomeException
                } : 
                {
                    disabilityIncludeDisabilityException: false,
                    disabilityAbleToEarnMoreThanFiftyPercentChecked: false,
                    disabilityUnableToEarnMoreThanFiftyPercentChecked: false,
                    disabilityCausedByDrugOrAlcoholChecked: false,
                    disabilityOtherExceptionValue: '',
                    disabilityOtherExceptionChecked: false,
                    stayAtHomeParentIncludeStayAtHomeParentException: false,
                    stayAtHomeParentWorkRequirementForSpouseChecked: false,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: 0,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: false,
                    stayAtHomeParentPercentageOfDistributionsValue: 0,
                    stayAtHomeParentPercentageOfDistributionsChecked: false,
                    stayAtHomeParentOtherExceptionValue: '',
                    stayAtHomeParentOtherExceptionChecked: false
                },
                distributionsUponRetirement: x.distributionsUponRetirement != null ?
                {
                    includeDistributionsUponRetirement: x.distributionsUponRetirement.makePaymentsAtSpecifiedAge,
                    age: x.distributionsUponRetirement.makePaymentsAlternativeDescription,
                    remainingBalanceChecked: x.distributionsUponRetirement.monthlyPaymentsForLife,
                    differentFinancialInstitution1: x.distributionsUponRetirement.differentInstitution1,
                    differentFinancialInstitution2: x.distributionsUponRetirement.differentInstitution2,
                    differentFinancialInstitutionsChecked: x.distributionsUponRetirement.useDifferentInstitutions,
                    alternativeLifeExpectancyMethod: x.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription,
                    alternativeLifeExpectancyChecked: x.distributionsUponRetirement.alternativeLifeExpectancyMethod,
                    distributionPercentageValue: x.distributionsUponRetirement.fixedPercentageDistributionPercent,
                    monthlyDistributionValue: x.distributionsUponRetirement.fixedDollarDistributionAmount,
                    noLifeAnnuityChecked: x.distributionsUponRetirement.fixedPercentageDistribution
                } : 
                {
                    includeDistributionsUponRetirement: false,
                    age: 62,
                    remainingBalanceChecked: false,
                    differentFinancialInstitution1: '',
                    differentFinancialInstitution2: '',
                    differentFinancialInstitutionsChecked: false,
                    alternativeLifeExpectancyMethod: '',
                    alternativeLifeExpectancyChecked: false,
                    distributionPercentageValue: 0,
                    monthlyDistributionValue: 0,
                    noLifeAnnuityChecked: false
                }
            }
        }) : [],
        grandChildren: response.grandchildren ? response.grandchildren.map((x, idx) => {
            return {
                id: x.id,
                key: idx,
                ...x,
                requiredDistributions: x.requiredDistributions != null ? 
                {
                    age: x.requiredDistributions.childRightToDistributionsAge,
                    fixedDollarAmount: x.requiredDistributions.fixedDollarAmount,
                    fixedPercentageAmount: x.requiredDistributions.fixedPercentagePercent,
                    workRequirementChecked: x.requiredDistributions.includeWorkRequirement,
                    otherDistributionDescription: x.requiredDistributions.otherDistributionRequirementDescription
                } : 
                {
                    age: 62,
                    fixedDollarAmount: 0,
                    fixedPercentageAmount: 0,
                    workRequirementChecked: false,
                    otherDistributionDescription: ''
                },
                workRequirements: x.workRequirements != null ? 
                {
                    includeWorkRequirement: x.workRequirements.includeWorkRequirement,
                    equivalentYearlyWagesChecked: x.workRequirements.equivalentToPreviousWages,
                    fixedPercentageYearlyWagesAmount: x.workRequirements.previouseWagesPercent,
                    cappedAmount: x.workRequirements.cappedDollarAmount,
                    cappedPercentage: x.workRequirements.cappedPercentAmount,
                    otherRequirementValue: x.workRequirements.otherWorkRequirementDescription
                } : 
                {
                    includeWorkRequirement: false,
                    equivalentYearlyWagesChecked: false,
                    fixedPercentageYearlyWagesAmount: 0,
                    cappedAmount: 0,
                    cappedPercentage: 0,
                    otherRequirementValue: ''
                },
                workRequirementExceptions: x.workRequirementExceptions != null ?
                {
                    disabilityIncludeDisabilityException: x.workRequirementExceptions.disabilityException,
                    disabilityAbleToEarnMoreThanFiftyPercentChecked: x.workRequirementExceptions.disabilityCanEarnException,
                    disabilityUnableToEarnMoreThanFiftyPercentChecked: x.workRequirementExceptions.disabilityCannotEarnException,
                    disabilityCausedByDrugOrAlcoholChecked: x.workRequirementExceptions.disabilityDrugsOrAlcholException,
                    disabilityOtherExceptionValue: x.workRequirementExceptions.otherDisabilityExceptionDescription,
                    disabilityOtherExceptionChecked: x.workRequirementExceptions.otherDisabilityException,
                    stayAtHomeParentIncludeStayAtHomeParentException: x.workRequirementExceptions.stayAtHomeParentException,
                    stayAtHomeParentWorkRequirementForSpouseChecked: x.workRequirementExceptions.workRequirementShifts,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: x.workRequirementExceptions.workRequirementDelayedChildAge,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: x.workRequirementExceptions.workRequirementDelayed,
                    stayAtHomeParentPercentageOfDistributionsValue: x.workRequirementExceptions.stayAtHomeDistributionPercentageAmount,
                    stayAtHomeParentPercentageOfDistributionsChecked: x.workRequirementExceptions.stayAtHomeDistributionPercentage,
                    stayAtHomeParentOtherExceptionValue: x.workRequirementExceptions.otherStayAtHomeExceptionDescription,
                    stayAtHomeParentOtherExceptionChecked: x.workRequirementExceptions.otherStayAtHomeException
                } : 
                {
                    disabilityIncludeDisabilityException: false,
                    disabilityAbleToEarnMoreThanFiftyPercentChecked: false,
                    disabilityUnableToEarnMoreThanFiftyPercentChecked: false,
                    disabilityCausedByDrugOrAlcoholChecked: false,
                    disabilityOtherExceptionValue: '',
                    disabilityOtherExceptionChecked: false,
                    stayAtHomeParentIncludeStayAtHomeParentException: false,
                    stayAtHomeParentWorkRequirementForSpouseChecked: false,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: 0,
                    stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: false,
                    stayAtHomeParentPercentageOfDistributionsValue: 0,
                    stayAtHomeParentPercentageOfDistributionsChecked: false,
                    stayAtHomeParentOtherExceptionValue: '',
                    stayAtHomeParentOtherExceptionChecked: false
                },
                distributionsUponRetirement: x.distributionsUponRetirement != null ?
                {
                    includeDistributionsUponRetirement: x.distributionsUponRetirement.makePaymentsAtSpecifiedAge,
                    age: x.distributionsUponRetirement.makePaymentsAlternativeDescription,
                    remainingBalanceChecked: x.distributionsUponRetirement.monthlyPaymentsForLife,
                    differentFinancialInstitution1: x.distributionsUponRetirement.differentInstitution1,
                    differentFinancialInstitution2: x.distributionsUponRetirement.differentInstitution2,
                    differentFinancialInstitutionsChecked: x.distributionsUponRetirement.useDifferentInstitutions,
                    alternativeLifeExpectancyMethod: x.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription,
                    alternativeLifeExpectancyChecked: x.distributionsUponRetirement.alternativeLifeExpectancyMethod,
                    distributionPercentageValue: x.distributionsUponRetirement.fixedPercentageDistributionPercent,
                    monthlyDistributionValue: x.distributionsUponRetirement.fixedDollarDistributionAmount,
                    noLifeAnnuityChecked: x.distributionsUponRetirement.fixedPercentageDistribution
                } : 
                {
                    includeDistributionsUponRetirement: false,
                    age: 62,
                    remainingBalanceChecked: false,
                    differentFinancialInstitution1: '',
                    differentFinancialInstitution2: '',
                    differentFinancialInstitutionsChecked: false,
                    alternativeLifeExpectancyMethod: '',
                    alternativeLifeExpectancyChecked: false,
                    distributionPercentageValue: 0,
                    monthlyDistributionValue: 0,
                    noLifeAnnuityChecked: false
                }
            }
        }) : []
    };

    var requiredDistributions : RequiredDistribution = {
        fixedDollarBoxChecked: false,
        fixedPercentageBoxChecked: false,
        workRequirementBoxChecked: false,
        otherDistributionBoxChecked: false
    }
    if(response.children || response.grandchildren){
        if(response.children && response.children.some(child => child.requiredDistributions) && response.grandchildren && response.grandchildren.some(grandChild => grandChild.requiredDistributions)){
            requiredDistributions = {
                fixedDollarBoxChecked: response.children.some(child => child.requiredDistributions.fixedDollarAmount > 0) || response.grandchildren.some(grandChild => grandChild.requiredDistributions.fixedDollarAmount > 0) ? true : false,
                fixedPercentageBoxChecked: response.children.some(child => child.requiredDistributions.fixedPercentagePercent > 0) || response.grandchildren.some(grandChild => grandChild.requiredDistributions.fixedPercentagePercent > 0) ? true : false,
                workRequirementBoxChecked: response.children.some(child => child.requiredDistributions.includeWorkRequirement === true) || response.grandchildren.some(grandChild => grandChild.requiredDistributions.includeWorkRequirement === true) ? true : false,
                otherDistributionBoxChecked: response.children.some(child => child.requiredDistributions.otherDistributionRequirementDescription.length > 0) || response.grandchildren.some(grandChild => grandChild.requiredDistributions.otherDistributionRequirementDescription.length > 0) ? true : false,
            }
        }
        else if(response.children && response.children.some(child => child.requiredDistributions)){
            requiredDistributions = {
                fixedDollarBoxChecked: response.children.some(child => child.requiredDistributions.fixedDollarAmount > 0) ? true : false,
                fixedPercentageBoxChecked: response.children.some(child => child.requiredDistributions.fixedPercentagePercent > 0) ? true : false,
                workRequirementBoxChecked: response.children.some(child => child.requiredDistributions.includeWorkRequirement === true) ? true : false,
                otherDistributionBoxChecked: response.children.some(child => child.requiredDistributions.otherDistributionRequirementDescription.length > 0) ? true : false,
            }
        }
        else if(response.grandchildren && response.grandchildren.some(grandChild => grandChild.requiredDistributions)){
            requiredDistributions = {
                fixedDollarBoxChecked: response.grandchildren.some(grandChild => grandChild.requiredDistributions.fixedDollarAmount > 0) ? true : false,
                fixedPercentageBoxChecked: response.grandchildren.some(grandChild => grandChild.requiredDistributions.fixedPercentagePercent > 0) ? true : false,
                workRequirementBoxChecked: response.grandchildren.some(grandChild => grandChild.requiredDistributions.includeWorkRequirement === true) ? true : false,
                otherDistributionBoxChecked: response.grandchildren.some(grandChild => grandChild.requiredDistributions.otherDistributionRequirementDescription.length > 0) ? true : false,
            }
        }
    }

    var workRequirement : WorkRequirement = {
        equivalentYearlyWagesChecked: false,
        fixedPercentageYearlyWagesChecked: false,
        cappedAmountChecked: false,
        cappedPercentageChecked: false,
        otherRequirementChecked: false
    }
    if(response.children || response.grandchildren){
        if(response.children && response.children.some(child => child.workRequirements) && response.grandchildren && response.grandchildren.some(grandChild => grandChild.workRequirements)){
            workRequirement = {
                equivalentYearlyWagesChecked: response.children.some(child => child.workRequirements.equivalentToPreviousWages) || response.grandchildren.some(grandChild => grandChild.workRequirements.equivalentToPreviousWages) ? true : false,
                fixedPercentageYearlyWagesChecked: response.children.some(child => child.workRequirements.previouseWagesPercent > 0) || response.grandchildren.some(grandChild => grandChild.workRequirements.previouseWagesPercent > 0) ? true : false,
                cappedAmountChecked: response.children.some(child => child.workRequirements.cappedDollarAmount > 0) || response.grandchildren.some(grandChild => grandChild.workRequirements.cappedDollarAmount > 0) ? true : false,
                cappedPercentageChecked: response.children.some(child => child.workRequirements.cappedPercentAmount > 0) || response.grandchildren.some(grandChild => grandChild.workRequirements.cappedPercentAmount > 0) ? true : false,
                otherRequirementChecked: response.children.some(child => child.workRequirements.otherWorkRequirementDescription.length > 0) || response.grandchildren.some(grandChild => grandChild.workRequirements.otherWorkRequirementDescription.length > 0) ? true : false
            }
        }
        else if(response.children && response.children.some(child => child.workRequirements)){
            workRequirement = {
                equivalentYearlyWagesChecked: response.children.some(child => child.workRequirements.equivalentToPreviousWages),
                fixedPercentageYearlyWagesChecked: response.children.some(child => child.workRequirements.previouseWagesPercent > 0),
                cappedAmountChecked: response.children.some(child => child.workRequirements.cappedDollarAmount > 0),
                cappedPercentageChecked: response.children.some(child => child.workRequirements.cappedPercentAmount > 0),
                otherRequirementChecked: response.children.some(child => child.workRequirements.otherWorkRequirementDescription.length > 0)
            }
        }
        else if(response.grandchildren && response.grandchildren.some(grandChild => grandChild.workRequirements)){
            workRequirement = {
                equivalentYearlyWagesChecked: response.grandchildren.some(grandChild => grandChild.workRequirements.equivalentToPreviousWages) ? true : false,
                fixedPercentageYearlyWagesChecked: response.grandchildren.some(grandChild => grandChild.workRequirements.previouseWagesPercent > 0) ? true : false,
                cappedAmountChecked: response.grandchildren.some(grandChild => grandChild.workRequirements.cappedDollarAmount > 0) ? true : false,
                cappedPercentageChecked: response.grandchildren.some(grandChild => grandChild.workRequirements.cappedPercentAmount > 0) ? true : false,
                otherRequirementChecked: response.grandchildren.some(grandChild => grandChild.workRequirements.otherWorkRequirementDescription.length > 0) ? true : false
            }
        }
    }

    var distributionsUponRetirement : DistributionsUponRetirement = {
        includeDistributionsUponRetirement: false,
        age: 62,
        remainingBalanceChecked: false,
        differentFinancialInstitutionsChecked: false,
        differentFinancialInstitution1: '',
        differentFinancialInstitution2: '',
        alternativeLifeExpectancyChecked: false,
        alternativeLifeExpectancyMethod: '',
        noLifeAnnuityChecked: false,
        monthlyDistributionValue: 0,
        distributionPercentageValue: 0
    };
    if(response.children || response.grandchildren){
        if(response.children && response.children.some(child => child.distributionsUponRetirement) && response.grandchildren && response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement)){
            distributionsUponRetirement = {
                includeDistributionsUponRetirement: response.children.some(child => child.distributionsUponRetirement.makePaymentsAtSpecifiedAge) || response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.makePaymentsAtSpecifiedAge) ? true : false,
                remainingBalanceChecked: response.children.some(child => child.distributionsUponRetirement.monthlyPaymentsForLife) || response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.monthlyPaymentsForLife) ? true : false,
                differentFinancialInstitutionsChecked: response.children.some(child => child.distributionsUponRetirement.differentInstitution1.length > 0) || response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.differentInstitution1.length > 0) ? true : false,
                alternativeLifeExpectancyChecked: response.children.some(child => child.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription.length > 0) || response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription.length > 0) ? true : false,
                noLifeAnnuityChecked: response.children.some(child => child.distributionsUponRetirement.fixedDollarDistributionAmount > 0) || response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.fixedDollarDistributionAmount > 0) ? true : false
            }
        }
        else if(response.children && response.children.some(child => child.workRequirements)){
            distributionsUponRetirement = {
                includeDistributionsUponRetirement: response.children.some(child => child.distributionsUponRetirement.makePaymentsAtSpecifiedAge) ? true : false,
                remainingBalanceChecked: response.children.some(child => child.distributionsUponRetirement.monthlyPaymentsForLife) ? true : false,
                differentFinancialInstitutionsChecked: response.children.some(child => child.distributionsUponRetirement.differentInstitution1.length > 0) ? true : false,
                alternativeLifeExpectancyChecked: response.children.some(child => child.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription.length > 0) ? true : false,
                noLifeAnnuityChecked: response.children.some(child => child.distributionsUponRetirement.fixedDollarDistributionAmount > 0) ? true : false
            }
        }
        else if(response.grandchildren && response.grandchildren.some(grandChild => grandChild.workRequirements)){
            distributionsUponRetirement = {
                includeDistributionsUponRetirement: response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.makePaymentsAtSpecifiedAge) ? true : false,
                remainingBalanceChecked: response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.monthlyPaymentsForLife) ? true : false,
                differentFinancialInstitutionsChecked: response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.differentInstitution1.length > 0) ? true : false,
                alternativeLifeExpectancyChecked: response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.alternativeLifeExpectancyMethodDescription.length > 0) ? true : false,
                noLifeAnnuityChecked: response.grandchildren.some(grandChild => grandChild.distributionsUponRetirement.fixedDollarDistributionAmount > 0) ? true : false
            }
        }
    }

    const successorTrustees : SuccessorTrustees = {
        trustee1: response.trustees && response.trustees.length > 0 ? response.trustees[0].legalName : '',
        trustee2: response.trustees && response.trustees.length > 1 ? response.trustees[1].legalName : '',
        trustee3: response.trustees && response.trustees.length > 2 ? response.trustees[2].legalName : '',
        trustee4: response.trustees && response.trustees.length > 3 ? response.trustees[3].legalName : '',
        trustee5: response.trustees && response.trustees.length > 4 ? response.trustees[4].legalName : ''
    };

    const deathOfBeneficiary : DeathOfBeneficiary = {
        selectedBeneficiary: response.remainingAssetsType,
        otherValue: response.remainingAssetsOtherDescription
    };

    const additionalQuestions : AdditionalQuestions = {
        primaryExecutor: response.primaryExecutor,
        alternateExecutor1: response.alternateExecutor1,
        alternateExecutor2: response.alternateExecutor2,
        primaryAttorneyInFact: response.primaryAttorneyInFact,
        alternateAttorneyInFact1: response.alternateAttorneyInFact1,
        alternateAttorneyInFact2: response.alternateAttorneyInFact2,
        attorneyInFactOperation: response.attorneyInFactOperation,
        primaryGuardian: response.primaryGuardian,
        alternateGuardian1: response.alternateGuardian1,
        alternateGuardian2: response.alternateGuardian2,
        monthlyPaymentForGuardian: response.monthlyPaymentForGuardian,
        oneTimePaymentForGuardianHome: response.oneTimePaymentForGuardianHome
    };

    const healthCareDocuments : HealthCareDocuments = {
        primaryPowerOfAttorneyLegalName: response.primaryPowerOfAttorneyLegalName,
        primaryPowerOfAttorneyHomeAddress: response.primaryPowerOfAttorneyHomeAddress,
        primaryPowerOfAttorneyPhoneNumber: response.primaryPowerOfAttorneyPhoneNumber,
        alternatePowerOfAttorney1LegalName: response.alternatePowerOfAttorney1LegalName,
        alternatePowerOfAttorney1HomeAddress: response.alternatePowerOfAttorney1HomeAddress,
        alternatePowerOfAttorney1PhoneNumber: response.alternatePowerOfAttorney1PhoneNumber,
        alternatePowerOfAttorney2LegalName: response.alternatePowerOfAttorney2LegalName,
        alternatePowerOfAttorney2HomeAddress: response.alternatePowerOfAttorney2HomeAddress,
        alternatePowerOfAttorney2PhoneNumber: response.alternatePowerOfAttorney2PhoneNumber,
        executeLivingWill: response.executeLivingWill,
        usePowerOfAttorneyContacts: response.usePowerOfAttorneyContacts,
        livingWillContact1LegalName: response.livingWillContact1LegalName,
        livingWillContact1HomeAddress: response.livingWillContact1HomeAddress,
        livingWillContact1PhoneNumber: response.livingWillContact1PhoneNumber,
        livingWillContact2LegalName: response.livingWillContact2LegalName,
        livingWillContact2HomeAddress: response.livingWillContact2HomeAddress,
        livingWillContact2PhoneNumber: response.livingWillContact2PhoneNumber,
        primarySurrogateLegalName: response.primarySurrogateLegalName,
        primarySurrogateHomeAddress: response.primarySurrogateHomeAddress,
        primarySurrogatePhoneNumber: response.primarySurrogatePhoneNumber,
        alternateSurrogateLegalName: response.alternateSurrogateLegalName,
        alternateSurrogateHomeAddress: response.alternateSurrogateHomeAddress,
        alternateSurrogatePhoneNumber: response.alternateSurrogatePhoneNumber,
        userState: ''
    };

    const assetIdentification : AssetIdentification = {
        hasProperty: response.hasProperty,
        propertyCity: response.propertyCity,
        propertyState: response.propertyState,
        survivorshipWithSpouse: response.survivorshipWithSpouse,
        otherHoldsRights: response.otherHoldsRights,
        currentMarketValue: response.currentMarketValue,
        investmentsOrVacationProperties: response.investmentsOrVacationProperties,
        investmentOrVacationLocations: response.investmentOrVacationLocations,
        investmentOrVacationOwners: response.investmentOrVacationOwners,
        lifeInsuranceChecked: response.lifeInsuranceChecked,
        lifeInsuranceCompany: response.lifeInsuranceCompany,
        lifeInsurancePolicyNumber: response.lifeInsurancePolicyNumber,
        lifeInsuranceOwner: response.lifeInsuranceOwner,
        lifeInsuranceBeneficiary: response.lifeInsuranceBeneficiary,
        lifeInsuranceAmount: response.lifeInsuranceAmount,
        investments: response.investments,
        retirementPlans: response.retirementPlans
    };

    return {
        customerAuth,
        welcome,
        grantorsPersonalInfo,
        familyInformation,
        requiredDistributions,
        workRequirement,
        distributionsUponRetirement,
        successorTrustees,
        deathOfBeneficiary,
        additionalQuestions,
        healthCareDocuments,
        assetIdentification
    };
};