import {Dispatch} from "redux";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { State } from "../store/state";
import {assetIdentificationActionTypes} from "../actionTypes/assetIdentificationActionTypes";
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateAssets = (assets: any) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: assetIdentificationActionTypes.UPDATE_ASSETS,
        payload: assets
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 11
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('payment');
    } catch (e) {
        console.log(e);
        throw e;
    }
};