import {Dispatch} from "redux";
import { convertToState } from "../translator";
import { logout as apiLogout, getResponse } from "../api";
import { history } from '../store/history';
import { State } from "../store/state";
import {welcomeActionTypes} from '../actionTypes/welcomeActionTypes'; 
import { grantorsPersonalInformationActionTypes } from "../actionTypes/grantorsPersonalInformationActionTypes";
import { familyInformationActionTypes } from "../actionTypes/familyInformationActionTypes";
import { requiredDistributionsActionTypes } from "../actionTypes/requiredDistributionsActionTypes";
import { workRequirementActionTypes } from "../actionTypes/workRequirementActionTypes";
import { distributionsUponRetirementActionTypes } from "../actionTypes/distributionsUponRetirementActionTypes";
import { deathOfBeneficiaryActionTypes } from "../actionTypes/deathOfBeneficiaryActionTypes";
import { successorTrusteesActionTypes } from "../actionTypes/successorTrusteesActionTypes";
import {additionalQuestionsActionTypes} from "../actionTypes/additionalQuestionsActionTypes";
import {healthCareDocumentsActionTypes} from "../actionTypes/healthCareDocumentsActionTypes";
import {assetIdentificationActionTypes} from "../actionTypes/assetIdentificationActionTypes";
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const fetchResponse = () => async (dispatch: Dispatch<any>, getState: () => State) => {
    try{
        const currentState = getState();
        if (currentState && currentState.customerAuth) {
            const {data, status} = await getResponse(currentState.customerAuth.customerId);

            if (status === 204) {
                dispatch({
                    type: customerAuthActionTypes.PAYMENT_COMPLETE
                });
                return;
            }

            const state = convertToState(data);
            dispatch({
                type: customerAuthActionTypes.UPDATE_CUSTOMER_INFORMATION,
                payload: state.customerAuth
            });
            dispatch({
                type: welcomeActionTypes.WELCOME_UPDATE_EMAIL,
                payload: state.welcome
            });
            dispatch({
                type: grantorsPersonalInformationActionTypes.UPDATE_GRANTOR_INFORMATION,
                payload: state.grantorsPersonalInfo
            });
            dispatch({
                type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
                payload: {
                    children: state.familyInformation.children,
                    grandChildren: state.familyInformation.grandChildren,
                    sameRestrictions: state.familyInformation.sameRestrictions                    
                }
            });
            dispatch({
                type: requiredDistributionsActionTypes.UPDATE_REQUIRED_DISTRIBUTIONS,
                payload: state.requiredDistributions
            });
            dispatch({
                type: workRequirementActionTypes.UPDATE_WORK_REQUIREMENT,
                payload: state.workRequirement
            });
            dispatch({
                type: distributionsUponRetirementActionTypes.UPDATE_DISTRIBUTIONS_UPON_RETIREMENT,
                payload: state.distributionsUponRetirement
            });
            dispatch({
                type: successorTrusteesActionTypes.UPDATE_SUCCESSOR_TRUSTEES,
                payload: state.successorTrustees
            });
            dispatch({
                type: deathOfBeneficiaryActionTypes.UPDATE_DISTRIBUTION_AFTER_DEATH_OF_BENEFICIARY,
                payload: state.deathOfBeneficiary
            });
            dispatch({
                type: additionalQuestionsActionTypes.UPDATE_ADDITIONAL_QUESTIONS,
                payload: state.additionalQuestions
            });
            dispatch({
                type: healthCareDocumentsActionTypes.UPDATE_HEALTH_CARE_DOCUMENTS,
                payload: state.healthCareDocuments
            });
            dispatch({
                type: assetIdentificationActionTypes.UPDATE_ASSETS,
                payload: state.assetIdentification
            });
        }
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const logout = () => async (dispatch: Dispatch<any>) => {
    await apiLogout();
    dispatch({
        type: "RESET"
    });
    history.replace('/');
}