import {HealthCareDocuments} from "../store/state";

export default function validate(healthCareDocuments : HealthCareDocuments) {
    let errors : any = {};

    if(healthCareDocuments.userState === "OH"){
        if (!healthCareDocuments.primaryPowerOfAttorneyLegalName) {
            errors.primaryPowerOfAttorneyLegalName = 'You must select a legal name for the primary power of attorney.';
        }
        if (!healthCareDocuments.primaryPowerOfAttorneyHomeAddress) {
            errors.primaryPowerOfAttorneyHomeAddress = 'You must select a home address for the primary power of attorney.';
        }
        if (!healthCareDocuments.primaryPowerOfAttorneyPhoneNumber) {
            errors.primaryPowerOfAttorneyPhoneNumber = 'You must select a phone number for the primary power of attorney.';
        }

        if(healthCareDocuments.executeLivingWill && !healthCareDocuments.usePowerOfAttorneyContacts){
            if (!healthCareDocuments.livingWillContact1LegalName) {
                errors.livingWillContact1LegalName = 'You must select a legal name for contact 1 for the will.';
            }
            if (!healthCareDocuments.livingWillContact1HomeAddress) {
                errors.livingWillContact1HomeAddress = 'You must select a home address for contact 1 for the will.';
            }
            if (!healthCareDocuments.livingWillContact1PhoneNumber) {
                errors.livingWillContact1PhoneNumber = 'You must select a phone number for contact 1 for the will.';
            }

            if (!healthCareDocuments.livingWillContact2LegalName) {
                errors.livingWillContact2LegalName = 'You must select a legal name for contact 2 for the will.';
            }
            if (!healthCareDocuments.livingWillContact2HomeAddress) {
                errors.livingWillContact2HomeAddress = 'You must select a home address for contact 2 for the will.';
            }
            if (!healthCareDocuments.livingWillContact2PhoneNumber) {
                errors.livingWillContact2PhoneNumber = 'You must select a phone number for contact 2 for the will.';
            }
        }
    }

    if(healthCareDocuments.userState === "KY") {
        if (!healthCareDocuments.primarySurrogateLegalName) {
            errors.primarySurrogateLegalName = 'You must select a legal name for the primary surrogate.';
        }
        if (!healthCareDocuments.primarySurrogateHomeAddress) {
            errors.primarySurrogateHomeAddress = 'You must select a home address for the primary surrogate.';
        }
        if (!healthCareDocuments.primarySurrogatePhoneNumber) {
            errors.primarySurrogatePhoneNumber = 'You must select a phone number for the primary surrogate.';
        }

        if (!healthCareDocuments.alternateSurrogateLegalName) {
            errors.alternateSurrogateLegalName = 'You must select a legal name for the alternate surrogate.';
        }
        if (!healthCareDocuments.alternateSurrogateHomeAddress) {
            errors.alternateSurrogateHomeAddress = 'You must select a home address for the alternate surrogate.';
        }
        if (!healthCareDocuments.alternateSurrogatePhoneNumber) {
            errors.alternateSurrogatePhoneNumber = 'You must select a phone number for the alternate surrogate.';
        }
    }

    return errors;
};