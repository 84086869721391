import React, { useState, CSSProperties, useReducer, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Header, Form, Checkbox, FormInputProps, FormProps} from 'semantic-ui-react';
import {HealthCareDocuments, State} from '../store/state';
import {updateHealthCareDocuments} from "../actions/healthCareDocumentsActions";
import {Link, RouteComponentProps} from "react-router-dom";
import InputMask from 'react-input-mask';
import {
    containerStyle, titleStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, headerStyle,
    pStyle, checkboxStyle
} from '../styles';
import { StickyContainer, Sticky } from 'react-sticky';
import useForm from "../useForm";
import validate from "../validationRules/healthCareDocumentsRules";
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const grantorInfoSelector = (state: State) => state.grantorsPersonalInfo;
const healthCareDocumentSelector = (state: State) => state.healthCareDocuments;

export const HealthCareDocumentsComponent = (props: RouteComponentProps) => {
    const reduxGrantorInfo = useSelector(grantorInfoSelector);
    const userState = reduxGrantorInfo.husbandState;

    const reduxHealthCareDocuments = useSelector(healthCareDocumentSelector);

    const nextPage  = () => {
        try {
            dispatch(updateHealthCareDocuments(healthCareDocuments));
        } catch(e) {
            alert('An error occured. Please contact Seven Hills Technology.');
        }
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: healthCareDocuments,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: HealthCareDocuments,
        setValues: Function,
        errors: any,
        handleChange: any,
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(reduxHealthCareDocuments, nextPage, validate);

    useEffect(() => {
        setValues({
            ...reduxHealthCareDocuments
        });
    }, [reduxHealthCareDocuments]);

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const dispatch = useDispatch();
    healthCareDocuments.userState = userState;
    
    dispatch(redirectToAppropriateStep(10));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Health Care Documents</h1>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'}>
                    {
                        userState === "Ohio" &&
                        <div className={'informationContainer'}>
                            <Header style={headerStyle}><b>Health Care Power of Attorney</b></Header>
                            <p style={pStyle}>Please identify your healthcare power of attorney:</p>
                            <Form.Input name={'primaryPowerOfAttorneyLegalName'}
                                        value={healthCareDocuments.primaryPowerOfAttorneyLegalName}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Legal Name'}
                                        placeholder={'Full legal name (including initials)'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')}
                                        autoFocus
                                        required
                                        error={errors.primaryPowerOfAttorneyLegalName} />
                            <InputMask mask="(999) 999-9999"
                                       value={healthCareDocuments.primaryPowerOfAttorneyPhoneNumber}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Health Care Power of Attorney')}
                                       onBlur={() => updateFocus('')}>
                                {(inputProps: FormInputProps) => <Form.Input name={'primaryPowerOfAttorneyPhoneNumber'}
                                                                             value={healthCareDocuments.primaryPowerOfAttorneyPhoneNumber}
                                                                             className={'field'}
                                                                             label={'Phone Number'}
                                                                             placeholder={'(513) 555-1234'}
                                                                             width={16}
                                                                             required
                                                                             {...inputProps}
                                                                             error={errors.primaryPowerOfAttorneyPhoneNumber} />}
                            </InputMask>
                            <Form.Input name={'primaryPowerOfAttorneyHomeAddress'}
                                        value={healthCareDocuments.primaryPowerOfAttorneyHomeAddress}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Address'}
                                        placeholder={'Address'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')}
                                        required
                                        error={errors.primaryPowerOfAttorneyHomeAddress} />
                            <br />
                            <p style={pStyle}>Alternate 1:</p>
                            <Form.Input name={'alternatePowerOfAttorney1LegalName'}
                                        value={healthCareDocuments.alternatePowerOfAttorney1LegalName}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Legal Name'}
                                        placeholder={'Full legal name (including initials)'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')} />
                            <InputMask mask="(999) 999-9999"
                                       value={healthCareDocuments.alternatePowerOfAttorney1PhoneNumber}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Health Care Power of Attorney')}
                                       onBlur={() => updateFocus('')}>
                                {(inputProps: FormInputProps) => <Form.Input name={'alternatePowerOfAttorney1PhoneNumber'}
                                                                             value={healthCareDocuments.alternatePowerOfAttorney1PhoneNumber}
                                                                             className={'field'}
                                                                             label={'Phone Number'}
                                                                             placeholder={'(513) 555-1234'}
                                                                             width={16}
                                                                             {...inputProps} />}
                            </InputMask>
                            <Form.Input name={'alternatePowerOfAttorney1HomeAddress'}
                                        value={healthCareDocuments.alternatePowerOfAttorney1HomeAddress}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Address'}
                                        placeholder={'Address'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')} />
                            <br />
                            <p style={pStyle}>Alternate 2:</p>
                            <Form.Input name={'alternatePowerOfAttorney2LegalName'}
                                        value={healthCareDocuments.alternatePowerOfAttorney2LegalName}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Legal Name'}
                                        placeholder={'Full legal name (including initials)'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')} />
                            <InputMask mask="(999) 999-9999"
                                       value={healthCareDocuments.alternatePowerOfAttorney2PhoneNumber}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Health Care Power of Attorney')}
                                       onBlur={() => updateFocus('')}>
                                {(inputProps: FormInputProps) => <Form.Input name={'alternatePowerOfAttorney2PhoneNumber'}
                                                                             value={healthCareDocuments.alternatePowerOfAttorney2PhoneNumber}
                                                                             className={'field'}
                                                                             label={'Phone Number'}
                                                                             placeholder={'(513) 555-1234'}
                                                                             width={16}
                                                                             {...inputProps} />}
                            </InputMask>
                            <Form.Input name={'alternatePowerOfAttorney2HomeAddress'}
                                        value={healthCareDocuments.alternatePowerOfAttorney2HomeAddress}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Address'}
                                        placeholder={'Address'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Power of Attorney')}
                                        onBlur={() => updateFocus('')} />
                            <br />
                            <Header style={headerStyle}><b>Living Will Declaration</b></Header>
                            <Checkbox
                                label='I want to execute a Living Will'
                                name='executeLivingWill'
                                value='yes'
                                style={checkboxStyle}
                                checked={healthCareDocuments.executeLivingWill === true}
                                onFocus={() => updateFocus('Living Will Declaration')}
                                onBlur={() => updateFocus('')}
                                onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'executeLivingWill', value: !healthCareDocuments.executeLivingWill } })}
                            />
                            <br />
                            <Checkbox
                                label='Use same people as my Health Care Power of Attorney'
                                name='usePowerOfAttorneyContacts'
                                value='yes'
                                style={checkboxStyle}
                                checked={healthCareDocuments.usePowerOfAttorneyContacts === true}
                                onFocus={() => updateFocus('Living Will Declaration')}
                                onBlur={() => updateFocus('')}
                                onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'usePowerOfAttorneyContacts', value: !healthCareDocuments.usePowerOfAttorneyContacts } })}
                            />
                            {
                                (healthCareDocuments.executeLivingWill && !healthCareDocuments.usePowerOfAttorneyContacts) &&
                                <div>
                                    <p style={pStyle}>Contact 1</p>
                                    <Form.Input name={'livingWillContact1LegalName'}
                                                value={healthCareDocuments.livingWillContact1LegalName}
                                                onChange={handleChange}
                                                className={'field'}
                                                label={'Legal Name'}
                                                placeholder={'Full legal name (including initials)'}
                                                width={16}
                                                onFocus={() => updateFocus('Living Will Declaration')}
                                                onBlur={() => updateFocus('')}
                                                required
                                                error={errors.livingWillContact1LegalName} />
                                    <InputMask mask="(999) 999-9999"
                                               value={healthCareDocuments.livingWillContact1PhoneNumber}
                                               onChange={handleChange}
                                               maskChar={null}
                                               onFocus={() => updateFocus('Living Will Declaration')}
                                               onBlur={() => updateFocus('')}>
                                        {(inputProps: FormInputProps) => <Form.Input name={'livingWillContact1PhoneNumber'}
                                                                                     value={healthCareDocuments.livingWillContact1PhoneNumber}
                                                                                     className={'field'}
                                                                                     label={'Phone Number'}
                                                                                     placeholder={'(513) 555-1234'}
                                                                                     width={16}
                                                                                     required
                                                                                     {...inputProps}
                                                                                     error={errors.livingWillContact1PhoneNumber} />}
                                    </InputMask>
                                    <Form.Input name={'livingWillContact1HomeAddress'}
                                                value={healthCareDocuments.livingWillContact1HomeAddress}
                                                onChange={handleChange}
                                                className={'field'}
                                                label={'Address'}
                                                placeholder={'Address'}
                                                width={16}
                                                onFocus={() => updateFocus('Living Will Declaration')}
                                                onBlur={() => updateFocus('')}
                                                required
                                                error={errors.livingWillContact1HomeAddress} />
                                    <br />
                                    <p style={pStyle}>Contact 2</p>
                                    <Form.Input name={'livingWillContact2LegalName'}
                                                value={healthCareDocuments.livingWillContact2LegalName}
                                                onChange={handleChange}
                                                className={'field'}
                                                label={'Legal Name'}
                                                placeholder={'Full legal name (including initials)'}
                                                width={16}
                                                onFocus={() => updateFocus('Living Will Declaration')}
                                                onBlur={() => updateFocus('')}
                                                required
                                                error={errors.livingWillContact2LegalName} />
                                    <InputMask mask="(999) 999-9999"
                                               value={healthCareDocuments.livingWillContact2PhoneNumber}
                                               onChange={handleChange}
                                               maskChar={null}
                                               onFocus={() => updateFocus('Living Will Declaration')}
                                               onBlur={() => updateFocus('')}>
                                        {(inputProps: FormInputProps) => <Form.Input name={'livingWillContact2PhoneNumber'}
                                                                                     value={healthCareDocuments.livingWillContact2PhoneNumber}
                                                                                     className={'field'}
                                                                                     label={'Phone Number'}
                                                                                     placeholder={'(513) 555-1234'}
                                                                                     width={16}
                                                                                     required
                                                                                     {...inputProps}
                                                                                     error={errors.livingWillContact2PhoneNumber} />}
                                    </InputMask>
                                    <Form.Input name={'livingWillContact2HomeAddress'}
                                                value={healthCareDocuments.livingWillContact2HomeAddress}
                                                onChange={handleChange}
                                                className={'field'}
                                                label={'Address'}
                                                placeholder={'Address'}
                                                width={16}
                                                onFocus={() => updateFocus('Living Will Declaration')}
                                                onBlur={() => updateFocus('')}
                                                required
                                                error={errors.livingWillContact2HomeAddress} />
                                    <br />
                                </div>
                            }

                        </div>
                    }
                    {
                        userState === "Kentucky" &&
                        <div className={'informationContainer'}>
                            <Header style={headerStyle}><b>Health Care Surrogate</b></Header>
                            <p style={pStyle}>Please identify your healthcare power of attorney:</p>
                            <Form.Input name={'primarySurrogateLegalName'}
                                        value={healthCareDocuments.primarySurrogateLegalName}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Legal Name'}
                                        placeholder={'Full legal name (including initials)'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Surrogate')}
                                        onBlur={() => updateFocus('')}
                                        autoFocus
                                        required
                                        error={errors.primarySurrogateLegalName} />
                            <InputMask mask="(999) 999-9999"
                                       value={healthCareDocuments.primarySurrogatePhoneNumber}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Health Care Surrogate')}
                                       onBlur={() => updateFocus('')}>
                                {(inputProps: FormInputProps) => <Form.Input name={'primarySurrogatePhoneNumber'}
                                                                             value={healthCareDocuments.primarySurrogatePhoneNumber}
                                                                             className={'field'}
                                                                             label={'Phone Number'}
                                                                             placeholder={'(513) 555-1234'}
                                                                             width={16}
                                                                             required
                                                                             {...inputProps}
                                                                             error={errors.primarySurrogatePhoneNumber} />}
                            </InputMask>
                            <Form.Input name={'primarySurrogateHomeAddress'}
                                        value={healthCareDocuments.primarySurrogateHomeAddress}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Address'}
                                        placeholder={'Address'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Surrogate')}
                                        onBlur={() => updateFocus('')}
                                        required
                                        error={errors.primarySurrogateHomeAddress} />
                            <br />
                            <p style={pStyle}>Alternate:</p>
                            <Form.Input name={'alternateSurrogateLegalName'}
                                        value={healthCareDocuments.alternateSurrogateLegalName}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Legal Name'}
                                        placeholder={'Full legal name (including initials)'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Surrogate')}
                                        onBlur={() => updateFocus('')}
                                        required
                                        error={errors.alternateSurrogateLegalName} />
                            <InputMask mask="(999) 999-9999"
                                       value={healthCareDocuments.alternateSurrogatePhoneNumber}
                                       onChange={handleChange}
                                       maskChar={null}
                                       onFocus={() => updateFocus('Health Care Surrogate')}
                                       onBlur={() => updateFocus('')}>
                                {(inputProps: FormInputProps) => <Form.Input name={'alternateSurrogatePhoneNumber'}
                                                                             value={healthCareDocuments.alternateSurrogatePhoneNumber}
                                                                             className={'field'}
                                                                             label={'Phone Number'}
                                                                             placeholder={'(513) 555-1234'}
                                                                             width={16}
                                                                             required
                                                                             {...inputProps}
                                                                             error={errors.alternateSurrogatePhoneNumber} />}
                            </InputMask>
                            <Form.Input name={'alternateSurrogateHomeAddress'}
                                        value={healthCareDocuments.alternateSurrogateHomeAddress}
                                        onChange={handleChange}
                                        className={'field'}
                                        label={'Address'}
                                        placeholder={'Address'}
                                        width={16}
                                        onFocus={() => updateFocus('Health Care Surrogate')}
                                        onBlur={() => updateFocus('')}
                                        required
                                        error={errors.alternateSurrogateHomeAddress} />
                            <br />
                        </div>
                    }
                    <StickyContainer className={'helpContainer'}>
                        <Sticky>
                            {({style: stickyStyle}) => 
                                <div style={stickyStyle}>
                                    {
                                        focus.field &&
                                        <h3>{focus.field}</h3>
                                    }
                                    {
                                        !focus.field &&
                                        <><h3>Help</h3>
                                        <p>Help text will show up here are you navigate through the form</p></>
                                    }
                                    {
                                        focus.field === 'Health Care Power of Attorney' &&
                                        <p>As you might infer, a Health Care Power of Attorney is a document wherein you appoint a fiduciary to make health care decisions on your behalf in the event that you are not able to do so yourself. Most clients appoint their spouses to serve. The State of Ohio recommends that you select two alternates just in case your first appointee is not available.</p>
                                    }
                                    {
                                        focus.field === 'Living Will Declaration' &&
                                        <p>In Ohio, a Living Will is a directive to your physicians in the event you are terminally ill or in a persistent vegetative state that you do not want to be kept alive on life support. You may also elect to allow the withholding of artificially suppled nutrition and hydration. If you execute a Living Will, your appointed Health Care Power of Attorney cannot act in a way that contravenes the wishes you express in this document. If you want your spouse or family to make all health care decisions, then you do not want to execute a living will. The State of Ohio requests that you list two persons to be notified if your Living Will Directive becomes operative.</p>
                                    }
                                    {
                                        focus.field === 'Health Care Surrogate' &&
                                        <p>The Commonwealth of Kentucky has a form called the “Living Will Directive and Health Care Surrogate Designation.” Kentucky’s living will allows you to make certain elections about medical treatment and the provision of artificial nutrition and hydration in the event you are terminally ill or in a persistent vegetative state. A Health Care Surrogate makes health care decisions on your behalf in the event that you are not able to do so yourself. Most clients appoint their spouses to serve as Health Care Surrogates. We require that you designate two.</p>
                                    }
                                </div>
                            }
                        </Sticky>
                    </StickyContainer>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} type="submit"
                            className={'ui right labeled icon button'}>
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}