import {GrantorsPersonalInfo} from '../store/state';

export default function validate(grantorsInfo: GrantorsPersonalInfo) {
    let errors : any = {};

    if (!grantorsInfo.husbandLegalName && !grantorsInfo.wifeLegalName) {
        errors.husbandLegalName = 'At least a husband or wife legal name is required.';
    }

    return errors;
}