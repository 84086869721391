import {HealthCareDocuments} from "../store/state";
import {initialState} from "../store/initialState";
import {healthCareDocumentsActionTypes} from "../actionTypes/healthCareDocumentsActionTypes";

export function healthCareDocumentsReducer(state = initialState.healthCareDocuments, action: {type: string, payload: any}): HealthCareDocuments {
    switch (action.type) {
        case healthCareDocumentsActionTypes.UPDATE_HEALTH_CARE_DOCUMENTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}