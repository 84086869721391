import React, {useState, CSSProperties, FormEvent, useEffect, useReducer,} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Checkbox, FormProps } from 'semantic-ui-react';
import { State, WorkRequirement, Beneficiary } from '../store/state';
import {updateWorkRequirements} from "../actions/workRequirementActions";
import {Link, RouteComponentProps} from "react-router-dom";
import { containerStyle, titleStyle, pStyle, checkedInputStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, distributionStyle, checkboxStyle, radioStyle } from '../styles';
import useForm from '../useForm';
import validate from '../validationRules/workRequirementRules';
import { history } from '../store/history';
import { redirectToAppropriateStep, redirectToStep } from '../actions/routerActions';

const workRequirementSelector = (state: State) => state.workRequirement;
const familyInformationSelector = (state: State) => state.familyInformation;
const childrenSelector = (state: State) => state.familyInformation.children;
const grandChildrenSelector = (state: State) => state.familyInformation.grandChildren;

export const WorkRequirementComponent = (props: RouteComponentProps) => {
    document.title = "Work Requirements";
    const reduxFamilyInfo = useSelector(familyInformationSelector);
    const reduxWorkRequirements = useSelector(workRequirementSelector);

    const reduxChildren = useSelector(childrenSelector);
    const reduxGrandChildren = useSelector(grandChildrenSelector);

    const [children, setChildWorkRequirementValues] = useReducer((children, { target, type, key }) => {
        switch (type) {
            case "update":
                var child = children.find((_: any, index: number) => index === key);
                child.workRequirements[target.name] = target.value;
                return [...children];
            case "updateAll":
                children.forEach((child: any)=>{child.workRequirements[target.name] = target.value;});
                return [...children];
            case "set":
                reduxChildren.forEach((child: any)=>{ 
                    if(child.requiredDistributions.workRequirementChecked)
                    {
                        child.workRequirements.includeWorkRequirement = true;
                    }
                });
                return [...reduxChildren];
            default:
                return reduxChildren;
        }
    }, reduxChildren);

    useEffect(() => {
        setChildWorkRequirementValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateWorkRequirement = (e: any, index: number ) => {
        setChildWorkRequirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const [grandChildren, setGrandChildWorkRequirementValues] = useReducer((grandChildren, { target, type, key }) => {
        switch (type) {
            case "update":
                var grandChild = grandChildren.find((_: any, index: number) => index === key);
                grandChild.workRequirements[target.name] = target.value;
                return [...grandChildren];
            case "updateAll":
                grandChildren.forEach((child: any)=>{child.workRequirements[target.name] = target.value;});
                return [...grandChildren];
            case "set":
                reduxGrandChildren.forEach((grandChild: any)=>{ 
                    if(grandChild.requiredDistributions.workRequirementChecked)
                    {
                        grandChild.workRequirements.includeWorkRequirement = true;
                    }
                });
                return [...reduxGrandChildren];
            default:
                return reduxGrandChildren;
        }
    }, reduxGrandChildren);

    useEffect(() => {
        setGrandChildWorkRequirementValues({type: "set", list: reduxGrandChildren});
    }, [reduxGrandChildren]);

    const updateGrandChildrenWorkRequirement = (e: any, index: number ) => {
        setGrandChildWorkRequirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "update", key: index });
    };

    const updateAllWorkRequirements = (e: any) => {
        setChildWorkRequirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
        setGrandChildWorkRequirementValues({ target: {name: e.target.name, value: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value}, type: "updateAll" });
    };

    const [focus, setFocus] = useState({
        field: ''
    });

    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const [useChildren, setUseChildren] = useState(children.length > 0);

    const nextPage  = () => {
        dispatch(updateWorkRequirements({children: children, grandChildren: grandChildren, sameRestrictions: reduxFamilyInfo.sameRestrictions}, workRequirement));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: workRequirement,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: WorkRequirement,
        setValues: Function,
        errors: any,
        handleChange: any,
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(reduxWorkRequirements, nextPage, validate)

    useEffect(() => {
        setValues({...reduxWorkRequirements});
    }, [reduxWorkRequirements]);

    const dispatch = useDispatch();
    dispatch(redirectToAppropriateStep(4));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Work Requirements</h1>
            <Form onSubmit={nextPage}>
                <div className={'contentStyle'}>
                    {
                        <div className={'informationContainer'}>
                            {
                                (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                <Checkbox
                                    name='includeWorkRequirement'
                                    label='I would like to include a work requirement clause that says, if a beneficiary does not work then they will not receive any distributions until they start working again.'
                                    value='yes'
                                    style={checkboxStyle}
                                    onChange={(e) => updateAllWorkRequirements({target: { name: 'includeWorkRequirement', value: useChildren ? !children[0].workRequirements.includeWorkRequirement : !grandChildren[0].workRequirements.includeWorkRequirement }})}
                                    checked={useChildren ? children[0].workRequirements.includeWorkRequirement === true : grandChildren[0].workRequirements.includeWorkRequirement === true}
                                />
                            }
                            {
                                !reduxFamilyInfo.sameRestrictions &&
                                <> 
                                    <p style={pStyle}>I would like to include a work requirement clause that says, if a beneficiary does not work then they will not receive any distributions until they start working again.</p>
                                    {
                                        children.map((child: Beneficiary, key: number) => (
                                            <>
                                                <Checkbox
                                                    label={child.legalName}
                                                    name='includeWorkRequirement'
                                                    value='yes'
                                                    style={checkboxStyle}
                                                    onChange={(e) => updateWorkRequirement({target: { name: 'includeWorkRequirement', value: !child.workRequirements.includeWorkRequirement }}, key)}
                                                    checked={child.workRequirements.includeWorkRequirement === true}
                                                />
                                                <br></br>
                                            </>
                                        ))
                                    }
                                
                                    {
                                        grandChildren.map((grandChild: Beneficiary, key: number) => (
                                            <>
                                                <Checkbox
                                                    label={grandChild.legalName}
                                                    name='includeWorkRequirement'
                                                    value='yes'
                                                    style={checkboxStyle}
                                                    onChange={(e) => updateGrandChildrenWorkRequirement({target: { name: 'includeWorkRequirement', value: !grandChild.workRequirements.includeWorkRequirement }}, key)}
                                                    checked={grandChild.workRequirements.includeWorkRequirement === true}
                                                />
                                                <br></br>
                                            </>
                                        ))
                                    }
                                </>
                            } 
                            <br></br>                       
                            <p style={pStyle}>Check applicable:</p>
                            <div style={distributionStyle}>
                                {
                                    reduxFamilyInfo.sameRestrictions &&
                                    <Checkbox
                                        style={checkboxStyle}
                                        name='equivalentYearlyWagesChecked'
                                        value={'true'}
                                        onFocus={() => updateFocus('Entitled Previous Calendar Year Annual Wages Percentage')}
                                        onBlur={() => updateFocus('')}
                                        checked={workRequirement.equivalentYearlyWagesChecked === true}
                                        onChange={(e) => 
                                            {
                                                updateAllWorkRequirements({ target: { name: 'equivalentYearlyWagesChecked', value: !workRequirement.equivalentYearlyWagesChecked } });
                                                handleChange({ persist: e.persist, target: { name: 'equivalentYearlyWagesChecked', value: !workRequirement.equivalentYearlyWagesChecked } });
                                            }
                                        }
                                    />
                                }
                                {
                                    !reduxFamilyInfo.sameRestrictions &&
                                    <Checkbox
                                        style={checkboxStyle}
                                        name='equivalentYearlyWagesChecked'
                                        value={'true'}
                                        onFocus={() => updateFocus('Entitled Previous Calendar Year Annual Wages Percentage')}
                                        onBlur={() => updateFocus('')}
                                        checked={workRequirement.equivalentYearlyWagesChecked === true}
                                        onChange={(e) => handleChange({ persist: e.persist, target: { name: 'equivalentYearlyWagesChecked', value: !workRequirement.equivalentYearlyWagesChecked } })}
                                    />
                                }
                                <div style={workRequirement.equivalentYearlyWagesChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        reduxFamilyInfo.sameRestrictions &&
                                        <p style={pStyle}>Equivalent to his or her previous calendar year’s annual wages</p>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Equivalent to his or her previous calendar year’s annual wages</p>
                                            {
                                                children.map((child: Beneficiary, key: number) =>
                                                    child.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Checkbox
                                                                style={checkboxStyle}
                                                                disabled={!workRequirement.equivalentYearlyWagesChecked}
                                                                name='equivalentYearlyWagesChecked'
                                                                value={'true'}
                                                                label={child.legalName}
                                                                key={key}
                                                                onFocus={() => updateFocus('Entitled Previous Calendar Year Annual Wages Percentage')}
                                                                onBlur={() => updateFocus('')}
                                                                checked={child.workRequirements.equivalentYearlyWagesChecked === true}
                                                                onChange={(e) => updateWorkRequirement({target: { name: 'equivalentYearlyWagesChecked', value: !child.workRequirements.equivalentYearlyWagesChecked }}, key)}
                                                            />
                                                            <br></br>
                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => 
                                                    grandChild.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Checkbox
                                                                style={checkboxStyle}
                                                                disabled={!workRequirement.equivalentYearlyWagesChecked}
                                                                name='equivalentYearlyWagesChecked'
                                                                value={'true'}
                                                                label={grandChild.legalName}
                                                                key={key}
                                                                onFocus={() => updateFocus('Entitled Previous Calendar Year Annual Wages Percentage')}
                                                                onBlur={() => updateFocus('')}
                                                                checked={grandChild.workRequirements.equivalentYearlyWagesChecked === true}
                                                                onChange={(e) => updateGrandChildrenWorkRequirement({target: { name: 'equivalentYearlyWagesChecked', value: !grandChild.workRequirements.equivalentYearlyWagesChecked }}, key)}
                                                            />
                                                            <br></br>
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='fixedPercentageYearlyWagesChecked'
                                    value={'true'}
                                    onFocus={() => updateFocus('Fixed Percentage Amount')}
                                    onBlur={() => updateFocus('')}
                                    checked={workRequirement.fixedPercentageYearlyWagesChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'fixedPercentageYearlyWagesChecked', value: !workRequirement.fixedPercentageYearlyWagesChecked }})}
                                />
                                <div style={workRequirement.fixedPercentageYearlyWagesChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <Form.Input name={'fixedPercentageYearlyWagesAmount'}
                                                    disabled={!workRequirement.fixedPercentageYearlyWagesChecked}
                                                    value={useChildren ? children[0].workRequirements.fixedPercentageYearlyWagesAmount : grandChildren[0].workRequirements.fixedPercentageYearlyWagesAmount}
                                                    onChange={(e) => updateAllWorkRequirements(e)}
                                                    className={'field'}
                                                    label={'Fixed Percentage Amount'}
                                                    placeholder={'Fixed Percentage Amount'}
                                                    width={16}
                                                    onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                    onBlur={() => updateFocus('')}
                                                    required
                                                    type="number"
                                                    min="0"/>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Fixed percent of his or her previous calandar year's annual wages</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => 
                                                    child.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'fixedPercentageYearlyWagesAmount'}
                                                                        disabled={!workRequirement.fixedPercentageYearlyWagesChecked}
                                                                        value={child.workRequirements.fixedPercentageYearlyWagesAmount}
                                                                        onChange={(e) => updateWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={child.legalName + ' Fixed Percentage Amount'}
                                                                        placeholder={'Fixed Percentage Amount'}
                                                                        key={key}
                                                                        width={16}
                                                                        onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required
                                                                        type="number"
                                                                        min="0"/>
                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => 
                                                    grandChild.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'fixedPercentageYearlyWagesAmount'}
                                                                        disabled={!workRequirement.fixedPercentageYearlyWagesChecked}
                                                                        value={grandChild.workRequirements.fixedPercentageYearlyWagesAmount}
                                                                        onChange={(e) => updateGrandChildrenWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={grandChild.legalName + ' Fixed Percentage Amount'}
                                                                        placeholder={'Fixed Percentage Amount'}
                                                                        key={key}
                                                                        width={16}
                                                                        onFocus={() => updateFocus('Fixed Percentage Amount')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required
                                                                        type="number"
                                                                        min="0"/>
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>                                    
                                <Checkbox
                                    style={checkboxStyle}
                                    name='cappedAmountChecked'
                                    value={'true'}
                                    onFocus={() => updateFocus('Capped At Amount')}
                                    onBlur={() => updateFocus('')}
                                    checked={workRequirement.cappedAmountChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'cappedAmountChecked', value: !workRequirement.cappedAmountChecked }})}
                                />
                                <div style={workRequirement.cappedAmountChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <Form.Input name={'cappedAmount'}
                                                    disabled={!workRequirement.cappedAmountChecked}
                                                    value={useChildren ? children[0].workRequirements.cappedAmount : grandChildren[0].workRequirements.cappedAmount}
                                                    onChange={(e) => updateAllWorkRequirements(e)}
                                                    className={'field'}
                                                    label={'Capped At Amount'} 
                                                    placeholder={'Example: $10,000'} 
                                                    width={16}
                                                    onFocus={() => updateFocus('Capped At Amount')}
                                                    onBlur={() => updateFocus('')}
                                                    required
                                                    type="number"
                                                    min="1"/>
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Capped at amount</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => 
                                                    child.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'cappedAmount'}
                                                                        disabled={!workRequirement.cappedAmountChecked}
                                                                        value={child.workRequirements.cappedAmount}
                                                                        onChange={(e) => updateWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={child.legalName + ' Capped At Amount'}
                                                                        placeholder={'Example: $10,000'} 
                                                                        width={16}
                                                                        onFocus={() => updateFocus('Capped At Amount')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required
                                                                        type="number"
                                                                        min="1"/>
                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => 
                                                    grandChild.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'cappedAmount'}
                                                                        disabled={!workRequirement.cappedAmountChecked}
                                                                        value={grandChild.workRequirements.cappedAmount}
                                                                        onChange={(e) => updateGrandChildrenWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={grandChild.legalName + ' Capped At Amount'}
                                                                        placeholder={'Example: $10,000'} 
                                                                        width={16}
                                                                        onFocus={() => updateFocus('Capped At Amount')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required
                                                                        type="number"
                                                                        min="1"/>
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='cappedPercentageChecked'
                                    value={'true'}
                                    onFocus={() => updateFocus('Capped Percentage')}
                                    onBlur={() => updateFocus('')}
                                    checked={workRequirement.cappedPercentageChecked === true}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'cappedPercentageChecked', value: !workRequirement.cappedPercentageChecked }})}
                                />
                                <div style={workRequirement.cappedPercentageChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <Form.Input name={'cappedPercentage'}
                                                    disabled={!workRequirement.cappedPercentageChecked}
                                                    value={useChildren ? children[0].workRequirements.cappedPercentage : grandChildren[0].workRequirements.cappedPercentage}
                                                    onChange={(e) => updateAllWorkRequirements(e)}
                                                    className={'field'}
                                                    label={'Capped Percentage'} 
                                                    placeholder={'Example: 4'} 
                                                    width={16} 
                                                    type="number"
                                                    min="1"
                                                    onFocus={() => updateFocus('Capped Percentage')}
                                                    onBlur={() => updateFocus('')}
                                                    required />
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Capped at percentage of the remaining trust</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => 
                                                    child.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'cappedPercentage'}
                                                                        disabled={!workRequirement.cappedPercentageChecked}
                                                                        value={child.workRequirements.cappedPercentage}
                                                                        onChange={(e) => updateWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={child.legalName + ' Capped Percentage'}
                                                                        placeholder={'Example: 4'} 
                                                                        width={16} 
                                                                        type="number"
                                                                        min="1"
                                                                        onFocus={() => updateFocus('Capped Percentage')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required />
                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => 
                                                    grandChild.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'cappedPercentage'}
                                                                        disabled={!workRequirement.cappedPercentageChecked}
                                                                        value={grandChild.workRequirements.cappedPercentage}
                                                                        onChange={(e) => updateGrandChildrenWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={grandChild.legalName + ' Capped Percentage'}
                                                                        placeholder={'Example: 4'} 
                                                                        width={16} 
                                                                        type="number"
                                                                        min="1"
                                                                        step="0.5"
                                                                        onFocus={() => updateFocus('Capped Percentage')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required />
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={distributionStyle}>
                                <Checkbox
                                    style={checkboxStyle}
                                    name='otherRequirementChecked'
                                    value={'true'}
                                    checked={workRequirement.otherRequirementChecked === true}
                                    onFocus={() => updateFocus('Other')}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'otherRequirementChecked', value: !workRequirement.otherRequirementChecked }})}
                                />
                                <div style={workRequirement.otherRequirementChecked === true ? checkedInputStyle : inputStyle}>
                                    {
                                        (reduxFamilyInfo.sameRestrictions && (children.length > 0 || grandChildren.length > 0)) &&
                                        <Form.Input name={'otherRequirementValue'}
                                                    disabled={!workRequirement.otherRequirementChecked}
                                                    value={useChildren ? children[0].workRequirements.otherRequirementValue : grandChildren[0].workRequirements.otherRequirementValue}
                                                    onChange={(e) => updateAllWorkRequirements(e)}
                                                    className={'field'}
                                                    label={'Other'} 
                                                    placeholder={'Other'} 
                                                    width={16} 
                                                    onFocus={() => updateFocus('Other')}
                                                    onBlur={() => updateFocus('')}
                                                    required />
                                    }
                                    {
                                        !reduxFamilyInfo.sameRestrictions &&
                                        <>
                                            <p style={pStyle}>Other</p>
                                            {
                                                children.map((child: Beneficiary, key: number) => 
                                                    child.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'otherRequirementValue'}
                                                                        disabled={!workRequirement.otherRequirementChecked}
                                                                        value={child.workRequirements.otherRequirementValue}
                                                                        onChange={(e) => updateWorkRequirement(e, key)}
                                                                        className={'field'}
                                                                        label={child.legalName + ' Other'}
                                                                        placeholder={'Other'} 
                                                                        width={16} 
                                                                        onFocus={() => updateFocus('Other')}
                                                                        onBlur={() => updateFocus('')}
                                                                        required />
                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                grandChildren.map((grandChild: Beneficiary, key: number) => 
                                                    grandChild.workRequirements.includeWorkRequirement && (
                                                        <>
                                                            <Form.Input name={'otherRequirementValue'}
                                                                    disabled={!workRequirement.otherRequirementChecked}
                                                                    value={grandChild.workRequirements.otherRequirementValue}
                                                                    onChange={(e) => updateGrandChildrenWorkRequirement(e, key)}
                                                                    className={'field'}
                                                                    label={grandChild.legalName + ' Other'}
                                                                    placeholder={'Other'} 
                                                                    width={16} 
                                                                    onFocus={() => updateFocus('Other')}
                                                                    onBlur={() => updateFocus('')}
                                                                    required />
                                                        </>
                                                    )
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className={'helpContainer'}>
                        <Header>{focus.field}</Header>
                        {/* Help section */}
                        {
                            !focus.field &&
                            <>
                                <p>The work requirement will require the beneficiary to be employed in order to receive their distributions.  Beneficiary’s right of withdrawal at each specified time will not occur until the Trustee receives verification of beneficiary’s employment through beneficiary’s W-2 or other specified documentation.  </p>
                                <p>You may allow for beneficiary’s distributions until the chosen retirement age to be: equivalent to the beneficiary’s previous calendar year’s annual wages; a specified percentage of beneficiary’s previous calendar year’s annual wages; you may cap the amount of the distribution at a fixed amount or a fixed percentage of the remaining trust assets so that the trust is not depleted too quickly.  You also have the option to customize the work requirement in any other way <u>not</u> already mentioned. </p>
                                <p>
                                    The purpose of the work requirement is to force the beneficiaries to continue working, rather than living off of their trust.
                                    <ul>
                                        <li>Stay-at-home parents can be covered under the work requirement to receive distributions from the trust for their work as a parent.</li>
                                    </ul>
                                </p>

                            </>
                        }
                        {
                            focus.field === 'Entitled Previous Calendar Year Annual Wages Percentage' &&
                            <p>Enter the percentage of his or her previous calendar year’s annual wages.</p>
                        }
                        {
                            focus.field === 'Fixed Percentage Amount' &&
                            <p>Enter the percentage of the annual ammount.</p>
                        }
                        {
                            focus.field === 'Capped At Amount' &&
                            <p>Enter the capped amount that can be given until they start working again.</p>
                        }
                        {
                            focus.field === 'Capped Percentage' &&
                            <p>Enter the capped percentage of the remaining trust assets.</p>
                        }
                        {
                            focus.field === 'Other' &&
                            <p>Any other requirement should be specified here.</p>
                        }
                    </div>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit">
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}