import {Welcome} from "../store/state";
import {initialState} from "../store/initialState";
import {welcomeActionTypes} from "../actionTypes/welcomeActionTypes";

export function welcomeReducer(state = initialState.welcome, action: {type: string, payload: any}): Welcome {
    switch (action.type) {
        case welcomeActionTypes.WELCOME_UPDATE_EMAIL: {
            return {
                ...state,
                ...action.payload
            };
        }
        case welcomeActionTypes.WELCOME_UPDATE_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
}