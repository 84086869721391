import React, { CSSProperties } from 'react';
import { BrowserRouterProps, RouteComponentProps } from 'react-router-dom';
import { Icon, Step } from 'semantic-ui-react';

const contentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    flexWrap: 'wrap'
};

export const MenuComponent = () => {
    // console.log(props);
    return (
        <div className={'menuContainerStyle'}>
            <Step.Group style={contentStyle} fluid size='mini'>
                <Step>
                    <Icon name={'id card outline'} />
                    <Step.Content>
                        <Step.Title>Grantor's Personal <br/> Information</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'users'} />
                    <Step.Content>
                        <Step.Title>Family <br/> Information</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'money bill alternate outline'} />
                    <Step.Content>
                        <Step.Title>Required Distributions <br/> Until Retirement</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'briefcase'} />
                    <Step.Content>
                        <Step.Title>Work <br/> Requirement</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'exclamation circle'} />
                    <Step.Content>
                        <Step.Title>Exceptions to <br/> Work Requirement</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'dollar sign'} />
                    <Step.Content>
                        <Step.Title>Distributions Upon <br/> Reaching Retirement</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'user outline'} />
                    <Step.Content>
                        <Step.Title>Successor <br/> Trustees</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'ambulance'} />
                    <Step.Content>
                        <Step.Title>After Death <br/> of a Beneficiary</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'plus circle'} />
                    <Step.Content>
                        <Step.Title>Additional<br/> Questions</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'hospital outline'} />
                    <Step.Content>
                        <Step.Title>Health Care<br/> Documents</Step.Title>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name={'money bill alternate outline'} />
                    <Step.Content>
                        <Step.Title>Asset <br /> Identification</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
        </div>
    )
}