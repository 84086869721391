import {Dispatch} from "redux";
import { FamilyInformation, State } from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";
import { familyInformationActionTypes } from "../actionTypes/familyInformationActionTypes";

export const updateWorkRequirementExceptions = (familyInfo: FamilyInformation) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
        payload:  {
            'children': familyInfo.children,
            'grandChildren': familyInfo.grandChildren,
            'sameRestrictions': familyInfo.sameRestrictions
        }
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 5
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step6');
    } catch (e) {
        console.log(e);
        throw e;
    }
};