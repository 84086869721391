import React, { useState, CSSProperties, useReducer, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, FormInputProps, Checkbox } from 'semantic-ui-react';
import { State } from '../store/state';
import { updateFamilyInfo, updateErrors } from "../actions/familyInformationActions";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    containerStyle, titleStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, headerStyle,
    checkboxStyle
} from '../styles';
import { StickyContainer, Sticky } from 'react-sticky';
import InputMask from 'react-input-mask';
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';
import useForm from "../useForm";
import validate from '../validationRules/familyInformationRules';

const informationContentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    flexWrap: 'wrap',
    padding: '15px',
    borderBottom: '1px solid rgb(227, 227, 227)',
    marginTop: '15px'
};

const addButtonStyle: CSSProperties = {
    backgroundColor: '#e6e6e6',
    color: 'grey',
    marginTop: '15px'
};

const telephoneContainerStyle: CSSProperties = {
    padding: '3%',
    width: '75%'
};

const addTelephoneButtonStyle: CSSProperties = {
    backgroundColor: '#e6e6e6',
    color: 'grey'
};

const removeButtonStyle: CSSProperties = {
    backgroundColor: 'transparent',
    color: 'red',
    alignSelf: 'right',
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0
};

const childrenSelector = (state: State) => state.familyInformation.children;
const grandchildrenSelector = (state: State) => state.familyInformation.grandChildren;
const familyInfoSelector = (state: State) => state.familyInformation;

export const FamilyInformationComponent = (props: RouteComponentProps) => {
    document.title = "Family Information";

    const reduxChildren = useSelector(childrenSelector);

    const [children, setChildrenValues] = useReducer((children, { target, type, key, teleKey, list = null }) => {
        switch (type) {
            case "add":
                return [
                    ...children,
                    {
                        key: new Date().getTime().toString(),
                        person: {
                            legalName: '',
                            homeAddress: '',
                            dateOfBirth: '',
                            phoneNumber: '',
                            emailAddress: ''
                        },
                        requiredDistributions: {
                            age: 0,
                            fixedDollarAmount: 0,
                            fixedPercentageAmount: 0,
                            workRequirementChecked: false,
                            otherDistributionDescription: ''
                        },
                        workRequirements: {
                            includeWorkRequirement: false,
                            fixedPercentageYearlyWagesAmount: 0,
                            cappedAmount: 0,
                            cappedPercentage: 0,
                            otherRequirementValue: ''
                        },
                        workRequirementExceptions: {
                            disabilityIncludeDisabilityException: false,
                            disabilityAbleToEarnMoreThanFiftyPercentChecked: false,
                            disabilityUnableToEarnMoreThanFiftyPercentChecked: false,
                            disabilityCausedByDrugOrAlcoholChecked: false,
                            disabilityOtherExceptionValue: '',
                            disabilityOtherExceptionChecked: false,
                            stayAtHomeParentIncludeStayAtHomeParentException: false,
                            stayAtHomeParentWorkRequirementForSpouseChecked: false,
                            stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: 0,
                            stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: false,
                            stayAtHomeParentPercentageOfDistributionsValue: 0,
                            stayAtHomeParentPercentageOfDistributionsChecked: false,
                            stayAtHomeParentOtherExceptionValue: '',
                            stayAtHomeParentOtherExceptionChecked: false
                        },
                        distributionsUponRetirement: {
                            includeDistributionsUponRetirement: false,
                            age: 0,
                            remainingBalanceChecked: false,
                            differentFinancialInstitution1: '',
                            differentFinancialInstitution2: '',
                            differentFinancialInstitutionsChecked: false,
                            alternativeLifeExpectancyMethod: '',
                            alternativeLifeExpectancyChecked: false,
                            distributionPercentageValue: 0,
                            monthlyDistributionValue: 0,
                            noLifeAnnuityChecked: false
                        }
                    }];
            case "remove":
                return children.filter((_: any, index: number) => index !== key);
            case "update":
                let child = children.find((_: any, index: number) => index === key);
                child[target.name] = target.value;
                return [...children];
            case "set": 
                return [...reduxChildren];
            default:
                return children;
        }
    }, reduxChildren);

    useEffect(() => {
        setChildrenValues({type: 'set'});
    }, [reduxChildren]);

    const updateChildValue = (e: { target: { name: string; value: string; };}, index: number) => {
        setChildrenValues({ target: {name: e.target.name, value: e.target.value}, type: "update", key: index, teleKey: '' });
    };

    const addChild = (e: FormEvent) => {
        e.preventDefault();
        setChildrenValues({ target: {name:'', value: ''}, type: "add", key: '', teleKey: '' }); 
    };

    const removeChild = (index: number) => {
        setChildrenValues({ target: {name:'', value: ''}, type: "remove", key: index, teleKey: '' });
    };

    const reduxGrandChildren = useSelector(grandchildrenSelector);
    const [grandChildren, setGrandChildrenValues] = useReducer((grandChildren, { target, type, key, teleKey, list=null }) => {
        switch (type) {
            case "add":
                return [...grandChildren,
                    {
                        key: new Date().getTime().toString(),
                        person: {
                            legalName: '',
                            homeAddress: '',
                            dateOfBirth: '',
                            phoneNumber: '',
                            emailAddress: ''
                        },
                        requiredDistributions: {
                            age: 0,
                            fixedDollarBoxChecked: false,
                            fixedDollarAmount: 0,
                            fixedPercentageAmount: 0,
                            workRequirementChecked: false,
                            otherDistributionDescription: ''
                        },
                        workRequirements: {
                            includeWorkRequirement: false,
                            fixedPercentageYearlyWagesAmount: 0,
                            cappedAmount: 0,
                            cappedPercentage: 0,
                            otherRequirementValue: ''
                        },
                        workRequirementExceptions: {
                            disabilityIncludeDisabilityException: false,
                            disabilityAbleToEarnMoreThanFiftyPercentChecked: false,
                            disabilityUnableToEarnMoreThanFiftyPercentChecked: false,
                            disabilityCausedByDrugOrAlcoholChecked: false,
                            disabilityOtherExceptionValue: '',
                            disabilityOtherExceptionChecked: false,
                            stayAtHomeParentIncludeStayAtHomeParentException: false,
                            stayAtHomeParentWorkRequirementForSpouseChecked: false,
                            stayAtHomeParentWorkRequirementDelayedUntilChildAgeValue: 0,
                            stayAtHomeParentWorkRequirementDelayedUntilChildAgeChecked: false,
                            stayAtHomeParentPercentageOfDistributionsValue: 0,
                            stayAtHomeParentPercentageOfDistributionsChecked: false,
                            stayAtHomeParentOtherExceptionValue: '',
                            stayAtHomeParentOtherExceptionChecked: false
                        },
                        distributionsUponRetirement: {
                            includeDistributionsUponRetirement: false,
                            age: 0,
                            remainingBalanceChecked: false,
                            differentFinancialInstitution1: '',
                            differentFinancialInstitution2: '',
                            differentFinancialInstitutionsChecked: false,
                            alternativeLifeExpectancyMethod: '',
                            alternativeLifeExpectancyChecked: false,
                            distributionPercentageValue: 0,
                            monthlyDistributionValue: 0,
                            noLifeAnnuityChecked: false
                        }
                    }];
            case "remove":
                return grandChildren.filter((_: any, index: number) => index !== key);
            case "update":
                const grandChild = grandChildren.find((_: any, index: number) => index === key);
                grandChild[target.name] = target.value;
                return [...grandChildren];
            case "set":
                return [...reduxGrandChildren];
            default:
                return grandChildren;
        }
    }, reduxGrandChildren);

    useEffect(() => {
        setGrandChildrenValues({type: "set"});
    }, [reduxGrandChildren]);

    const updateGrandChildValue = (e: { target: { name: string; value: string; };}, index: number ) => {
        setGrandChildrenValues({ target: {name: e.target.name, value: e.target.value}, type: "update", key: index, teleKey: '' });
    };

    const addGrandChild = (e: FormEvent) => {
        e.preventDefault();
        setGrandChildrenValues({ target: {name:'', value: ''}, type: "add", key: '', teleKey: ''  });
    };

    const removeGrandChild = (index: number) => {
        setGrandChildrenValues({ target: {name:'', value: ''}, type: "remove", key: index, teleKey: '' });
    };

    const [focus, setFocus] = useState({
        person: '',
        field: ''
    });

    const updateFocus = (person: string, field: string) => {
        setFocus({
            person,
            field
        })
    };

    const dispatch = useDispatch();

    const nextPage = (event: FormEvent) => {
        let errors = validate(children, grandChildren);
        if(errors == null){
            errors = null;
            dispatch(updateErrors(errors));
            event.preventDefault();

            dispatch(updateFamilyInfo(children, grandChildren, familyInfo.sameRestrictions));
        }
        else{
            dispatch(updateErrors(errors));
        }
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const reduxFamilyInfo = useSelector(familyInfoSelector);

    const {
        values: familyInfo,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(reduxFamilyInfo, nextPage, validate);

    useEffect(() => {
        setValues({...reduxFamilyInfo});
    }, [reduxFamilyInfo]);

    dispatch(redirectToAppropriateStep(2));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Family Information</h1>
            <Form onSubmit={nextPage}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <Header style={headerStyle}><b>Children</b></Header>
                        {children.map((child: any, key: number) => (
                            child != null &&
                            <div style={informationContentStyle} key={key}>
                                <Button style={removeButtonStyle} 
                                        className={'ui icon button'} 
                                        onClick={() => removeChild(key)}>
                                            <i className={'times icon'}></i>
                                </Button>
                                <Form.Input name={'legalName'}
                                            value={child.legalName}
                                            onChange={(e) => updateChildValue(e, key)} 
                                            className={'field'} label={'Legal Name'} 
                                            placeholder={'Legal Name'} 
                                            width={16} 
                                            onFocus={() => updateFocus('Children', 'Legal Name')}
                                            onBlur={() => updateFocus('', '')}
                                            autoFocus
                                            required />
                                <Form.Input name={'homeAddress'}
                                            value={child.homeAddress}
                                            onChange={(e) => updateChildValue(e, key)} 
                                            className={'field'} 
                                            label={'Home Address'} 
                                            placeholder={'Home Address'} 
                                            width={16} 
                                            onFocus={() => updateFocus('Children', 'Home Address')} 
                                            onBlur={() => updateFocus('', '')}
                                            required />
                                <InputMask mask="99/99/9999" 
                                           value={child.dateOfBirth}
                                           onChange={(e) => updateChildValue(e, key)} 
                                           maskChar={null}
                                           onFocus={() => updateFocus('Children', 'Date of Birth')} 
                                           onBlur={() => updateFocus('', '')}>
                                               {(inputProps: FormInputProps) => 
                                                   <Form.Input name={'dateOfBirth'}
                                                               value={child.dateOfBirth}
                                                               className={'field'} 
                                                               label={'Date of Birth'} 
                                                               placeholder={'12/12/2012'} 
                                                               width={6} 
                                                               required
                                                               {...inputProps} />
                                               }
                                </InputMask>
                                <Form.Field width={12}></Form.Field>
                                <InputMask mask="(999) 999-9999" 
                                                value={child.phoneNumber}
                                                onChange={(e) => updateChildValue(e, key)} 
                                                maskChar={null}
                                                onFocus={() => updateFocus('Children', 'Telephone Number')}
                                                onBlur={() => updateFocus('', '')}>
                                                    {(inputProps: FormInputProps) => 
                                                        <Form.Input name={'phoneNumber'}
                                                                    value={child.phoneNumber}
                                                                    className={'field'} 
                                                                    label={'Telephone Number'} 
                                                                    placeholder={'(513) 555-1234'} 
                                                                    width={10}
                                                                    {...inputProps} />}
                                </InputMask>
                                <Form.Input name={'emailAddress'}
                                            value={child.emailAddress}
                                            onChange={e => updateChildValue(e, key)}
                                            className={'field'} 
                                            label={'Email Address'} 
                                            placeholder={'billsmith@gmail.com'} 
                                            width={12} 
                                            required
                                            onFocus={() => updateFocus('Children', 'Email Address')}
                                            onBlur={() => updateFocus('', '')} />
                            </div>
                        ))}
                        <Button style={addButtonStyle} 
                                className={'ui left labeled icon button'} 
                                onClick={addChild}> 
                                    Add Child<i className={'plus icon'}></i>
                        </Button>
                        <br />
                        <Header style={headerStyle}><b>Grand Children</b></Header>
                        {grandChildren.map(((grandChild: any, key: number) => (
                            grandChild != null &&
                            <div style={informationContentStyle} key={key}>
                                <Button style={removeButtonStyle} 
                                        className={'ui icon button'} 
                                        onClick={() => removeGrandChild(key)}>
                                            <i className={'times icon'}></i>
                                </Button>
                                <Form.Input name={'legalName'}
                                            value={grandChild.legalName}
                                            onChange={(e) => updateGrandChildValue(e, key)} 
                                            className={'field'} 
                                            label={'Legal Name'} 
                                            placeholder={'Legal Name'}
                                            width={12} 
                                            onFocus={() => updateFocus('Grand Children', 'Legal Name')}
                                            onBlur={() => updateFocus('', '')}
                                            required />
                                <Form.Input name={'homeAddress'}
                                            value={grandChild.homeAddress}
                                            onChange={(e) => updateGrandChildValue(e, key)} 
                                            className={'field'} 
                                            label={'Home Address'} 
                                            placeholder={'Home Address'} 
                                            width={16} 
                                            onFocus={() => updateFocus('Grand Children', 'Home Address')}
                                            onBlur={() => updateFocus('', '')}
                                            required />
                                <InputMask mask="99/99/9999" 
                                           value={grandChild.dateOfBirth}
                                           onChange={(e) => updateGrandChildValue(e, key)} 
                                           maskChar={null}
                                           onFocus={() => updateFocus('Grand Children', 'Date of Birth')}
                                           onBlur={() => updateFocus('', '')}>
                                               {(inputProps: FormInputProps) => 
                                                    <Form.Input name={'dateOfBirth'}
                                                                value={grandChild.dateOfBirth}
                                                                className={'field'} 
                                                                label={'Date of Birth'}
                                                                placeholder={'12/12/2012'}
                                                                width={6} 
                                                                required
                                                                {...inputProps} />
                                               }
                                </InputMask>
                                <Form.Field width={12}></Form.Field>
                                <InputMask mask="(999) 999-9999" 
                                                value={grandChild.phoneNumber}
                                                onChange={(e) => updateGrandChildValue(e, key)} 
                                                maskChar={null}
                                                onFocus={() => updateFocus('Grand Children', 'Telephone Number')}>
                                                    {(inputProps: FormInputProps) => 
                                                        <Form.Input name={'phoneNumber'}
                                                                    value={grandChild.phoneNumber}
                                                                    className={'field'} 
                                                                    label={'Telephone Number'} 
                                                                    placeholder={'(513) 555-1234'} 
                                                                    width={10}
                                                                    {...inputProps} />}
                                </InputMask>
                                <Form.Input name={'emailAddress'}
                                            value={grandChild.emailAddress}
                                            onChange={e => updateGrandChildValue(e, key)}
                                            className={'field'} 
                                            label={'Email Address'} 
                                            placeholder={'billsmith@gmail.com'} 
                                            width={12}
                                            onFocus={() => updateFocus('Grand Children', 'Email Address')}
                                            onBlur={() => updateFocus('', '')}
                                            required />
                            </div>
                        )))}
                        <Button style={addButtonStyle} 
                                className={'ui left labeled icon button'} 
                                onClick={addGrandChild}>
                                    Add Grand Child<i className={'plus icon'}></i>
                        </Button>
                    </div>
                    <StickyContainer className={'helpContainer'}>
                        <Sticky>
                            {({style: stickyStyle}) => 
                                <div style={stickyStyle}>
                                    {/* Children help section */}
                                    {
                                        focus.field && focus.person &&
                                        <h3>{focus.field} - {focus.person}</h3>
                                    }
                                    {
                                        (!focus.field || !focus.person) &&
                                        <><h3>Children and Grandchildren</h3>
                                        <p>Enter the children and grandchildren you would like to be part of the trust.</p></>
                                    }
                                    {
                                        focus.person === 'Children' && focus.field === 'Legal Name' &&
                                        <p>Enter the child's full name</p>
                                    }
                                    {
                                        focus.person === 'Children' && focus.field === 'Home Address' &&
                                        <p>Enter the child's home address</p>
                                    }
                                    {
                                        focus.person === 'Children' && focus.field === 'Telephone Number' &&
                                        <p>Enter the child's preferred telephone number with area code (if applicable)</p>
                                    }
                                    {
                                        focus.person === 'Children' && focus.field === 'Date of Birth' &&
                                        <p>Enter the child's date of birth</p>
                                    }
                                    {
                                        focus.person === 'Children' && focus.field === 'Email Address' &&
                                        <p>Enter the child's email address (if applicable)</p>
                                    }
                                    {/* Grand Children help section */}
                                    {
                                        focus.person === 'Grand Children' && focus.field === 'Legal Name' &&
                                        <p>Enter the grand child's full name</p>
                                    }
                                    {
                                        focus.person === 'Grand Children' && focus.field === 'Home Address' &&
                                        <p>Enter the grand child's home address</p>
                                    }
                                    {
                                        focus.person === 'Grand Children' && focus.field === 'Telephone Number' &&
                                        <p>Enter the grand child's preferred telephone number with area code (if applicable)</p>
                                    }
                                    {
                                        focus.person === 'Grand Children' && focus.field === 'Date of Birth' &&
                                        <p>Enter thegrand  child's date of birth</p>
                                    }
                                    {
                                        focus.person === 'Grand Children' && focus.field === 'Email Address' &&
                                        <p>Enter the grand child's email address (if applicable)</p>
                                    }
                                </div>
                            }
                        </Sticky>
                    </StickyContainer>
                    <Checkbox
                        style={checkboxStyle}
                        label='I want the same benefits and restrictions for all children and grandchildren'
                        name='sameRestrictions'
                        value={'true'}
                        checked={familyInfo.sameRestrictions === true}
                        onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'sameRestrictions', value: !familyInfo.sameRestrictions } })}
                    />
                    {reduxFamilyInfo.errors &&
                        <div style={{color: 'rgb(255, 0, 0)', margin: '15px', width: '100%', textAlign: 'center'}}>
                            {reduxFamilyInfo.errors}
                        </div>
                    }
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i> 
                    </Button>
                    <Button style={buttonStyle} type="submit"
                            className={'ui right labeled icon button'}>
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}