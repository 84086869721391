import React, { useState, CSSProperties, useReducer, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Header, Form, Radio, Checkbox, FormInputProps, Dropdown, FormProps} from 'semantic-ui-react';
import {AssetIdentification, GrantorsPersonalInfo, State} from '../store/state';
import {updateAdditionalQuestions} from "../actions/additionalQuestionsActions";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    containerStyle, titleStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle, headerStyle,
    radioStyle, pStyle, checkboxStyle, dropdownDivStyle, dropdownLabelStyle, dropdownStyle
} from '../styles';
import { StickyContainer, Sticky } from 'react-sticky';
import {updateAssets} from "../actions/assetIdentificationActions";
import useForm from "../useForm";
import validate from "../validationRules/assetIdentificationRules";
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const addressStyle: CSSProperties = {
    paddingBottom: '10px'
};

const holdRightsCheckboxStyle: CSSProperties = {
    padding: '5px',
    alignSelf: 'center',
    marginBottom: '15px'
};

const assetSelector = (state: State) => state.assetIdentification;

export const AssetIdentificationComponent = (props: RouteComponentProps) => {
    const assets = useSelector(assetSelector);

    const nextPage = () => {
        dispatch(updateAssets(assetIdentification));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: assetIdentification,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } : {
        values: AssetIdentification,
        setValues: Function,
        errors: any,
        handleChange: any,
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(assets, nextPage, validate);

    useEffect(() => {
        setValues({...assets});
    }, [assets]);

    const [focus, setFocus] = useState({
        field: ''
    });
    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const dispatch = useDispatch();

    const states: any[] = [
        {
            text: 'Ohio',
            value: 'OH'
        },
        {
            text: 'Kentucky',
            value: 'KY'
        }
    ];

    const stateOptions = states.map((state: any) => ({
        key: state.value,
        text: state.text,
        value: state.value,
    }));

    dispatch(redirectToAppropriateStep(11));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Short-and-Quick Asset Identification</h1>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'}>
                    <div className={'informationContainer'}>
                        <Header style={headerStyle}><b>Property</b></Header>
                        <div>
                            <Checkbox
                                label='I own property'
                                name='hasProperty'
                                value='true'
                                style={checkboxStyle}
                                checked={assetIdentification.hasProperty === true}
                                onFocus={() => updateFocus('Property')} 
                                onBlur={() => updateFocus('')}
                                onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'hasProperty', value: !assetIdentification.hasProperty } })} />
                        </div>
                        {
                            assetIdentification.hasProperty && 
                            <>
                                <p style={pStyle}>You’ve identified who you want to receive your property and when. Now it’s time to briefly examine your assets so we can determine the best estate planning methods for you.</p>
                                <Form.Group unstackable style={addressStyle}>
                                    <Form.Input name={'propertyCity'}
                                                value={assetIdentification.propertyCity}
                                                onChange={handleChange}
                                                className={'field'}
                                                label={'City'}
                                                placeholder={'City'}
                                                width={10}
                                                onFocus={() => updateFocus('Property')} 
                                                onBlur={() => updateFocus('')}
                                                error={errors.propertyCity}
                                                autoFocus
                                                required />
                                    <div style={dropdownDivStyle}>
                                        <p style={dropdownLabelStyle} className="dropdown required"><b>State</b></p>
                                        <Dropdown name={'propertyState'}
                                                value={assetIdentification.propertyState}
                                                onChange={(e: any, data: any) => handleChange({ persist: e.persist, target: { name: 'propertyState', value: data.value } })}
                                                placeholder={'State'}
                                                search
                                                selection
                                                options={stateOptions}
                                                style={dropdownStyle}
                                                onFocus={() => updateFocus('Property')} 
                                                onBlur={() => updateFocus('')}
                                                error={errors.propertyState}
                                                required />
                                    </div>
                                </Form.Group>
                                <Checkbox
                                    label='Owned in survivorship with my spouse'
                                    name='survivorshipWithSpouse'
                                    value='yes'
                                    style={checkboxStyle}
                                    checked={assetIdentification.survivorshipWithSpouse === true}
                                    onFocus={() => updateFocus('Property')} 
                                    onBlur={() => updateFocus('')}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'survivorshipWithSpouse', value: !assetIdentification.survivorshipWithSpouse } })}
                                />
                                <br />
                                <Checkbox
                                    label='Other persons hold a legal right in the property'
                                    name='otherHoldsRights'
                                    value='yes'
                                    style={holdRightsCheckboxStyle}
                                    checked={assetIdentification.otherHoldsRights === true}
                                    onFocus={() => updateFocus('Property')} 
                                    onBlur={() => updateFocus('')}
                                    onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'otherHoldsRights', value: !assetIdentification.otherHoldsRights } })}
                                />
                                <Form.Input name={'currentMarketValue'}
                                            value={assetIdentification.currentMarketValue}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Current Market Value'}
                                            placeholder={'Market Value'}
                                            width={10}
                                            onFocus={() => updateFocus('Property')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.currentMarketValue}
                                            required
                                            type="number"
                                            step="0.01"
                                            min="0" />
                                <br />
                            </>
                        }
                        
                        <Checkbox
                            label='I have investment and vacation properties'
                            name='investmentsOrVacationProperties'
                            value='yes'
                            style={checkboxStyle}
                            checked={assetIdentification.investmentsOrVacationProperties === true}
                            onFocus={() => updateFocus('Property')} 
                            onBlur={() => updateFocus('')}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'investmentsOrVacationProperties', value: !assetIdentification.investmentsOrVacationProperties } })}
                        />
                        {
                            assetIdentification.investmentsOrVacationProperties &&
                            <div>
                                <br />
                                <Form.Input name={'investmentOrVacationLocations'}
                                            value={assetIdentification.investmentOrVacationLocations}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Investment/Vacation Property Locations'}
                                            placeholder={'Location(s)'}
                                            width={16}
                                            onFocus={() => updateFocus('Property')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.investmentOrVacations}
                                            required />
                                <Form.Input name={'investmentOrVacationOwners'}
                                            value={assetIdentification.investmentOrVacationOwners}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Investment/Vacation Property Owners'}
                                            placeholder={'Owner(s)'}
                                            width={16}
                                            onFocus={() => updateFocus('Property')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.investmentOrVacations}
                                            required />
                            </div>
                        }
                        <Header style={headerStyle}><b>Life Insurance</b></Header>
                        <Checkbox
                            label='I have a life insurance policy'
                            name='lifeInsuranceChecked'
                            value='yes'
                            style={checkboxStyle}
                            checked={assetIdentification.lifeInsuranceChecked === true}
                            onFocus={() => updateFocus('Life Insurance')} 
                            onBlur={() => updateFocus('')}
                            onChange={(e: any) => handleChange({ persist: e.persist, target: { name: 'lifeInsuranceChecked', value: !assetIdentification.lifeInsuranceChecked } })}
                        />
                        {
                            assetIdentification.lifeInsuranceChecked &&
                            <div>
                                <br />
                                <Form.Input name={'lifeInsuranceCompany'}
                                            value={assetIdentification.lifeInsuranceCompany}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Company'}
                                            placeholder={'Company'}
                                            width={16}
                                            onFocus={() => updateFocus('Life Insurance')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.lifeInsurance}
                                            required />
                                <Form.Input name={'lifeInsurancePolicyNumber'}
                                            value={assetIdentification.lifeInsurancePolicyNumber}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Policy Number'}
                                            placeholder={'Policy Number'}
                                            width={16}
                                            onFocus={() => updateFocus('Life Insurance')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.lifeInsurance}
                                            required />
                                <Form.Input name={'lifeInsuranceOwner'}
                                            value={assetIdentification.lifeInsuranceOwner}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Owner'}
                                            placeholder={'Owner'}
                                            width={16}
                                            onFocus={() => updateFocus('Life Insurance')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.lifeInsurance}
                                            required />
                                <Form.Input name={'lifeInsuranceBeneficiary'}
                                            value={assetIdentification.lifeInsuranceBeneficiary}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Beneficiary'}
                                            placeholder={'Beneficiary'}
                                            width={16}
                                            onFocus={() => updateFocus('Life Insurance')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.lifeInsurance}
                                            required />
                                <Form.Input name={'lifeInsuranceAmount'}
                                            value={assetIdentification.lifeInsuranceAmount}
                                            onChange={handleChange}
                                            className={'field'}
                                            label={'Amount'}
                                            placeholder={'Amount'}
                                            width={16}
                                            onFocus={() => updateFocus('Life Insurance')} 
                                            onBlur={() => updateFocus('')}
                                            error={errors.lifeInsurance}
                                            required
                                            type="number"
                                            step="0.01"
                                            min="0" />
                            </div>
                        }
                        <Header style={headerStyle}><b>Investments</b></Header>
                        <Form.TextArea name={'investments'}
                                    value={assetIdentification.investments}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'List your investments and their values'}
                                    placeholder={'Investment name(s) and value(s)'}
                                    width={16}
                                    onFocus={() => updateFocus('Investments')} 
                                    onBlur={() => updateFocus('')} />
                        <Header style={headerStyle}><b>Retirement Plans</b></Header>
                        <Form.TextArea name={'retirementPlans'}
                                    value={assetIdentification.retirementPlans}
                                    onChange={handleChange}
                                    className={'field'}
                                    label={'Identify the type (IRA, 401(k), etc) as well as its estimated value'}
                                    placeholder={'Retirement plan(s)'}
                                    width={16}
                                    onFocus={() => updateFocus('Retirement Plans')} 
                                    onBlur={() => updateFocus('')} />
                    </div>
                    <StickyContainer className={'helpContainer'}>
                        <Sticky>
                            {({style: stickyStyle}) => 
                                <div style={stickyStyle}>
                                    {
                                        focus.field &&
                                        <h3>{focus.field}</h3>
                                    }
                                    {
                                        !focus.field &&
                                        <><h3>Help</h3>
                                        <p>Help text will show up here are you navigate through the form</p></>
                                    }
                                    {
                                        focus.field === 'Property' &&
                                        <p>The properties should contain all your properties either lived in or used as investments.</p>
                                    }
                                    {
                                        focus.field === 'Life Insurance' &&
                                        <p>If you have life insurance list it here.</p>
                                    }
                                    {
                                        focus.field === 'Investments' &&
                                        <p>Any other investments other than properties.</p>
                                    }
                                    {
                                        focus.field === 'Retirement Plans' &&
                                        <p>If you have any other retirement plans, detail them here.</p>
                                    }
                                </div>
                            }
                        </Sticky>
                    </StickyContainer>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={backButtonStyle} 
                            className={'ui left labeled icon button'} 
                            onClick={(e: FormEvent) => goBack(e)}>
                                Back <i className={'left arrow icon'}></i>
                    </Button>
                    <Button style={buttonStyle} type="submit"
                            className={'ui right labeled icon button'}>
                                Next <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}