import {AssetIdentification} from "../store/state";

export default function validate(assets : AssetIdentification) {
    let errors : any = {};

    if (!assets.propertyCity) {
        errors.propertyCity = 'You must enter a city.';
    }

    if (!assets.propertyState) {
        errors.propertyState = true;
    }

    if (!assets.currentMarketValue) {
        errors.currentMarketValue = 'You must enter a value.';
    }

    if (assets.investmentsOrVacationProperties && !(assets.investmentOrVacationLocations || assets.investmentOrVacationOwners)) {
        errors.currentMarketValue = 'You must enter a value for your investment locations and owners.';
    }

    if (assets.lifeInsuranceChecked && !(assets.lifeInsuranceAmount || assets.lifeInsuranceBeneficiary || assets.lifeInsuranceCompany || assets.lifeInsuranceOwner || assets.lifeInsurancePolicyNumber)) {
        errors.lifeInsurance = 'If you have life insurance you must fill out all information.';
    }

    return errors;
};