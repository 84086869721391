import React, { CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { State } from '../store/state';
import { containerStyle, centerStyle, buttonStyle } from '../styles';
import stripe from '../stripeService';
import { redirectToAppropriateStep } from '../actions/routerActions';

declare const STRIPE_SKU: string;
declare const STRIPE_SUCCESS_URL: string;
declare const STRIPE_FAILURE_URL: string;

const textStyle: CSSProperties = {
    fontFamily: 'Lato',
    fontSize: '18px',
    margin: '10px',
    color: 'black'
}

const customerAuthSelector = (state: State) => state.customerAuth;

export const PaymentComponent = (props: RouteComponentProps) => {
    document.title = "Questionnaire Complete!";
    const reduxCustomerAuth = useSelector(customerAuthSelector)!;
    function startStripeCheckout() {
        stripe.redirectToCheckout({
            customerEmail: reduxCustomerAuth.email,
            lineItems: [
              // Replace with the ID of your SKU
              {price: STRIPE_SKU, quantity: 1}
            ],
            mode: 'payment',
            successUrl: STRIPE_SUCCESS_URL,
            cancelUrl: STRIPE_FAILURE_URL,
          })
    }

    const dispatch = useDispatch();

    dispatch(redirectToAppropriateStep(12));

    return (
        <div style={containerStyle}>
            <Form>
                <div className={'contentStyle'} style={{flexDirection: 'column', alignItems: 'center'}}>
                    <div className={'informationContainer'} style={{display: 'flex', flexDirection: 'column'}}>
                        <h2>Questionnaire Complete!</h2>
                        <p style={textStyle}>Enter your payment information below for a one-time charge to create the documents for your retirement trust.</p>
                        <h3>Amount Due: $500.00</h3>
                        <div style={{...centerStyle}} className={'ui fluid input'}>
                            <Button type={'button'} className='btn btn-primary' style={buttonStyle} onClick={startStripeCheckout}>Checkout</Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}