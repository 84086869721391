import {AdditionalQuestions} from "../store/state";

export default function validate(additionalQuestions : AdditionalQuestions) {
    let errors : any = {};

    if (!additionalQuestions.primaryExecutor) {
        errors.primaryExecutor = 'You must select a primary Executor.';
    }

    if (!additionalQuestions.primaryAttorneyInFact) {
        errors.primaryAttorneyInFact = 'You must select a primary Attorney In Fact.';
    }

    if (!additionalQuestions.primaryGuardian) {
        errors.primaryGuardian = 'You must select a primary Guardian.';
    }

    return errors;
};