import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from '../reducers/rootReducer';
import { routerMiddleware } from 'connected-react-router';
import {history} from './history';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['customerAuth'] 
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default function configureStore() {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ]
    const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(...middleware))
    );
    return {store, persistor: persistStore(store)};
}
