import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { containerStyle } from '../styles';
import { useDispatch } from 'react-redux';
import { redirectToAppropriateStep } from '../actions/routerActions';

const textStyle: CSSProperties = {
    fontFamily: 'Lato',
    fontSize: '18px',
    margin: '10px',
    color: 'black'
}

interface PaymentResultComponentProps {
    error?: boolean;
}

export const PaymentResultComponent = (props: RouteComponentProps & PaymentResultComponentProps) => {
    document.title = "Thank you!";
    const dispatch = useDispatch();

    // dispatch(redirectToAppropriateStep(12));
    
    return (
        <div style={containerStyle}>
            <Form>
                <div className={'contentStyle'} style={{flexDirection: 'column', alignItems: 'center'}}>
                    <div className={'informationContainer'} style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            props.error ?
                            <>
                            <h2>Something went wrong.</h2>
                            <p style={textStyle}>Something went wrong with your payment. Please contact us at (513) 731-6601.</p>
                            </>
                            :
                            <>
                                <h2>Thank You!</h2>
                                <p style={textStyle}>Your document is being created. You'll receive your finalized document to the email address you entered within five business days.</p>
                            </>
                        }
                    </div>
                </div>
            </Form>
        </div>
    )
}