import React, { useState, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Header, Form, Radio } from 'semantic-ui-react';
import { State } from '../store/state';
import {updateDistributionAfterDeathOfBeneficiary} from "../actions/deathOfBeneficiaryActions";
import {Link, RouteComponentProps} from "react-router-dom";
import { beneficiaryStyle, containerStyle, titleStyle, pStyle, radioStyle, otherRadioStyle, checkedInputStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle, backButtonStyle } from '../styles';
import useForm from '../useForm';
import validate from '../validationRules/deathOfBenificiaryRules';
import { history } from '../store/history';
import { redirectToAppropriateStep } from '../actions/routerActions';

const deathOfBeneficiarySelector = (state: State) => state.deathOfBeneficiary;

export const DeathOfBeneficiaryComponent = (props: RouteComponentProps) => {
    document.title = "After Death of a Beneficiary";
    const deathOfBeneficiary = useSelector(deathOfBeneficiarySelector);

    const nextPage = () => {
        dispatch(updateDistributionAfterDeathOfBeneficiary(beneficiary));
    };

    const goBack = (event: FormEvent) => {
        event.preventDefault();
        history.goBack();
    };

    const {
        values: beneficiary,
        setValues,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(deathOfBeneficiary, nextPage, validate);

    useEffect(() => {
        setValues({...deathOfBeneficiary});
    }, [deathOfBeneficiary]);

    const [focus, setFocus] = useState({
        field: ''
    });
    const updateFocus = (field: string) => {
        setFocus({
            field
        })
    };

    const dispatch = useDispatch();
    dispatch(redirectToAppropriateStep(8));

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>After Death Of A Beneficiary</h1>
            <Form onSubmit={handleSubmit}>
            <div className={'contentStyle'}>
                <div className={'informationContainer'}>
                        <p style={pStyle}>The deceased beneficiary’s remaining assets will be distributed to:</p>
                        <Radio
                            label='His or her then living issue, per stirpes'
                            name='selectedBeneficiary'
                            value='livingIssue'
                            style={radioStyle}
                            checked={beneficiary.selectedBeneficiary === 'livingIssue'}
                            onChange={(e: FormEvent) => handleChange({ persist: e.persist, target:{name: 'selectedBeneficiary', value: 'livingIssue'}})}
                        /><br/>
                        <Radio
                            label='One or all other sub-trusts written in the document'
                            name='selectedBeneficiary'
                            value='subTrusts'
                            style={radioStyle}
                            checked={beneficiary.selectedBeneficiary === 'subTrusts'}
                            onChange={(e: FormEvent) => handleChange({ persist: e.persist, target:{name: 'selectedBeneficiary', value: 'subTrusts'}})}
                        /><br/>
                        <Radio
                            label='Any other named beneficiary'
                            name='selectedBeneficiary'
                            value='otherNamedBeneficiary'
                            style={radioStyle}
                            checked={beneficiary.selectedBeneficiary === 'otherNamedBeneficiary'}
                            onChange={(e: FormEvent) => handleChange({ persist: e.persist, target:{name: 'selectedBeneficiary', value: 'otherNamedBeneficiary'}})}
                        /><br/>
                        <div style={beneficiaryStyle}>
                            <Radio
                                name='selectedBeneficiary'
                                value='other'
                                style={otherRadioStyle}
                                checked={beneficiary.selectedBeneficiary === 'other'}
                                onChange={(e: FormEvent) => handleChange({ persist: e.persist, target:{name: 'selectedBeneficiary', value: 'other'}})}
                            />
                            <div style={beneficiary.selectedBeneficiary === 'other' ? checkedInputStyle : inputStyle}>
                                <Form.Input name={'otherValue'} 
                                            disabled={beneficiary.selectedBeneficiary !== 'other'} 
                                            value={beneficiary.otherValue} 
                                            onChange={handleChange} 
                                            className={'field'} 
                                            label={'Other'} 
                                            placeholder={'Other'} 
                                            width={16} 
                                            onFocus={() => updateFocus('Other')} 
                                            onBlur={() => updateFocus('')}
                                            required/>
                            </div>
                        </div>
                </div>
                <div className={'helpContainer'}>
                    {focus.field !== '' &&
                        <Header>{focus.field}</Header>
                    }
                    {/* help section */}
                    {
                        !focus.field && 
                        <>
                            <p>
                                Designate how the beneficiary’s remaining assets should be distributed if beneficiary passes away.  
                                <ul>
                                    <li>You may choose to distribute remaining assets to the beneficiary’s then living children, equally (per stripes); to one or all other living beneficiaries in the trust; to any other named beneficiaries; or you may specify any alternative individuals or organizations you wish. </li>
                                </ul>
                            </p>
                        </>
                    }
                    {
                        focus.field === 'Other'  &&
                        <p>Include any other beneficiary.</p>
                    }
                </div>
            </div>
            <div style={{...buttonContainerStyle, ...centerStyle}}>
                <Button style={backButtonStyle} 
                        className={'ui left labeled icon button'} 
                        onClick={(e: FormEvent) => goBack(e)}>
                            Back <i className={'left arrow icon'}></i>
                </Button>
                <Button style={buttonStyle} 
                        className={'ui right labeled icon button'} 
                        type="submit">
                            Next <i className={'right arrow icon'}></i>
                </Button>
            </div>
            </Form>
        </div>
    )
}