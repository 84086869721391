import { useState, useEffect } from 'react';

const useForm = (state: any, callback: Function, validate: Function) => {

  const [values, setValues] = useState({...state});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event: any) => {
    if (typeof event.persist === "function") event.persist();
    
    let value = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;
    setValues((e:any) => ({ ...values, [event.target.name]: value }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors
  }
};

export default useForm;