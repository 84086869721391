import {Dispatch} from "redux";
import {distributionsUponRetirementActionTypes} from "../actionTypes/distributionsUponRetirementActionTypes";
import { State, DistributionsUponRetirement, FamilyInformation } from "../store/state";
import { convertToServerResponse } from "../translator";
import { updateResponse } from "../api";
import { history } from '../store/history';
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";
import { familyInformationActionTypes } from "../actionTypes/familyInformationActionTypes";

export const updateDistributionsUponRetirement = (familyInfo: FamilyInformation, distributionsUponRetirement: DistributionsUponRetirement) => async (dispatch: Dispatch<any>, getState: () => State ) => {
    dispatch({
        type: familyInformationActionTypes.UPDATE_FAMILY_INFORMATION,
        payload:  {
            'children': familyInfo.children,
            'grandChildren': familyInfo.grandChildren,
            'sameRestrictions': familyInfo.sameRestrictions
        }
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 6
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step7');
    } catch (e) {
        console.log(e);
        throw e;
    }
};