import {Dispatch} from "redux";
import {grantorsPersonalInformationActionTypes} from "../actionTypes/grantorsPersonalInformationActionTypes";
import { GrantorsPersonalInfo, State } from "../store/state";
import {convertToServerResponse} from '../translator';
import { updateResponse } from "../api";
import { history } from "../store/history";
import { customerAuthActionTypes } from "../actionTypes/customerAuthActionTypes";

export const updateGrantorInfo = (grantorsInfo: GrantorsPersonalInfo) => async (dispatch: Dispatch<any>, getState: () => State) => {
    dispatch({
        type: grantorsPersonalInformationActionTypes.UPDATE_GRANTOR_INFORMATION,
        payload: {
            ...grantorsInfo
        }
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 1
    });

    try{
        const state = getState();
        const response = convertToServerResponse(state);
        await updateResponse(response.customerId, response);
        history.push('step2');
    } catch (e) {
        console.log(e);
        throw e;
    }
};