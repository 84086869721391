import React, { useState, CSSProperties, FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, FormProps, Input, Form } from 'semantic-ui-react';
import queryString from 'query-string';
import { State, Welcome } from '../store/state';
import { updateEmail, loginWithToken } from '../actions/welcomeActions';
import { containerStyle, inputStyle, buttonContainerStyle, centerStyle, buttonStyle } from '../styles';
import useForm from '../useForm';
import validate from '../validationRules/welcomeRules';


const contentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    boxShadow: '0px 15px 30px #00000029'
}

const textStyle: CSSProperties = {
    fontFamily: 'Lato',
    fontSize: '18px',
    margin: '10px',
    color: 'black'
}

const inputContainerStyle: CSSProperties = {
    width: '70%'
}

const welcomeSelector = (state: State) => state.welcome;

export const WelcomeComponent = (props: RouteComponentProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.location.search) {
            const {token} = queryString.parse(props.location.search);
            if (token && typeof(token) === 'string')
                dispatch(loginWithToken(token));
        }
    });

    const reduxWelcome = useSelector(welcomeSelector);
    const nextPage  = () => {
        try {
            dispatch(updateEmail(welcome));
        } catch(e) {
            alert('call us yo');
        }
    };

    const {
        values: welcome,
        errors,
        handleChange,
        handleSubmit,
    } : {values: Welcome, 
        errors: any, 
        handleChange: any, 
        handleSubmit: (event: FormEvent<HTMLFormElement>, data: FormProps) => void
    } = useForm(reduxWelcome, nextPage, validate)

    return (
        <div style={containerStyle}>
            <Form onSubmit={handleSubmit}>
                <div className={'contentStyle'} style={{flexDirection: 'column', alignItems: 'center'}}>
                    <div className={'informationContainer'} style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={textStyle}>It is known that the younger generations are not saving enough for their retirement. The purpose of a Retirement Trust is to act as a retirement plan for the Grantor’s children who may otherwise not have anything saved for retirement. The questionnaire will help us prepare a custom Retirement Trust that is specific to your loved one. The questions presented will be discussed in greater detail at our meeting, but please review them in advance. You can call us at (513) 731-6601 to review the questionnaire with us or to schedule a meeting.</p>
                        <p style={{...textStyle, ...centerStyle}}>Please enter your email address to get started:</p>
                        <div style={{...centerStyle, ...inputContainerStyle}} className={'ui fluid input'}>
                            <Form.Input name={'email'} 
                                        value={welcome.email} 
                                        onChange={handleChange} 
                                        className={'field'} 
                                        placeholder={'Email'} 
                                        width={16} 
                                        required
                                        error={errors.email} />
                        </div>
                        {reduxWelcome.error && 
                        <div style={{...centerStyle, color: 'rgb(0, 108, 168)'}}>
                            {reduxWelcome.error}
                        </div>
                        }
                    </div>
                </div>
                <div style={{...buttonContainerStyle, ...centerStyle}}>
                    <Button style={buttonStyle} 
                            className={'ui right labeled icon button'} 
                            type="submit"
                            disabled={welcome.email === reduxWelcome.email && !!reduxWelcome.error}>
                                Start <i className={'right arrow icon'}></i>
                    </Button>
                </div>
            </Form>
        </div>
    )
}