import {Dispatch} from "redux";
import {welcomeActionTypes} from "../actionTypes/welcomeActionTypes";
import {customerAuthActionTypes} from '../actionTypes/customerAuthActionTypes';
import { Welcome } from "../store/state";
import { login, loginWithToken as apiLoginWithToken } from "../api";
import { ServerCustomer } from "../translator";
import { history } from '../store/history';
import { fetchResponse } from "./customerAuthActions";

export const updateEmail = (welcome: Welcome) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: welcomeActionTypes.WELCOME_UPDATE_EMAIL,
        payload: welcome
    });
    dispatch({
        type: customerAuthActionTypes.UPDATE_CURRENT_STEP,
        payload: 0
    });

    try{
        const { status, data } : { status: number, data: ServerCustomer } = await login(welcome.email);

        if (status === 200) {
            dispatch({
                type: customerAuthActionTypes.UPDATE_CUSTOMER_INFORMATION,
                payload: data
            });

            history.push('/step1')
        }
    } catch (e) {
        dispatch({
            type: welcomeActionTypes.WELCOME_UPDATE_ERROR,
            payload: 'An email has been sent to you. Please login using the link in your email.'
        });
        console.log(e);
    }
};

export const loginWithToken = (token: string) => async (dispatch: Dispatch<any>) => {
    const {status, data} = await apiLoginWithToken(token);

    if (status === 200) {
        dispatch({
            type: customerAuthActionTypes.UPDATE_CUSTOMER_INFORMATION,
            payload: data
        });

        dispatch(fetchResponse());

        history.push('/step1')
    }
};